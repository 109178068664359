import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "authConfig";
import { toast } from "react-toastify";
import { MenuItem, TextField } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

/**
 * EwCIA8l6BAAUbDba3x2OMJElkF7gJ4z/VbCPEz0AAdj/pqReMIaQTneZeWeHn//kCROuClmyNc0iBSdJi2e/CygTxhEOvKlIf55kVMjlB+l5opF6hESZj3vqdnM2DZNeuoaEkuqp3lYXPtH6JOj6vgy7dM2JnrLcNjkMTKeEtOFulS2bPA6cUIRJ9s46Fuhtr+gPqwvJBa/cMPEt6b4MKSzC5Z4p9NhLKzv1rYOjFI4k3pwarHPnOdaiK4T97OD86drFNO1WTtrx+YrQDEUy6Yd5+nADgjQEraiwIrv65fY6y+E8PIWQsNu/MBub4b+U76xlu3D8ekXUWCinSIPXiIZ4YJXe/CC5eQxFYLwqZ/mmUbI3iCzOtyLk0Z08THADZgAACEqFFWZOQkitWALYBEejUsZ+Dg4iB99gh1nbxUzLjXSBOkHalLxGoaSh5+eqepzkrtEFq4XDshxyhtNPduvtlWhvKfBYjY/JAMlJV2Ph1sp4B4yjkkjGj4uQB+5Wkb++Zb4sZINwdsdb78z6SHOIYyj/CVaHp/Ks3Gb/5FuAaoDY97s4/moBBI/4zAEXB+4zAmgyJO4Db7ylxV5Gzg9cUqlKTzbVjckKJn/qFxU8PB2dXKPqk5uxdwArMVSClmKthxHYXR8rqq5gTr+btNOd8x2LRjwyRfdc+YWxXq1lxwEr0QSiA4r1sBbaK+h1JovnybYcdYyrlDbUHlAA91CgC2WkV0DdCzXyktcF7kq2Lar/CZW5p5zJ14Sd6gTbq3UwKqGG53Kc7J8+npaiYjTUkBFE3x7RZGP6Qhn4r3BQcP/l3mzWr6HGC4tL6q23MXLoaJ6oO+FdfmxYcmWKZs8f1d+uCQIgCj5ela02WnNU70nS5tqESXziH0XBJXwgd3k6tTT5FvExSm24UBDHVCU74W34YXv984NHH65oz4qYFyITYvn9tTa0LB4ADipupw3/t519KGONN0DXbMhFfpOTRXNLlSL610Vl0JDXF2PPk1JBbkNnE3Q7E8n1UUk23Mf26hFw+CauShJ007uMqgW0OH3nEWg3bX/t7fF+ruWbAg06APaHRwlBW/vzPLH4sZhAtbwN90V9hkNVvkysF9Dwm2Gl5yX/RqozNk/CIMLxvbjNGbE0UFUFpL8t6iiWQn6wP+9yPYG6rswO01a5zhd9UOT5/Ok52QM7Tah1bm9TTSHJqqWbAg==
 */
export const SignInButton = () => {
  const theme = useTheme();
  const { instance } = useMsal();

  const handleLogin = async (loginType) => {
    try {
      let result;
      if (loginType === "popup") {
        result = await instance.loginPopup(loginRequest);
      } else if (loginType === "redirect") {
        result = await instance.loginRedirect(loginRequest);
      }
      instance.setActiveAccount(result.account);
    } catch (error) {
      toast(`${error}`);
    }
  };
  return (
    <TextField
      inputProps={{
        id: "signin",
      }}
      label="Se connecter"
      select
      SelectProps={{
        IconComponent: () => (
          <KeyboardArrowDown
            sx={{
              color: theme.palette.grey.main,
            }}
          />
        ),
        style: {
          color: theme.palette.grey[400],
          height: "40px",
        },
      }}
      InputLabelProps={{
        htmlFor: "signin",
        style: {
          fontSize: "16px",
          color: theme.palette.grey[400],
          marginTop: "-6px",
        },
      }}
      sx={{
        color: "black",
        border: "1px solid",
        borderColor: theme.palette.grey[50],
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
        width: "180px",
      }}
    >
      <MenuItem
        sx={{
          color: theme.palette.grey.main,
          height: "30px",
          ":hover": {
            backgroundColor: theme.palette.grey.light,
          },
        }}
        onClick={() => handleLogin("popup")}
      >
        Connectez-vous en utilisant Popup
      </MenuItem>
      <MenuItem
        sx={{
          color: theme.palette.grey.main,
          height: "30px",
          ":hover": {
            backgroundColor: theme.palette.grey.light,
          },
        }}
        onClick={() => handleLogin("redirect")}
      >
        Connectez-vous en utilisant la redirection
      </MenuItem>
    </TextField>
  );
};
