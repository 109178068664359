import { Box, Button, List, Typography } from "@mui/material";
import React from "react";
import Chat from "./Chat";
import { AddOutlined } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectGroups } from "state/conversation";
import NotFoundError from "Components/NotFoundError";

const GroupList = ({ setIsAddGroup }) => {
  const groups = useSelector((state) => selectGroups(state));
  const theme = useTheme();
  return (
    <Box>
      <Button
        sx={{
          width: "100%",
          height: "32px",
          bgcolor: theme.palette.secondary[900],
          color: theme.palette.primary.main,
          textTransform: "none",
          fontSize: "16px",
          textAlign: "center",
          borderRadius: "0",
          ":hover": {
            bgcolor: theme.palette.secondary[900],
          },
        }}
        onClick={() => setIsAddGroup(true)}
      >
        <AddOutlined sx={{ mr: "5px" }} />
        Nouveau Group
      </Button>
      {groups && Array.isArray(groups) && groups.length !== 0 ? (
        <Box
          sx={{
            height: "78vh",
            overflow: "auto",
          }}
        >
          {groups.map((group) => (
            <Chat key={group._id} conversation={group} />
          ))}
        </Box>
      ) : (
        <NotFoundError text="Aucun groupe trouvé !" sx={{ height: "70vh" }} />
      )}
    </Box>
  );
};

export default GroupList;
