import { useTheme } from '@emotion/react'
import { CalendarTodayOutlined, KeyboardArrowDown } from '@mui/icons-material'
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import EmailsWeekView from './EmailsWeekView'
import EmailsMonthView from './EmailsMonthView'
dayjs.locale("fr");

const EmailCalendar = () => {
  const theme = useTheme()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [display, setDisplay] = useState('week')
  const navigate = useNavigate()
  const getMonth = () => {
    const date = new Date(selectedDate)
    const month = date.toLocaleString('fr', { month: 'long' })
    return `${month[0].toUpperCase()}${month.substring(1)}`
  }
  const getDate = () => {
    const date = new Date(selectedDate)
    const numMonth = date.toLocaleString('fr', { month: '2-digit' })
    const day = date.toLocaleString('fr', { day: '2-digit' })
    const month = getMonth()
    const year = date.toLocaleString('fr', { year: 'numeric' })
    return `${numMonth}-${day} ${month} ${year}`
  }
  return (
    <Box sx={{
      display: 'flex',
      width:'100%',
      height: "100%",
    }}>
      {/* Left */}
      <Box
        sx={{
          width: '250px',
          bgcolor: theme.palette.grey[100],
          p: '10px 20px',
        }}
      >
        <Typography variant='h4' sx={{
          color: theme.palette.secondary[700],
          fontWeight: '500',
        }}>
          {getMonth()}
        </Typography>
        <Box sx={{
          "& .MuiIconButton-root": {
            display: 'none'
          },
          "& .MuiPickersCalendarHeader-label": {
            display: 'none'
          },
          "& .MuiButtonBase-root": {
            color: theme.palette.secondary.main,
            fontSize: "10px",
            fontWeight:'500'
          },
          "& .MuiButtonBase-root.Mui-selected": {
            borderRadius: "50%",
            height: '25px',
          },
          "& .css-1acgcob-MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
            bgcolor: theme.palette.alt.main,
            height: '25px',
            color: 'white',
          },
          "& .css-1acgcob-MuiButtonBase-root-MuiPickersDay-root:focus ": {
            height: '25px',
            bgcolor: theme.palette.alt.main,
            color: 'white',
          },
          "& .css-sg8ll6-MuiButtonBase-root-MuiPickersDay-root.Mui-selected  ": {
            height: '25px',
            bgcolor: theme.palette.alt.main,
            color: 'white',
          },
          "& .css-1acgcob-MuiButtonBase-root-MuiPickersDay-root:focus.Mui-selected":
          {
            height: '25px',
            bgcolor: theme.palette.alt.main,
            color: 'white',
          },
          "& .MuiDayCalendar-weekDayLabel": {
            color: theme.palette.grey[400],
            fontSize: "14px",
          },
          "& .MuiPickersYear-root": {
            color: theme.palette.secondary.main,
          },
          "& .MuiPickersYear-yearButton": {
            fontSize: "16px",
          },
          "& .css-9yhg0o-MuiPickersYear-yearButton.Mui-selected": {
            bgcolor: theme.palette.alt.main,
          },
        }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              sx={{
                bgcolor: theme.palette.grey[100],
                height: '434px',
                width: '205px',
              }}
              onChange={(value) => setSelectedDate(new Date(value))}
              defaultValue={dayjs(new Date())}
              value={dayjs(selectedDate)}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <Button
            startIcon={<CalendarTodayOutlined fontSize="large" />}
            sx={{
              height: "40px",
              color: theme.palette.alt[900],
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "600",
              textWrap: "nowrap",
              border: '1px solid',
              borderColor: theme.palette.alt[900],
              width: '98%',
              mt: '20px',
            }}
            onClick={() => navigate('/emails')}
          >
            Messagerie
          </Button>
        </Box>
      </Box>
      {/* right */}
      <Box sx={{
        flex:'1'
      }}>
        <Box sx={{
          height: '68px',
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'start',
          alignItems: 'center',
          ".css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":{
            p:'5px',
            pr:'0'
          }
        }}>
          <Typography variant='h2' sx={{
            color: theme.palette.secondary[700],
            fontWeight: '500',
            p: '15px'
          }}>
            {getDate()}
          </Typography>
          <TextField
            select
            value={display}
            SelectProps={{
              IconComponent: () => (
                <KeyboardArrowDown
                  sx={{
                    color: theme.palette.grey[600],
                    width:'25px',
                    height:'25px',
                  }}
                />
              ),
              style: {
                color: theme.palette.grey.main,
                backgroundColor: theme.palette.primary.main,
                fontWeight:'500'
              },
            }}
            sx={{
              border: "1px solid",
              borderColor: theme.palette.grey.main,
              borderRadius: "4px",
              width:'90px',
              textAlign:'center'
            }}
            onChange={(e)=>setDisplay(e.target.value)}
          >
            <MenuItem
              sx={{
                color: theme.palette.grey.main,
                backgroundColor: display === 'week' && `${theme.palette.grey[300]} !important`,
                ":hover": {
                  backgroundColor: display === 'week' && `${theme.palette.grey[300]} !important`,
                }
              }}
              value='week'
            >
              Semaine
            </MenuItem>
            <MenuItem
            sx={{
                color: theme.palette.grey.main,
                backgroundColor: display === 'month' && `${theme.palette.grey[300]} !important`,
                ":hover": {
                  backgroundColor: display === 'month' && `${theme.palette.grey[300]} !important`,
                }
              }}
              value='month'
            >
              Mois
            </MenuItem>
          </TextField>
        </Box>
        {
          display === 'week' ? (
            <EmailsWeekView selectedDate={selectedDate} />
          ) : (
            <EmailsMonthView selectedDate={selectedDate} />
          )
        }
      </Box>
    </Box>
  )
}

export default EmailCalendar