import handleError from "utils/handelError";
import axiosClient from "./client";
import { addEmailAttachmentsEndPoint, filterEmailEndPoint, forwardEmailEmailEndPoint, getEmailAttachmentsEndPoint, getFilterByDateEmailsEndPoint, replyAllEmailEndPoint, replyEmailEndPoint, updateEmailCategoryEndPoint, updateEmailEndPoint } from "./Constants";
import { deleteEmailEndPoint, getEmailDetailsEndPoint, getMyDetailsEndPoint, getMyEmailsEndPoint, getMyProfilePhotoEndPoint, sendEmailEndPoint } from "./Constants.js";
import { loginRequest } from "authConfig";
const EmailService = {
  getMyDetails: async (instance) =>{
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken
      const response = await axiosClient.get(getMyDetailsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      return data;
    } catch (error) {
      handleError(error);
    }
  },
  getMyProfilePhoto: async (instance) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken
      const response = await axiosClient.get(getMyProfilePhotoEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const photo = response.data;
      return photo;
    } catch (error) {
      handleError(error);
    }
  },
  sendEmail: async (instance,data) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken
      await axiosClient.post(sendEmailEndPoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },
  replyEmail: async (instance,id,data) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken
      await axiosClient.post(replyEmailEndPoint(id), data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },
  replyAllEmail: async (instance,id,data) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken
      await axiosClient.post(replyAllEmailEndPoint(id), data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },
  forwardEmail: async (instance,id,data) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken
      await axiosClient.post(forwardEmailEmailEndPoint(id), data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },
  getEmailDetails: async (instance,id) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken;
      const response = await axiosClient.get(getEmailDetailsEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const email = response.data;
      return email;
    } catch (error) {
      handleError(error);
    }
  },
  getUserEmails: async (instance,category) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken;
      const response = await axiosClient.get(getMyEmailsEndPoint(category), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const emails = response.data.value;
      return emails;
    } catch (error) {
      handleError(error);
    }
  },
  deleteEmail: async (instance,id) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken;
      await axiosClient.delete(deleteEmailEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },
  updateEmailCategory: async (instance,id, category) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken;
      await axiosClient.post(
        updateEmailCategoryEndPoint(id),
        { destinationId:category },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      handleError(error);
    }
  },
  updateEmail: async (instance,id, data) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken;
      await axiosClient.patch(
        updateEmailEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      handleError(error);
    }
  },
  markEmailAsSeen: async (instance,id) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken;
      await axiosClient.patch(
        updateEmailEndPoint(id),
        {isRead:true},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      handleError(error);
    }
  },
  sendEmailAttachment: async (instance,emailId,data) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken;
      await axiosClient.post(
        addEmailAttachmentsEndPoint(emailId),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      handleError(error);
    }
  },
  getEmailAttachments: async (instance,emailId) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken;
      const response = await axiosClient.get(
        getEmailAttachmentsEndPoint(emailId),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const attachments = response.data.value;
      return attachments;
    } catch (error) {
      handleError(error);
    }
  },
  getFilterEmails: async (instance,category,isRead) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken;
      const response = await axiosClient.get(
        filterEmailEndPoint(category,isRead),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const emails = response.data.value;
      return emails;
    } catch (error) {
      handleError(error);
    }
  },
  getFilterByDateEmails: async (instance,start,end) => {
    try {
      const res = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount()});
      const token = res.accessToken;
      const response = await axiosClient.get(
        getFilterByDateEmailsEndPoint(start,end),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const emails = response.data.value;
      return emails;
    } catch (error) {
      handleError(error);
    }
  },
};

export default EmailService;
