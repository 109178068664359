import React, { useState } from 'react';
import './Style.css';
import { Switch } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import UpdateAdminModal from 'Components/Modals/UpdateAdminModal';
import { deleteAdmin } from '../../../redux/actions/UserAction';
import ModalDeleteGuide from 'Components/Modals/DeleteModal';
import { useDispatch } from 'react-redux';

const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#06A561',
        '&:hover': {
            backgroundColor: alpha('#06A561', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#06A561',
    },
    '& .MuiSwitch-track ': {
        backgroundColor: '#000',
    },
}));

function DetailsAdmin({ admin }) {
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenDelete, setModalOpenDelete] = useState(false);
    const openModalDelete = () => {
        setModalOpenDelete(true);
    };

    const closeModalDelete = () => {
        setModalOpenDelete(false);
    };
    const OpenAddAdminModal = () => {
        setModalOpen(true);
    };

    const closeAddAdminModal = () => {
        setModalOpen(false);
    };

    const handleDelete = (id) => {
        dispatch(deleteAdmin(id))
            .then(result => {
                // if (result) {
                //     dispatch(fetchAllAmicals())
                // }
            })
            .catch(error => {
                console.log('Error :', error);
            });
    };

    const testDelete = () => {
        openModalDelete()
    };
    return (
        <div className="DetailsAdminContainer mt-3">
             {
                modalOpen && <UpdateAdminModal admin={admin} isOpen={modalOpen} onClose={closeAddAdminModal} />
            }
            {modalOpenDelete && (
                <ModalDeleteGuide isOpen={modalOpenDelete} onClose={closeModalDelete} handleAction={() => handleDelete(admin._id)} title={`Supprimer le client ${admin.firstName}`} message="Voulez-vous vraiment supprimer cet utilisateur ?" />
            )}
            <div className="DetailsAdminHeader">
                <h4>{admin.role == "SUPER_ADMIN" ? "Super Admin" : "Admin"}</h4>
                <div className='DetailsAdminHeaderBtns'>
                    <button onClick={()=>OpenAddAdminModal()}>
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.834 13.8333C13.2942 13.8333 13.6673 14.2064 13.6673 14.6667C13.6673 15.1269 13.2942 15.5 12.834 15.5H1.16732C0.70708 15.5 0.333984 15.1269 0.333984 14.6667C0.333984 14.2064 0.70708 13.8333 1.16732 13.8333H12.834ZM12.0031 0.987431L13.1799 2.16419C13.8298 2.8141 13.8298 3.86781 13.1799 4.51772L6.31544 11.3822C6.18814 11.5095 6.02297 11.5921 5.84474 11.6175L2.00065 12.1667L2.54981 8.32258C2.57527 8.14435 2.65785 7.97918 2.78516 7.85187L9.6496 0.987431C10.2995 0.337523 11.3532 0.337523 12.0031 0.987431ZM10.8264 2.16419L4.15824 8.83231L3.96231 10.205L5.33501 10.0091L12.0031 3.34095L10.8264 2.16419Z"
                                fill="white"
                            />
                        </svg>
                    </button>
                    {
                        admin.role == "ADMIN" && <button onClick={()=>testDelete()}>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9993 4.66667V13.8333C10.9993 14.7538 10.2532 15.5 9.33268 15.5H2.66602C1.74554 15.5 0.999349 14.7538 0.999349 13.8333V4.66667H10.9993ZM9.33268 6.33333H2.66602V13.8333H9.33268V6.33333ZM5.99935 0.5C6.45959 0.5 6.83268 0.873096 6.83268 1.33333V2.16667H10.9993C11.4596 2.16667 11.8327 2.53976 11.8327 3C11.8327 3.46024 11.4596 3.83333 10.9993 3.83333H0.999349C0.539112 3.83333 0.166016 3.46024 0.166016 3C0.166016 2.53976 0.539112 2.16667 0.999349 2.16667H5.16602V1.33333C5.16602 0.873096 5.53911 0.5 5.99935 0.5Z" fill="white" />
                            </svg>

                        </button>
                    }
                </div>

            </div>
            <div className="DetailsAdminContent">
                <h3>
                    {admin.firstName} {admin.lastName}
                </h3>
                <div className="DetailsAdminDescription mb-2">
                    <p className="detailsAdmin-label">Numéro de téléphone:</p>
                    <p className="detailsAdmin-text">{admin.phone}</p>
                </div>
                <div className="DetailsAdminDescription mb-2">
                    <p className="detailsAdmin-label">Adresse mail:</p>
                    <p className="detailsAdmin-text">{admin.email}</p>
                </div>
                <div className="DetailsAdminDescription mb-2">
                    <p className="detailsAdmin-label">Login:</p>
                    <p className="detailsAdmin-text">{admin.userName}</p>
                </div>
                <div className="DetailsAdminFonction">
                    <p className="detailsAdmin-label">Fonction En charge:</p>
                    <div className='d-flex align-items-center'>
                        <PinkSwitch checked={admin.hrManagement} />
                        <p className="detailsAdmin-text">Gestions des ressources humaines</p>
                    </div>
                    <div className='d-flex align-items-center'>
                        <PinkSwitch checked={admin.projectManagement} />
                        <p className="detailsAdmin-text">Gestion des chantiers et exploitation</p>
                    </div>
                    <div className='d-flex align-items-center '>
                        <PinkSwitch checked={admin.financialManagement} />
                        <p className="detailsAdmin-text">Gestion financière</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailsAdmin;
