import { useTheme } from '@emotion/react';
import { Box, Button, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, Typography } from '@mui/material';
import AuthService from 'Api/AuthService';
import CustomCircularProgress from 'Components/CustomCircularProgress';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setGlobalIsLoading } from 'state/global';
import TextEditor from './TextEditor';
import { CloseOutlined, DescriptionOutlined, EmailOutlined } from '@mui/icons-material';
import CustomAutoComplete from 'Components/CustomAutoComplete';
import EmailBox from './Components/EmailBox';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'state/auth';
import EmailService from 'Api/mailing/EmailService';
import { useMsal } from '@azure/msal-react';

const ReplyEmails = () => {
    const [isLoading, setIsLoading] = useState(true)
    const theme = useTheme()
    const { instance } = useMsal()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isReplyAll = useLocation().pathname.includes('reply-all')
    const [email, setEmail] = useState({})
    const [content, setContent] = useState('');
    const user = useSelector((state) => selectUser(state))
    const { emailId, category } = useParams();
    const [users, setUsers] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [initialValues, setInitialValues] = useState({
        body: '',
        toRecipients: [],
        ccRecipients: [],
        bccRecipients: [],
    });
    const formatData = (value) => {
        return {
            first_name: value.emailAddress.name ? value.emailAddress.name.split(' ')[0] : '',
            last_name: value.emailAddress.name ? value.emailAddress.name.split(' ')[1] : '',
            email: value.emailAddress.address ?? '',
        }
    }

    useEffect(() => {
        const getEmail = async () => {
            try {
                const data = await EmailService.getEmailDetails(instance, emailId);
                setEmail(data);
                const receivers = []
                data.toRecipients.map((item) => {
                    if (item.emailAddress.address !== user.email) {
                        receivers.push(formatData(item))
                    }
                })

                setInitialValues({
                    body: '',
                    toRecipients: isReplyAll ? [formatData(data.sender), ...receivers] : [formatData(data.sender)],
                    ccRecipients: [],
                    bccRecipients: [],
                })
                setIsLoading(false);
            } catch (error) {
                toast(`${error}`);
            }
        }
        const getUsers = async () => {
            try {
                setIsLoading(true);
                const data = await AuthService.getAllUsers();
                setUsers(data);
                getEmail()
            } catch (error) {
                toast(`${error}`);
            }
        };
        getUsers();
    }, []);

    const searchOptions = (options, state) => {
        return options.filter((option) => `${option.first_name} ${option.last_name} ${option.email}`.includes(state.inputValue));
    };

    const addEmail = async (values) => {
        try {
            dispatch(setGlobalIsLoading({ isLoading: true }))
            const replyEmail = document.getElementById('email-info').innerHTML
            const body = `<div><div>${replyEmail}</div><hr/><div>${content}</div></div>`
            const data = {
                message: {
                    subject: values.subject,
                    body: {
                        contentType: "html",
                        content:body,
                    },
                }
            }
            data.message.toRecipients = values.toRecipients.map((item) => {
                return {
                    emailAddress: {
                        address: item.email
                    }
                }
            })
            data.message.ccRecipients = values.ccRecipients.map((item) => {
                return {
                    emailAddress: {
                        address: item.email
                    }
                }
            })
            data.message.bccRecipients = values.bccRecipients.map((item) => {
                return {
                    emailAddress: {
                        address: item.email
                    }
                }
            })
            if (attachments && attachments.length > 0) {
                const promises = attachments.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            resolve({
                                "@odata.type": "#microsoft.graph.fileAttachment",
                                name: file.name,
                                contentType: file.type,
                                contentBytes: reader.result.split(',')[1],
                            });
                        }
                        reader.onerror = reject;
                        reader.readAsDataURL(file);
                    })
                });
                data.message.attachments = await Promise.all(promises)
            }
            if (isReplyAll) {
                await EmailService.replyAllEmail(instance, email.id, data)
            } else {
                await EmailService.replyEmail(instance, email.id, data)
            }
            dispatch(setGlobalIsLoading({ isLoading: false }))
            navigate("/emails/tems")
        } catch (error) {
            dispatch(setGlobalIsLoading({ isLoading: false }))
            toast(`${error}`)
        }
    }

    return (
        <Box sx={{
            width: '100%',
            height: '100%'
        }}>
            <Typography sx={{
                color: theme.palette.secondary[700],
                fontSize: "16px",
                fontWeight: "600",
                textTransform: "none",
                borderBottom: "1px solid",
                borderBottomColor: theme.palette.grey[1100],
                p: '10px'
            }}>
                Répondre
            </Typography>
            {
                isLoading ?
                    (
                        <CustomCircularProgress sx={{
                            height: '90%'
                        }} />
                    )
                    :
                    (
                        <Formik
                            initialValues={initialValues}
                            onSubmit={addEmail}
                            enableReinitialize={true}
                        // validationSchema={emailSchema}
                        >
                            {({ values, handleSubmit, setFieldValue }) => (
                                <Form>
                                    <Box sx={{
                                        height: '80vh',
                                        overflow: 'auto'
                                    }}>
                                        <List>
                                            <EmailBox email={email} />
                                            <CustomAutoComplete
                                                hint="Vers :"
                                                options={users}
                                                toggleOption={(value) => setFieldValue('toRecipients', value)}
                                                searchOptions={searchOptions}
                                                filterOptions={(option) => {
                                                    return !values.ccRecipients.includes(option) && !values.bccRecipients.includes(option)
                                                }}
                                                initialValue={values.toRecipients}
                                            />
                                            <CustomAutoComplete
                                                hint="Cc :"
                                                options={users}
                                                toggleOption={(value) => setFieldValue('ccRecipients', value)}
                                                searchOptions={searchOptions}
                                                filterOptions={(option) => {
                                                    return !values.toRecipients.includes(option) && !values.bccRecipients.includes(option)
                                                }}
                                                initialValue={values.ccRecipients}
                                            />
                                            <CustomAutoComplete
                                                hint="Cci :"
                                                options={users}
                                                toggleOption={(value) => setFieldValue('bccRecipients', value)}
                                                searchOptions={searchOptions}
                                                filterOptions={(option) => {
                                                    return !values.toRecipients.includes(option) && !values.ccRecipients.includes(option)
                                                }}
                                                initialValue={values.bccRecipients}
                                            />
                                            <TextEditor setContent={setContent} initialValue={''} setAttachments={setAttachments} />
                                            <Box sx={{
                                                width: '90%',
                                                display: 'flex',
                                                gap: '15px',
                                                flexWrap: 'wrap',
                                                m: '20px'
                                            }}>
                                                {attachments.map((file) => (
                                                    <ListItem sx={{
                                                        width: '260px',
                                                        border: '1px solid',
                                                        borderColor: theme.palette.grey[400],
                                                        borderRadius: "6px",
                                                    }}>
                                                        <ListItemIcon>
                                                            <DescriptionOutlined sx={{ color: theme.palette.grey[400], }} />
                                                        </ListItemIcon>
                                                        <ListItemAvatar sx={{
                                                            fontSize: '12px',
                                                            color: theme.palette.grey[400],
                                                            width: '150px',
                                                        }}>
                                                            <Typography sx={{
                                                                width: '130px',
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'clip',
                                                                textWrap: 'nowrap',
                                                            }}>
                                                                {file.name}
                                                            </Typography>
                                                            <Typography>
                                                                {file.size}
                                                            </Typography>
                                                        </ListItemAvatar>
                                                        <ListItemSecondaryAction sx={{
                                                            p: '0',
                                                            m: '0'
                                                        }}>
                                                            <IconButton
                                                                sx={{
                                                                    border: "1px solid",
                                                                    borderColor: theme.palette.grey[50],
                                                                    borderRadius: "4px",
                                                                    p: '0',
                                                                    m: '0'
                                                                }}
                                                                onClick={() => setAttachments(prevElm => prevElm.filter((element) => element !== file))}
                                                            >
                                                                <CloseOutlined
                                                                    sx={{
                                                                        color: theme.palette.grey.main,
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                            </Box>
                                            <Box sx={{
                                                width: '100%',
                                                height: '48px',
                                                backgroundColor: theme.palette.grey[1300],
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px',
                                                pl: '10px'
                                            }}>
                                                <Button
                                                    startIcon={<EmailOutlined />}
                                                    sx={{
                                                        width: "107px",
                                                        height: "32px",
                                                        fontSize: '14px',
                                                        textTransform: 'none',
                                                        backgroundColor: theme.palette.alt[900],
                                                        borderRadius: '3px',
                                                        fontWeight: '400',
                                                        ":hover": {
                                                            backgroundColor: theme.palette.alt[900],
                                                        }
                                                    }}
                                                    type='submit'
                                                >
                                                    Envoyer
                                                </Button>
                                                <Button
                                                    sx={{
                                                        width: "107px",
                                                        height: "32px",
                                                        fontSize: '14px',
                                                        textTransform: 'none',
                                                        color: theme.palette.grey[700],
                                                        border: '1px solid',
                                                        borderColor: theme.palette.grey.main,
                                                        borderRadius: '3px',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Annuler
                                                </Button>
                                            </Box>
                                        </List>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    )}
        </Box>
    )
}

export default ReplyEmails