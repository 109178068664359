import React, { useEffect, useState } from "react";
import "./Todo.css";
import PopUp from "Components/Popup";
import { formatDate } from "utils/format_date";
import { useDispatch, useSelector } from "react-redux";
import TodoService from "Api/TodoService";
import { addTodo, deleteTodo, setTodos, updateTodo } from "state/todo";
import { Skeleton } from "@mui/material";
import { toast } from "react-toastify";
import { setGlobalIsLoading } from "state/global";
import CustomDialog from "Components/CustomDialog";

function AddEditTodo({
  newTodo,
  handleSaveTodo,
  handleInputChange,
  setEditable,
  setNewTodo,
}) {
  return (
    <div className="AddTodo">
      <input
        type="text"
        name="title"
        value={newTodo.title}
        onChange={handleInputChange}
        placeholder="Titre"
        className="todoInput"
      />
      <textarea
        type="text"
        name="text"
        value={newTodo.text}
        onChange={handleInputChange}
        placeholder="Texte"
        className="todoInput"
        multiple
      />
      <div className="AddTodoAction">
        <button className="action confirm" onClick={handleSaveTodo}>
          <svg
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.0665 0.329481C18.5088 -0.109841 19.2246 -0.109824 19.6669 0.329516C20.1111 0.770721 20.111 1.48745 19.6668 1.92864L7.84461 13.6705C7.40231 14.1098 6.68661 14.1098 6.24429 13.6705L0.333181 7.79986C-0.111032 7.35868 -0.111065 6.64195 0.333109 6.20074C0.775406 5.76139 1.49115 5.76136 1.93349 6.20067L7.04443 11.2767L18.0665 0.329481Z"
              fill="#FFFFFF"
            />
          </svg>
        </button>
        <button
          className="action cancel"
          onClick={() => {
            setEditable(false);
            setNewTodo({ title: "", text: "" });
          }}
        >
          <svg
            width="14"
            height="2"
            viewBox="0 0 14 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13 0C13.5523 0 14 0.447715 14 1C14 1.55228 13.5523 2 13 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0L13 0Z"
              fill="#1E5EFF"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

function TodoClient({ selectedDate }) {
  const todos = useSelector((state) => state.todo.todos);
  const [isLoading, setIsLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [newTodo, setNewTodo] = useState({ title: "", text: "" });
  const [currentTodo, setCurrentTodo] = useState(null);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTodo({
      ...newTodo,
      [name]: value,
    });
  };

  const handleSaveTodo = () => {
    if (currentTodo !== null) {
      handleUpdateTodo();
    } else {
      handleAddTodo();
    }
  };

  const handleAddTodo = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = {
        title: newTodo.title,
        text: newTodo.text,
        date: selectedDate,
      };
      const newTodoItem = await TodoService.createTodo(data);
      dispatch(addTodo({ todo: newTodoItem }));
      setNewTodo({ title: "", text: "" });
      setEditable(false);
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  const handleDeleteTodo = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = await TodoService.deleteTodo(currentTodo._id);
      dispatch(deleteTodo({ id: currentTodo._id }));
      setCurrentTodo(null);
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  const handleEditTodo = (todo) => {
    setNewTodo({ title: todo.title, text: todo.text });
    setCurrentTodo(todo);
    setEditable(true);
  };

  const handleUpdateTodo = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = await TodoService.updateTodoDetails(
        currentTodo._id,
        newTodo
      );
      dispatch(updateTodo({ todo: data }));
      setNewTodo({ title: "", text: "" });
      setCurrentTodo(null);
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setEditable(false);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  useEffect(() => {
    const getTodos = async () => {
      try {
        setIsLoading(true);
        const data = await TodoService.getUserTodo(selectedDate);
        dispatch(setTodos({ todos: data }));
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getTodos();
  }, [selectedDate]);
  return (
    <div className="todoContainer">
      <h4>{formatDate(selectedDate)}</h4>
      <>
        {isLoading ? (
          <Skeleton
            animation="pulse"
            variant="rectangular"
            sx={{
              bgcolor: "#D9D9D9",
              borderRadius: "6px",
              height: "80px",
            }}
          />
        ) : (
          <>
            {todos && todos.length === 0 ? (
              <p id="not-found-text">Pas de todo...</p>
            ) : (
              <>
                {todos.map((todo) => (
                  <div key={todo._id} className="todoContent">
                    <div>
                      <p className="todoTitle">
                        {todo.title.length > 10
                          ? todo.title.substring(0, 10) + ",.."
                          : todo.title}
                      </p>
                      <div className="todoAction">
                        <button onClick={() => handleEditTodo(todo)}>
                          <svg
                            width="16"
                            height="18"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M15 16C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H1C0.447715 18 0 17.5523 0 17C0 16.4477 0.447715 16 1 16H15ZM14.003 0.584917L15.4151 1.99703C16.195 2.77692 16.195 4.04137 15.4151 4.82126L7.17775 13.0586C7.02498 13.2114 6.82678 13.3105 6.61291 13.341L2 14L2.65899 9.38709C2.68954 9.17322 2.78864 8.97502 2.94141 8.82225L11.1787 0.584917C11.9586 -0.194972 13.2231 -0.194972 14.003 0.584917ZM12.5909 1.99703L4.58911 9.99878L4.35399 11.646L6.00122 11.4109L14.003 3.40914L12.5909 1.99703Z"
                              fill="#979797"
                            />
                          </svg>
                        </button>
                        <button
                          onClick={() => {
                            setCurrentTodo(todo);
                            setDeleteOpen(true);
                          }}
                        >
                          <svg
                            width="14"
                            height="18"
                            viewBox="0 0 14 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13 5V16C13 17.1046 12.1046 18 11 18H3C1.89543 18 1 17.1046 1 16V5H13ZM11 7H3V16H11V7ZM7 0C7.55228 0 8 0.447715 8 1V2H13C13.5523 2 14 2.44772 14 3C14 3.55228 13.5523 4 13 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H6V1C6 0.447715 6.44772 0 7 0Z"
                              fill="#979797"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <p className="todoText">
                      {todo.text.length > 10
                        ? todo.text.substring(0, 10) + ",.."
                        : todo.text}
                    </p>
                  </div>
                ))}
              </>
            )}
            <button id="addtodo" onClick={() => setEditable(true)}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 0C7.55228 0 8 0.447715 8 1V5.999L13 6C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8L8 7.999V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V7.999L1 8C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6L6 5.999V1C6 0.447715 6.44772 0 7 0Z"
                  fill="#1E5EFF"
                />
              </svg>
            </button>
          </>
        )}
      </>
      <PopUp
        open={editable}
        setOpen={setEditable}
        style={{ backgroundColor: "white" }}
      >
        <AddEditTodo
          newTodo={newTodo}
          handleSaveTodo={handleSaveTodo}
          handleInputChange={handleInputChange}
          setEditable={setEditable}
          setNewTodo={setNewTodo}
        />
      </PopUp>
      <CustomDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        title={"Supprimer Todo"}
        content={"Vous êtes sûr de supprimer Todo?"}
        onClick={() => handleDeleteTodo()}
      />
    </div>
  );
}

export default TodoClient;
