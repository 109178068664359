import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Switch } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { updateAdmin } from '../../../redux/actions/UserAction';
import "./AddStyle.css";
import ValidateModal from 'Components/Modals/ValidateModal';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#06A561',
    '&:hover': {
      backgroundColor: alpha('#06A561', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#06A561',
  },
  '& .MuiSwitch-track ': {
    backgroundColor: '#000',
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UpdateAdminForm = ({ adminData,closeModalAdd }) => {
  const dispatch = useDispatch();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    phone: '',
    email: '',
    role: '',
    hrManagement: false,
    projectManagement: false,
    financialManagement: false,
  });

  useEffect(() => {
    if (adminData) {
      setInitialValues({
        firstName: adminData.firstName || '',
        lastName: adminData.lastName || '',
        userName: adminData.userName || '',
        phone: adminData.phone || '',
        email: adminData.email || '',
        role: adminData.role || '',
        hrManagement: adminData.hrManagement || false,
        projectManagement: adminData.projectManagement || false,
        financialManagement: adminData.financialManagement || false,
      });
    }
  }, [adminData]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsAlertOpen(false);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Le prénom est obligatoire'),
    lastName: Yup.string().required('Le nom est obligatoire'),
    phone: Yup.string().required('Le téléphone est obligatoire'),
    userName: Yup.string().required("Le nom d'utilisateur est obligatoire"),
    email: Yup.string().email('Format de courriel invalide').required('Le courriel est obligatoire'),
  });

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const successUpdate = async () => {
    openModal();
    await new Promise(resolve => setTimeout(resolve, 3000));
    closeModal();
    closeModalAdd();
  };

  const handleSubmit = (values) => {
    dispatch(updateAdmin(adminData._id, values))
      .then(result => {
        if (result) {
          successUpdate();
        } else {
          setIsAlertOpen(true);
        }
      })
      .catch(error => {
        console.log('Erreur', error);
        if (error) {
          setIsAlertOpen(true);
        }
      });
  };

  return (
    <>
      {modalOpen &&
        <ValidateModal isOpen={modalOpen} onClose={closeModal} message="Mise à jour réussie" />
      }
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isAlertOpen}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Erreur de mise à jour Admin !
        </Alert>
      </Snackbar>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form style={{ width: '100%', height: '100%' }}>
            <div className="mb-3">
              <h4>
                Mettre à jour l'administrateur
              </h4>
            </div>
            <div className="mb-3">
              <div className="culumnFormContainer">
                <label className="form-label" htmlFor="firstName">Prénom</label>
                <Field className="form-control" type="text" name="firstName" />
                <ErrorMessage name="firstName" component="span" />
              </div>
            </div>
            <div className="mb-3">
              <div className="culumnFormContainer">
                <label className="form-label" htmlFor="lastName">Nom</label>
                <Field className="form-control" type="text" name="lastName" />
                <ErrorMessage name="lastName" component="span" />
              </div>
            </div>
            <div className="mb-3"> 
              <div className="culumnFormContainer">
                <label className="form-label" htmlFor="userName">Nom d'utilisateur</label>
                <Field className="form-control" type="text" name="userName" />
                <ErrorMessage name="userName" component="span" />
              </div>
            </div>
            <div className="mb-3">
              <div className="culumnFormContainer">
                <label className="form-label" htmlFor="email">Courriel</label>
                <Field className="form-control" type="email" name="email" />
                <ErrorMessage name="email" component="span" />
              </div>
            </div>
            <div className="mb-3">
              <div className="culumnFormContainer">
                <label className="form-label" htmlFor="phone">Téléphone</label>
                <Field className="form-control" type="number" name="phone" />
                <ErrorMessage name="phone" component="span" />
              </div>
            </div>
            <div className="mb-3">
              <h5 className='mb-2'>Fonctions en charge :</h5>
              <div className='d-flex align-items-center'>
                <PinkSwitch
                  checked={values.hrManagement}
                  onChange={() => setFieldValue('hrManagement', !values.hrManagement)}
                />
                <p className="detailsAdmin-text">Gestion des ressources humaines</p>
              </div>
              <div className='d-flex align-items-center'>
                <PinkSwitch
                  checked={values.projectManagement}
                  onChange={() => setFieldValue('projectManagement', !values.projectManagement)}
                />
                <p className="detailsAdmin-text">Gestion des chantiers et exploitation</p>
              </div>
              <div className='d-flex align-items-center '>
                <PinkSwitch
                  checked={values.financialManagement}
                  onChange={() => setFieldValue('financialManagement', !values.financialManagement)}
                />
                <p className="detailsAdmin-text">Gestion financière</p>
              </div>
            </div>
            <button className="DarkBtn " style={{ width: "100%", justifyContent: "center" }} type="submit">Mettre à jour</button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateAdminForm;
