import { useTheme } from "@emotion/react";
import {
  CalendarTodayOutlined,
  KeyboardArrowDownOutlined,
  MailOutline,
} from "@mui/icons-material";
import { Box, Button, Icon, Menu, MenuItem, SvgIcon } from "@mui/material";
import CustomDialog from "Components/CustomDialog";
import FlexBetween from "Components/FlexBetween";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setGlobalIsLoading } from "state/global";
import { emailIcons } from "./emailIcons";
import { SignOutButton } from "./Components/SignOutButton";
import EmailService from "Api/mailing/EmailService";
import { useMsal } from "@azure/msal-react";

const ToolBarButton = ({ startIcon, endIcon, text, onClick, isDisabled }) => {
  const theme = useTheme();

  return (
    <Button
      disabled={isDisabled}
      startIcon={
        startIcon && (
          <SvgIcon
            sx={{
              mt: "10px",
              ml: "10px",
              height: "30px",
              fontSize: "24px !important",
            }}
          >
            {startIcon}
          </SvgIcon>
        )
      }
      endIcon={endIcon && <Icon component={endIcon} />}
      sx={{
        color: theme.palette.secondary[700],
        textTransform: "none",
        fontSize: "14px",
        ":disabled": {
          color: theme.palette.grey[1000],
        },
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
const NavButton = ({ icon, text, onClick, count, isSelected }) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      startIcon={
        <SvgIcon
          sx={{
            mt: "10px",
            ml: "10px",
            height: "30px",
            fontSize: "24px !important",
          }}
        >
          {icon}
        </SvgIcon>
      }
      sx={{
        color: isSelected
          ? theme.palette.alt[900]
          : theme.palette.secondary[700],
        textTransform: "none",
        fontSize: "14px",
        width: "100%",
        height: "40px",
        bgcolor: isSelected && theme.palette.grey.light,
        ":hover": {
          bgcolor: isSelected && theme.palette.grey.light,
        },
        pr: "0",
      }}
      endIcon={
        <Box
          sx={{
            fontSize: "14px !important",
            fontWeight: "400",
            width: "50px",
            textAlign: "center",
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflow: "clip",
          }}
        >
          {count}
        </Box>
      }
    >
      <Box
        sx={{
          width: "150px",
          textAlign: "left",
          fontSize: "14px",
          fontWeight: isSelected ? "bold" : "400",
        }}
      >
        {text}
      </Box>
    </Button>
  );
};

const EmailLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const { instance } = useMsal();
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { category, emailId } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const deleteEmail = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await EmailService.deleteEmail(instance, emailId);
      dispatch(setGlobalIsLoading({ isLoading: false }));
      navigate(`/emails/${category}`);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const getCategory = (value) => {
    if (value === "drafts") {
      return "Brouillon";
    } else if (value === "deleteditems") {
      return "Corbeille";
    } else if (value === "junkemail") {
      return "Spam";
    } else if (value === "archive") {
      return "Archive";
    } else {
      return "";
    }
  };
  const changeCategory = async (newCategory) => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await EmailService.updateEmailCategory(instance, emailId, newCategory);
      toast(`Email déplacé vers ${getCategory(newCategory)} avec succès`);
      navigate(`/emails/${category}`);
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  const markAsSeen = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      await EmailService.markEmailAsSeen(instance, emailId);
      toast("Email marqué comme lu avec succès");
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  return (
    <Box
      sx={{
        width: "calc(83vw + 10px)",
        height: "91vh",
        overflow: "auto",
      }}
    >
      {/* Header */}
      <FlexBetween
        sx={{
          bgcolor: theme.palette.grey[100],
          p: "10px 20px",
          height: "64px",
        }}
      >
        <Button
          startIcon={<MailOutline fontSize="large" />}
          sx={{
            height: "40px",
            bgcolor: theme.palette.alt[900],
            textTransform: "none",
            fontSize: "14px",
            fontWeight: "600",
            p: "10px 30px",
            textWrap: "nowrap",
            ":hover": {
              bgcolor: theme.palette.alt[900],
            },
          }}
          onClick={() => navigate("/emails/add")}
        >
          Nouveau mail
        </Button>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <ToolBarButton
            startIcon={emailIcons.basket}
            text="Supprimer"
            isDisabled={!emailId}
            onClick={() => setDeleteOpen(true)}
          />
          <ToolBarButton
            startIcon={emailIcons.archive}
            text="Archiver"
            isDisabled={!emailId}
            onClick={() => changeCategory("archive")}
          />
          {category !== "drafts" && (
            <Box ref={menuRef}>
              <ToolBarButton
                startIcon={emailIcons.moveto}
                endIcon={KeyboardArrowDownOutlined}
                text="Déplacer vers"
                isDisabled={!emailId}
                onClick={() => setMenuOpen(true)}
              />
              <Menu
                anchorReference="anchorEl"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                anchorEl={menuRef.current}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                MenuListProps={{
                  style: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.grey[600],
                    border: "1px solid",
                    borderColor: theme.palette.primary.main,
                    borderRadius: "6px",
                    width: "125px",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    changeCategory("drafts");
                    setMenuOpen(false);
                  }}
                >
                  Brouillon
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeCategory("deleteditems");
                    setMenuOpen(false);
                  }}
                >
                  Corbeille
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeCategory("junkemail");
                    setMenuOpen(false);
                  }}
                >
                  Spam
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeCategory("archive");
                    setMenuOpen(false);
                  }}
                >
                  Archive
                </MenuItem>
              </Menu>
            </Box>
          )}
          <ToolBarButton
            startIcon={emailIcons.respondeall}
            text="Répondre"
            isDisabled={!emailId}
            onClick={() => navigate(`/emails/${category}/${emailId}/reply-all`)}
          />
          <ToolBarButton
            startIcon={emailIcons.transfer}
            text="Transférer"
            isDisabled={!emailId}
            onClick={() => navigate(`/emails/${category}/${emailId}/transfer`)}
          />
          {category !== "drafts" && (
            <ToolBarButton
              startIcon={emailIcons.seen}
              text="Marquer comme lu"
              isDisabled={!emailId}
              onClick={() => markAsSeen()}
            />
          )}
          <SignOutButton />
        </Box>
      </FlexBetween>
      {/* Body */}
      <Box
        sx={{
          display: "flex",
          // height: "100%",
          width: "100%",
          overflow: "clip",
        }}
      >
        {/* Nav */}
        <Box
          sx={{
            bgcolor: theme.palette.grey[100],
            width: "231px",
            height: "82vh",
          }}
        >
          <NavButton
            icon={emailIcons.received}
            text="Boite de réception"
            isSelected={location.pathname.includes("inbox")}
            onClick={() => navigate("inbox")}
          />
          <NavButton
            icon={emailIcons.sent}
            text="Messages envoyés"
            isSelected={location.pathname.includes("sentItems")}
            onClick={() => navigate("sentItems")}
          />
          <NavButton
            icon={emailIcons.draft}
            text="Brouillon"
            isSelected={location.pathname.includes("drafts")}
            onClick={() => navigate("drafts")}
          />
          <NavButton
            icon={emailIcons.basket}
            text="Corbeille"
            isSelected={location.pathname.includes("deleteditems")}
            onClick={() => navigate("deleteditems")}
          />
          <NavButton
            icon={emailIcons.spam}
            text="Spam"
            isSelected={location.pathname.includes("junkemail")}
            onClick={() => navigate("junkemail")}
          />
          <NavButton
            icon={emailIcons.archive}
            text="Archive"
            isSelected={location.pathname.includes("archive")}
            onClick={() => navigate("archive")}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              startIcon={<CalendarTodayOutlined fontSize="large" />}
              sx={{
                height: "40px",
                color: theme.palette.alt[900],
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "600",
                p: "10px 30px",
                textWrap: "nowrap",
                border: "1px solid",
                borderColor: theme.palette.alt[900],
                width: "90%",
                mt: "20px",
              }}
              onClick={() => navigate("calendar")}
            >
              Calendrier
            </Button>
          </Box>
        </Box>
        <Outlet />
      </Box>
      <CustomDialog
        title={`Supprimer l'email`}
        content={`Vous êtes sûr de supprimer l'email?`}
        onClick={deleteEmail}
        open={deleteOpen}
        setOpen={setDeleteOpen}
      />
    </Box>
  );
};

export default EmailLayout;
