import React, { useEffect, useState } from "react";
import "./ActiveClient.css";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";
import AuthService from "Api/AuthService";

const ActiveClientsList = ({ clients }) => {
  return (
    <>
      {clients && clients.length > 0 ? (
        <>
          {clients.map((client) => (
            <div className="ActiveClientCard" key={client._id}>
              <div>
                <p className="clientName">{client.client.nom}</p>
              </div>
              <div className="statItem">
                <p className="statItemHeader">Personnels</p>
                <p className="statItemText">{client.nbPersonnel}</p>
              </div>
              <div className="statItem">
                <p className="statItemHeader">Chantiers en cours</p>
                <p className="statItemText">{client.nbSite}</p>
              </div>
              <div className="statItem">
                <p className="statItemHeader">Notification</p>
                <p className="statItemText">{client.nbNotification}</p>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70%",
              color: "black",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Aucun Client trouvé!
          </div>
        </>
      )}
    </>
  );
};

function ActiveClients() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);
  useEffect(() => {
    const getActiveClients = async () => {
      try {
        setIsLoading(true);
        const data = await AuthService.getClientStats();
        setClients(data);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getActiveClients();
  }, []);

  return (
    <div
      className="activeClientContainer"
      style={{
        height: "335px",
        borderRadius: "5px",
      }}
    >
      <div className="activeClientContHeader">
        <h4>Clients actifs</h4>
      </div>

      {isLoading ? (
        <>
          <Skeleton
            animation="pulse"
            variant="rectangular"
            sx={{
              bgcolor: theme.palette.grey[900],
              m: "20px",
              borderRadius: "6px",
              height: "80px",
            }}
          />
        </>
      ) : (
        <ActiveClientsList clients={clients} />
      )}
    </div>
  );
}

export default ActiveClients;
