import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
//import Home from '../Pages/Home';
import AddClient from "../Pages/Clients/AddClient";
import ListClients from "../Pages/Clients/ListClients";
import AddPersonel from "../Pages/Personnels/AddPersonel";
import ListPersonnels from "../Pages/Personnels/ListPersonnels";
import ClientDetails from "../Pages/Clients/ClientDetails";
import UpdateClients from "../Pages/Clients/UpdateClient";
import UpdatePersonnels from "../Pages/Personnels/UpdatePersonnel";
import ListConges from "../Pages/Personnels/ListConges";
import AddPrepaie from "../Pages/Prepaie/AddPrepaie";
import PrepaieList from "../Pages/Prepaie/PrepaieList";
import UpdatePrepaie from "../Pages/Prepaie/UpdatePrepaie";
import { ThemeProvider } from "@emotion/react";
import { useMemo } from "react";
import { Box, CssBaseline, createTheme } from "@mui/material";
import { themeSettings } from "../theme";
import NotFound from "../Pages/NotFound";
import Equipments from "../Pages/Equipments/Equipments";
import Financials from "../Pages/Financials/Financials";
import Profile from "../Pages/Profile";
import Sites from "../Pages/Sites/Sites";
 import Messages from "../Pages/Messages/Messages";
import LoadingOverlay from "Components/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import { selectGlobalIsLoading } from "state/global";
 import Emails from "../Pages/Email/Emails";
import Quotes from "../Pages/Quotes/Quotes";
import Invoices from "../Pages/Invoices/Invoices";
import LayoutX from "Components/Layouts/LayoutX";
 import Home from "Pages/Dashboard/Home";
import GestionRole from "Pages/Dashboard/GestionRole/Index";
 import ParametreERP from "Pages/Dashboard/ParametreERP/Index";
import MessagingService from "Api/messanging/service";
import {
  addNotification,
  incrementMessageNotificationCount,
  incrementNotificationCount,
} from "state/notification";
import { toast } from "react-toastify";
import MainLayout from "Components/Layouts/MainLayout";
import MainHeader from "Components/Headers/MainHeader";

const TestHome = () => {
  return (
    <Box>
      <MainHeader />
      <MainLayout>
        <Box
          sx={{
            fontSize: "38px",
            fontWeight: "bold",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          Bienvenue chez CS Consulting
        </Box>
      </MainLayout>
    </Box>
  );
};

const AdminRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useMemo(() => createTheme(themeSettings()), []);
  const isLoading = useSelector((state) => selectGlobalIsLoading(state));
  const user = useSelector((state) => state.authentification.user);

  useEffect(() => {
    const MessageServiceConnect = async () => {
      try {
        MessagingService.connect(user);
        MessagingService.on("message received", () => {
          if (!location.pathname.includes("messages")) {
            dispatch(incrementMessageNotificationCount({}));
          }
        });
        MessagingService.on("notification", (notification) => {
          if (!location.pathname !== "/") {
            dispatch(incrementNotificationCount({}));
          }
          dispatch(addNotification({ notification }));
        });
      } catch (error) {
        toast(`${error}`);
      }
    };
    if (user?._id) {
      MessageServiceConnect();
    }
  }, [user]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/clients" element={<ListClients />} />
        <Route exact path="addclient" element={<AddClient />} />
        <Route exact path="/gestionRole" element={<GestionRole />} />
        <Route exact path="/parametreERP" element={<ParametreERP />} />
        <Route path="/messages/*" element={<Messages />} />
        <Route path="/emails/*" element={<Emails />} />
        <Route
          exact
          path="updateclient/:idClient"
          element={<UpdateClients />}
        />
        <Route element={<LayoutX />}>
          <Route exact path="clientDetails/:id" element={<ClientDetails />} />
          <Route path="/sites/*" element={<Sites />} />
          <Route path="/equipments/*" element={<Equipments />} />
          <Route path="/financials/*" element={<Financials />} />
          <Route path="/invoices/*" element={<Invoices />} />
          <Route path="/quotes/*" element={<Quotes />} />
          <Route path="/profile/*" element={<Profile />} />
          <Route exact path="Listpersonnel" element={<ListPersonnels />} />
          <Route exact path="addPersonnel" element={<AddPersonel />} />
          <Route
            exact
            path="UpdatePersonnel/:idPersonnel"
            element={<UpdatePersonnels />}
          />
          <Route exact path="listconge" element={<ListConges />} />
          <Route exact path="prepaielist" element={<PrepaieList />} />
          <Route exact path="addprepaie" element={<AddPrepaie />} />
          <Route
            exact
            path="UpdatePrepaie/:idFiche"
            element={<UpdatePrepaie />}
          />
          <Route path="/*" element={<NotFound />} />
        </Route>
      </Routes>
      <LoadingOverlay open={isLoading} />
    </ThemeProvider>
  );
};

export default AdminRoute;
