import { useTheme } from "@emotion/react";
import { Skeleton } from "@mui/material";
import AuthService from "Api/AuthService";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function UsersList({ users }) {
  return (
    <div>
      {users && users.length !== 0 ? (
        <div>
          <p className="title">Equipes et Collaborateurs</p>
          {users.map((user) => (
            <div key={user._id} className="user-card">
              <p className="item">
                {user.firstName} {user.lastName}
              </p>
              <p className="item">admin au l'ERP</p>
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

function APropos() {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getUsers = async () => {
      try {
        setIsLoading(true);
        const data = await AuthService.getAllUsers();
        setUsers(data);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getUsers();
  }, []);
  return (
    <div className="AproposContainer">
      <div className="AproposHeader mb-3">
        <h4>A propos</h4>
        <button>
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.834 13.8333C13.2942 13.8333 13.6673 14.2064 13.6673 14.6667C13.6673 15.1269 13.2942 15.5 12.834 15.5H1.16732C0.70708 15.5 0.333984 15.1269 0.333984 14.6667C0.333984 14.2064 0.70708 13.8333 1.16732 13.8333H12.834ZM12.0031 0.987431L13.1799 2.16419C13.8298 2.8141 13.8298 3.86781 13.1799 4.51772L6.31544 11.3822C6.18814 11.5095 6.02297 11.5921 5.84474 11.6175L2.00065 12.1667L2.54981 8.32258C2.57527 8.14435 2.65785 7.97918 2.78516 7.85187L9.6496 0.987431C10.2995 0.337523 11.3532 0.337523 12.0031 0.987431ZM10.8264 2.16419L4.15824 8.83231L3.96231 10.205L5.33501 10.0091L12.0031 3.34095L10.8264 2.16419Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div className="AproposBody">
        {isLoading ? (
          <Skeleton
            animation="pulse"
            variant="rectangular"
            sx={{
              bgcolor: theme.palette.grey[900],
              m: "20px",
              borderRadius: "6px",
              height: "80px",
            }}
          />
        ) : (
          <div>
            <div>
              <p className="title">Domaines d'Expertise</p>
              <p className="item">Consulting</p>
            </div>
            <UsersList users={users} />
          </div>
        )}
      </div>
    </div>
  );
}

export default APropos;
