import React, { useState } from 'react'
import AdminCard from './AdminCard'
import "./Style.css"
import AddAdminModal from 'Components/Modals/AddAdminModal';

function AdminListe({ admin, isSuper, activeAdmin, setActiveAdmin }) {

    const [modalOpen, setModalOpen] = useState(false);

    const OpenAddAdminModal = () => {
        setModalOpen(true);
    };

    const closeAddAdminModal = () => {
        setModalOpen(false);
    };

   
    return (
        <div className='AdminListContainer mt-3'>
            {
                modalOpen && <AddAdminModal isOpen={modalOpen} onClose={closeAddAdminModal} />
            }
            <div className='AdminListContainerHeader'>
            <h4>{isSuper ? "Super admin" : "Administrateurs"}</h4>
                {
                    !isSuper && <button onClick={()=>OpenAddAdminModal()}><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0C9.71008 0 10.2857 0.575634 10.2857 1.28571V7.713L16.7143 7.71429C17.4244 7.71429 18 8.28992 18 9C18 9.71008 17.4244 10.2857 16.7143 10.2857L10.2857 10.2844V16.7143C10.2857 17.4244 9.71008 18 9 18C8.28992 18 7.71429 17.4244 7.71429 16.7143V10.2844L1.28571 10.2857C0.575634 10.2857 0 9.71008 0 9C0 8.28992 0.575634 7.71429 1.28571 7.71429L7.71429 7.713V1.28571C7.71429 0.575634 8.28992 0 9 0Z" fill="#1E5EFF" />
                    </svg>
                    </button>
                }
            </div>
            <div className={!isSuper && "AdminListContainerBody"}>
            {
                admin.map(admin => (
                    <AdminCard key={admin.createdAt} admin={admin} activeAdmin={activeAdmin} setActiveAdmin={setActiveAdmin} />
                ))
            }
            </div>
            
        </div>
    )
}

export default AdminListe
