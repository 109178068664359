import AdminListe from 'Components/Dashboard/GestionRoleComponents/AdminListe';
import DetailsAdmin from 'Components/Dashboard/GestionRoleComponents/DetailsAdmin';
import MainLayout from 'Components/Layouts/MainLayout';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../../redux/actions/UserAction';
import MainHeader from 'Components/Headers/MainHeader';

function GestionRole() {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.user ? state.user.users : []); 
    const [isLoading, setIsloading] = useState(true);
    const [superAdmins, setSuperAdmins] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [activeAdmin, setActiveAdmin] = useState(null);

    useEffect(() => {
        const getUserDetails = async () => {
            try {
                setIsloading(true);
                await dispatch(fetchUsers());
                setIsloading(false);
            } catch (error) {
                console.log('err', error);
            }
        };
        getUserDetails();
    }, [dispatch]);

    useEffect(() => {
        const newSuperAdmins = users.filter(user => user.role === "SUPER_ADMIN");
        const newAdmins = users.filter(user => user.role === "ADMIN");

        setSuperAdmins(newSuperAdmins);
        setAdmins(newAdmins);

        if (newSuperAdmins.length > 0) {
            setActiveAdmin(newSuperAdmins[0]);
        } else if (newAdmins.length > 0) {
            setActiveAdmin(newAdmins[0]);
        }
    }, [users]);
    return (
        <>
            <MainHeader />
            <MainLayout>
                {isLoading ? (
                    <h1>loading ...</h1>
                ) : (
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <AdminListe admin={superAdmins} isSuper activeAdmin={activeAdmin} setActiveAdmin={setActiveAdmin} />
                                <AdminListe admin={admins} activeAdmin={activeAdmin} setActiveAdmin={setActiveAdmin} />
                            </div>
                            <div className="col-md-8">
                                {activeAdmin && <DetailsAdmin admin={activeAdmin} />}
                            </div>
                        </div>
                    </div>
                )}
            </MainLayout>
        </>
    );
}

export default GestionRole;
