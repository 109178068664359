import { Box, IconButton } from "@mui/material";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import React, { useState } from "react";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTheme } from "@emotion/react";
import { Editor } from "react-draft-wysiwyg";
import { AttachFileOutlined } from "@mui/icons-material";

const TextEditor = ({ setContent, initialValue, setAttachments }) => {
  const theme = useTheme();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(initialValue))
    )
  );
  return (
    <Box
      sx={{
        height: "420px",
        // width:'74vw',
        color: "black",
        border: "1px solid",
        borderColor: theme.palette.grey.light,
        borderRadius: "3px",
        "#file-upload": {
          display: "none",
        },
      }}
    >
      <Editor
        locale="fr"
        toolbarCustomButtons={[
          <>
            <label htmlFor="file-upload">
              <AttachFileOutlined sx={{ color: "black", cursor: "pointer" }} />
            </label>
            <input
              type="file"
              name="files[]"
              multiple
              accept="*"
              onChange={(event) =>
                setAttachments(Array.from(event.currentTarget.files))
              }
              id="file-upload"
            />
          </>,
        ]}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        onChange={() =>
          setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        }
        placeholder="Entrez votre texte ici..."
        editorStyle={{
          height: "30vh",
          overflow: "clip",
          p: "5px",
          m: "0",
        }}
      />
    </Box>
  );
};

export default TextEditor;
