import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { formatDay, formatTime } from 'utils/format_date'

const EmailInfo = ({ email, isTransfer }) => {
    const theme = useTheme()
    const getDate = (value) => {
        const date = new Date(value)
        const day = formatDay(value).substring(0, 3)
        const formatedDate = date.toLocaleDateString('fr', { day: 'numeric', month: 'long', year: 'numeric' })
        const time = formatTime(date)
        return `${day}. ${formatedDate} à ${time}`
    }
    const getReceivers = ()=>{
        const values = email.toRecipients.map((item)=>{
            return `${item.emailAddress.name} <${item.emailAddress.address}>`
        })
        return values.join(',')
    }
    return (
        <Box
            id='email-info'
            sx={{
                bgcolor: theme.palette.grey[200],
                p: '20px',
                m: '0 25px',
                color: theme.palette.secondary[700],
                width:'71vw'
            }}
        >
            {isTransfer && (
                <Box sx={{
                    mb: '30px'
                }}>
                    <Typography>----------  message transféré ---------</Typography>
                    <Typography>De : {`${email.sender.emailAddress.name} <${email.sender.emailAddress.address}>`}</Typography>
                    <Typography>Date: {getDate(email.createdDateTime)}</Typography>
                    <Typography>Objet: {email.subject}</Typography>
                    <Typography>À: {getReceivers()}</Typography>
                    <Typography>Cc: ---</Typography>
                </Box>
            )}
            <Typography
                sx={{
                    fontSize: '16px',
                }} 
                dangerouslySetInnerHTML={{ __html: email.body.content ,}}
            />
        </Box>
    )
}

export default EmailInfo