import axios from 'axios';
import { baseURL } from './Constants';

const getAxiosInstance = () => {
    const instance = axios.create({
        baseURL: baseURL,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    // Add a response interceptor to retry the request
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const { config, response } = error;
            const isNetworkError = !response;
            const shouldRetry = isNetworkError || (response && response.status >= 500);
            if (shouldRetry) {
                config.__retryCount = config.__retryCount || 0;
                if (config.__retryCount >= 3) {
                    return Promise.reject(error);
                }
                config.__retryCount += 1;
                const delay = config.__retryCount * 1000;
                return new Promise(resolve => setTimeout(() => resolve(instance(config)), delay));
            }
            return Promise.reject(error);
        }
    );
    return instance;
};

const axiosClient = getAxiosInstance();

export default axiosClient;
