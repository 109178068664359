import React, { useEffect, useState } from "react";
import "./ListSites.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import SiteService from "Api/SiteService";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ListSites() {
  const navigate = useNavigate();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const stateLabels = {
    todo: "À faire",
    inprogress: "En cours",
    suspended: "Suspendu",
    done: "Terminé",
  };
  const stateColors = {
    todo: "#4FC0FF",
    inprogress: "#FBDD4E",
    suspended: "#F12B43",
    done: "#06A561",
  };
  useEffect(() => {
    const getSites = async () => {
      try {
        setIsLoading(true);
        const data = await SiteService.getSitesInDateDetails(selectedDate);
        setSites(data);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getSites();
  }, [selectedDate]);
  return (
    <div className="ListSitesContainer">
      <div className="header">
        <h4>Chantiers</h4>
        <div className="date-picker">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <button onClick={() => setPickerOpen(!pickerOpen)}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.66665 0.333496C10.0348 0.333496 10.3333 0.631973 10.3333 1.00016V1.66683H12.3333C13.0697 1.66683 13.6666 2.26378 13.6666 3.00016V12.3335C13.6666 13.0699 13.0697 13.6668 12.3333 13.6668H1.66665C0.930267 13.6668 0.333313 13.0699 0.333313 12.3335V3.00016C0.333313 2.26378 0.930267 1.66683 1.66665 1.66683H3.66665V1.00016C3.66665 0.631973 3.96512 0.333496 4.33331 0.333496C4.7015 0.333496 4.99998 0.631973 4.99998 1.00016V1.66683H8.99998V1.00016C8.99998 0.631973 9.29846 0.333496 9.66665 0.333496ZM12.3333 6.3335H1.66665V12.3335H12.3333V6.3335ZM3.66665 3.00016H1.66665V5.00016H12.3333V3.00016H10.3333V3.66683C10.3333 4.03502 10.0348 4.3335 9.66665 4.3335C9.29846 4.3335 8.99998 4.03502 8.99998 3.66683V3.00016H4.99998V3.66683C4.99998 4.03502 4.7015 4.3335 4.33331 4.3335C3.96512 4.3335 3.66665 4.03502 3.66665 3.66683V3.00016Z"
                  fill="white"
                />
              </svg>
              Calendrier
            </button>
            <DatePicker
              sx={{
                width: "0px",
                overflow: "clip",
                position: "absolute",
              }}
              open={pickerOpen}
              views={["year", "month"]}
              value={dayjs(selectedDate)}
              onChange={(value) => {
                setSelectedDate(new Date(value));
              }}
              onClose={() => setPickerOpen(false)}
            />
          </LocalizationProvider>
        </div>
      </div>
      {isLoading ? (
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={{
            bgcolor: "#D9D9D9",
            borderRadius: "6px",
            height: "100px",
          }}
        />
      ) : (
        <table className="table-personnel">
          <thead>
            <tr>
              <th>Client</th>
              <th>Chantier</th>
              <th>Date de début</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sites.length > 0 ? (
              sites.map((site, index) => (
                <tr
                  key={index}
                  className="site-row"
                  onClick={() => navigate(`sites/${site._id}`)}
                >
                  <td>{site.client.nom}</td>
                  <td>{site.name}</td>
                  <td>{site.begin_date.slice(0, 10)}</td>
                  <td>
                    <span
                      id="site-state"
                      style={{ backgroundColor: stateColors[site.state] }}
                    >
                      {stateLabels[site.state]}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <p id="not-found-text">Pas de chantier...</p>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ListSites;
