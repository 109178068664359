import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  getAllChatsEndPoint,
  getAllGroupsEndPoint,
  createConversationEndPoint,
} from "./Constants";

const AuthService = {
  getAllChats: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllChatsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const conversations = response.data.conversations;
      return conversations;
    } catch (error) {
      handleError(error);
    }
  },

  getAllGroups: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllGroupsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const conversations = response.data.conversations;
      return conversations;
    } catch (error) {
      handleError(error);
    }
  },

  createConversation: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.post(
        createConversationEndPoint,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const conversation = response.data.conversation;
      return conversation;
    } catch (error) {
      handleError(error);
    }
  },
};

export default AuthService;
