// id application (client) ccd67d27-2a12-47d5-98fc-26b0e7247ae3
// id projet 4993fe95-1322-49c0-9594-c85f58f8be97
// id annuaire f8cdef31-a31e-4b4a-93e4-5f571e91255a
// client  csc_client
// client  value -gf8Q~kBGtSg8aTRSX_2O2JZGhST-_gneiElWbt5
// client  secret id 1f6486f1-9c7c-4a89-be94-3d25725bd190
// url https://entra.microsoft.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/CallAnAPI/appId/ccd67d27-2a12-47d5-98fc-26b0e7247ae3/objectId/4993fe95-1322-49c0-9594-c85f58f8be97/isMSAApp~/true/defaultBlade/Overview/appSignInAudience/AzureADandPersonalMicrosoftAccount
export const baseURL = "https://graph.microsoft.com/v1.0";
export const getMyDetailsEndPoint = "me"
export const getMyProfilePhotoEndPoint = "me/photo/$value"
export const getAllUsersEndPoint = "users"
export const getMyEmailsEndPoint = (category) => `me/mailFolders/${category}/messages`//archive drafts inbox sentItems junkemail(spam) deleteditems(basket)
export const getEmailDetailsEndPoint = (id) => `me/messages/${id}`//todo :`me/messages/${id}`
export const deleteEmailEndPoint = (id) => `me/messages/${id}`
export const sendEmailEndPoint = "me/sendMail"
export const replyEmailEndPoint = (id) => `me/messages/${id}/reply`
export const replyAllEmailEndPoint = (id) => `me/messages/${id}/replyAll`
export const forwardEmailEmailEndPoint = (id) => `me/messages/${id}/forward`
export const updateEmailCategoryEndPoint = (id) => `me/messages/${id}/move`
export const updateEmailEndPoint = (id) => `me/messages/${id}`
export const getEmailAttachmentsEndPoint = (id) => `me/messages/${id}/attachments`
export const addEmailAttachmentsEndPoint = (id) => `me/messages/${id}/attachments`
export const filterEmailEndPoint = (category,isRead) => `me/mailFolders/${category}/messages?$filter=isRead eq ${isRead}`
export const getFilterByDateEmailsEndPoint = (start,end) => `me/messages?$filter=createdDateTime ge ${start} and createdDateTime le ${end}`
