import { useTheme } from "@emotion/react";
import { CloseOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import EmailAvatar from "Pages/Email/Components/EmailAvatar";
import React from "react";

const CustomAutoComplete = ({
  options,
  filterOptions,
  searchOptions,
  toggleOption,
  hint,
  initialValue,
  name,
  value,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        border: "1px solid",
        borderColor: theme.palette.grey[200],
        borderBottom: "none",
        "& .css-63uuw-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
          {
            color: theme.palette.secondary[700],
            fontSize: "14px",
          },
        pl: "15px",
        "& .css-63uuw-MuiAutocomplete-root .MuiOutlinedInput-root": {
          p: "2px",
        },
      }}
    >
      <Typography
        sx={{
          color: theme.palette.secondary[700],
          fontSize: "14px",
          fontWeight: "600",
        }}
      >
        {hint}
      </Typography>
      <Autocomplete
        sx={{
          flex: "1",
        }}
        name={name}
        multiple
        freeSolo
        defaultValue={initialValue}
        value={value}
        options={options}
        getOptionLabel={(option) => option}
        ChipProps={{
          style: {
            border: "1px solid",
            borderColor: theme.palette.grey.light,
            borderRadius: "3px",
            height: "32px",
            fontSize: "14px",
            color: theme.palette.secondary[700],
          },
          deleteIcon: (
            <CloseOutlined
              sx={{
                color: `${theme.palette.grey[600]} !important`,
                ":hover": {
                  color: theme.palette.grey[600],
                },
                width: "15px",
                height: "15px",
              }}
            />
          ),
        }}
        slotProps={{
          clearIndicator: {
            style: {
              color: theme.palette.grey[600],
            },
          },
          popupIndicator: {
            style: {
              color: theme.palette.grey[600],
            },
          },
        }}
        ListboxProps={{
          style: {
            color: "black",
            backgroundColor: "white",
          },
        }}
        // disableCloseOnSelect
        renderInput={(params) => <TextField {...params} />}
        renderOption={(props, option, { selected }) =>
          filterOptions(option) && (
            <MenuItem
              {...props}
              key={option}
              value={option}
              sx={{
                bgcolor: selected && `${theme.palette.grey[200]} !important`,
                borderBottom: "1px solid",
                borderColor: theme.palette.grey.light,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  gap: "10px",
                  textWrap: "nowrap",
                }}
              >
                <EmailAvatar name={option} />
                <Typography>{option}</Typography>
              </Box>
            </MenuItem>
          )
        }
        onChange={(event, value) => toggleOption(value)}
        noOptionsText="Aucun utilisateur trouvé!"
        filterOptions={searchOptions}
      />
    </Box>
  );
};

export default CustomAutoComplete;
