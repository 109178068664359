import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  createTodoEndPoint,
  deleteTodoEndPoint,
  getTodoDetailsEndPoint,
  getTodosDetailsEndPoint,
  getUserTodosEndPoint,
  updateTodoDetailsEndPoint,
} from "./Constants";

const TodoService = {
  createTodo: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.post(createTodoEndPoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const todo = response.data.todo;
      return todo;
    } catch (error) {
      handleError(error);
    }
  },
  getTodoDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getTodoDetailsEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const todo = response.data.todo;
      return todo;
    } catch (error) {
      handleError(error);
    }
  },
  getUserTodo: async (date) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getUserTodosEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date,
        },
      });
      const todos = response.data.todos;
      return todos;
    } catch (error) {
      handleError(error);
    }
  },
  getTodosDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getTodosDetailsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const todos = response.data.todos;
      return todos;
    } catch (error) {
      handleError(error);
    }
  },
  updateTodoDetails: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateTodoDetailsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const todo = response.data.todo;
      return todo;
    } catch (error) {
      handleError(error);
    }
  },
  deleteTodo: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      await axiosClient.delete(deleteTodoEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },
};

export default TodoService;
