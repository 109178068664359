import React from "react";
import { useMsal } from "@azure/msal-react";
import { MenuItem, TextField } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

export const SignOutButton = () => {
    const theme = useTheme()
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    
    return (
        <TextField
        inputProps={{
            id: "signout"
        }}
        label="Se déconnecter"
        select
        SelectProps={{
            IconComponent: () => (
                <KeyboardArrowDown
                    sx={{
                        color: theme.palette.grey.main,
                        pt:'10px',
                        fontSize:'35px'
                    }}
                />
            ),
            style: {
                color: theme.palette.grey[400],
                height: "40px",
            },
        }}
        InputLabelProps={{
            htmlFor: "signout",
            style: {
                fontSize: "14px",
                color: theme.palette.secondary[700],
                fontWeight:'500'
            },
        }}
        sx={{
            color: "black",
            width: "150px",
        }}
    >
        <MenuItem
            sx={{
                color: theme.palette.grey.main,
                height: "30px",
                ":hover": {
                    backgroundColor: theme.palette.grey.light,
                },
            }}
            onClick={() => handleLogout("popup")}
        >
            Se déconnecter en utilisant Popup
        </MenuItem>
        <MenuItem
            sx={{
                color: theme.palette.grey.main,
                height: "30px",
                ":hover": {
                    backgroundColor: theme.palette.grey.light,
                },
            }}
            onClick={() => handleLogout("redirect")}
        >
            Se déconnecter en utilisant la redirection
        </MenuItem>
    </TextField>
    )
}