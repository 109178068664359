import { useTheme } from '@emotion/react';
import { Box, Button, List, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomCircularProgress from 'Components/CustomCircularProgress';
import EmailBox from './Components/EmailBox';
import EmailService from 'Api/mailing/EmailService';
import { useMsal } from '@azure/msal-react';

const EmailsDetails = () => {
  const theme = useTheme()
  const {instance} = useMsal()
  const { emailId, category } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [emails, setEmails] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const getTitle = () => {
    if (category === 'inbox') {
      return "Messages reçus";
    } else if (category === "sentItems") {
      return "Messages envoyés";
    } else if (category === "drafts") {
      return "Brouillons";
    } else if (category === "deleteditems") {
      return "Corbeille"
    } else if (category === "junkemail") {
      return "Spam";
    } else if (category === "archive") {
      return "Archive";
    } else {
      return "";
    }
  };
  useEffect(() => {
    const getEmail = async () => {
      try {
        setIsLoading(true)
        const data = await EmailService.getUserEmails(instance,category);
        setEmails(data)
        const index = data.findIndex((item) => item.id === emailId)
        setCurrentIndex(index)
        setIsLoading(false)
      } catch (error) {
        toast(`${error}`)
      }
    }
    getEmail()
  }, [])
  return (
    <Box sx={{
      width: "100%",
      height: '100%'
    }}>
      {/* Header */}
      <Box>
        <Typography
          sx={{
            color: theme.palette.secondary[700],
            fontSize: "16px",
            fontWeight: "600",
            p: "5px 20px",
          }}
        >
          {getTitle()}
        </Typography>
        <Box sx={{
          p: '10px',
          pl: '20px',
          border: '1px solid',
          borderColor: theme.palette.grey[1100],
        }}>
          <Button
            disabled={currentIndex <= 0}
            sx={{
              fontSize: '14px',
              color: theme.palette.secondary[700],
              textTransform: 'none',
              borderRight: '1px solid',
              borderRightColor: theme.palette.grey[1400],
              p: '5px 20px',
              ":disabled": {
                color: theme.palette.grey[1500],
              }
            }}
            onClick={() => setCurrentIndex((prevValue) => prevValue - 1)}
          >
            Précédent
          </Button>
          <Button
            disabled={currentIndex >= emails.length - 1}
            sx={{
              fontSize: '14px',
              color: theme.palette.secondary[700],
              textTransform: 'none',
              p: '5px 20px',
              ":disabled": {
                color: theme.palette.grey[1500],
              }
            }}
            onClick={() => setCurrentIndex(prevValue => prevValue + 1)}
          >
            Suivant
          </Button>
          <Typography sx={{
            color: theme.palette.secondary[700],
            fontSize: '20px',
            fontWeight: 'bold',
            pt:'10px'
          }}>
            {emails[currentIndex]?.subject}
          </Typography>
        </Box>
      </Box>
      {isLoading ?
        (
          <CustomCircularProgress sx={{ height: '80%' }} />
        ) :
        (
          <Box sx={{
            height: '80%',
            overflow: 'auto',
          }}>
            <List>
              <Box sx={{
                border: '1px solid',
                borderColor: theme.palette.grey.light,
              }}>
              <EmailBox email={emails[currentIndex]} showAttachments={true} />
              </Box>
            </List>
          </Box>
        )
      }
    </Box>
  )
}

export default EmailsDetails