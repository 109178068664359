import Information from 'Components/Dashboard/Parametre/Information';
import APropos from 'Components/Dashboard/Parametre/APropos'
import MainLayout from 'Components/Layouts/MainLayout'
import React, { useState } from 'react'
import BannerAPropos from 'Components/Dashboard/Parametre/BannerAPropos';
import MainHeader from 'Components/Headers/MainHeader';

function ParametreERP() {
    const [isLoading, setIsloading] = useState(false);
  return (
    <>
    
    <MainHeader />
    <MainLayout>
        {
            isLoading ? <h1>loading ...</h1> :<div className="container">
                <BannerAPropos />
            <div className="row">
                <div className="col-md-4">
                    <Information/>
                </div>
                <div className="col-md-8">
                    <APropos/>
                </div>
            </div>
        </div>
        }
        
    </MainLayout>
</>
  )
}

export default ParametreERP;