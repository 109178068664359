import { useMsal } from '@azure/msal-react'
import { useTheme } from '@emotion/react'
import { DescriptionOutlined, KeyboardArrowDown } from '@mui/icons-material'
import { Box, IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Skeleton, TextField, Typography } from '@mui/material'
import EmailService from 'Api/mailing/EmailService'
import NotFoundError from 'Components/NotFoundError'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'


const AttachmentItem = ({ attachment }) => {
    const theme = useTheme()

    const [open, setOpen] = useState(false)
    const anchorEl = useRef(null)
    const downloadAttachment = () => {
        const link = document.createElement("a");
        link.href = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
        link.download = attachment.name;
        link.click();
    }

    return (
        <Box sx={{
            height: '48px',
            width: '260px',
        }}>
            <ListItem sx={{
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: "6px",
            }}>
                <ListItemIcon>
                    <DescriptionOutlined sx={{ color: theme.palette.grey[400], }} />
                </ListItemIcon>
                <ListItemAvatar sx={{
                    fontSize: '12px',
                    color: theme.palette.grey[400],
                    width: '180px'
                }}>
                    <Typography sx={{
                        width: '130px',
                        textOverflow: 'ellipsis',
                        overflow: 'clip',
                        textWrap: 'nowrap',
                    }}>
                        {attachment.name}
                    </Typography>
                    <Typography>
                        {attachment.size}
                    </Typography>
                </ListItemAvatar>
                <ListItemSecondaryAction>
                    <IconButton
                        ref={anchorEl}
                        onClick={() => setOpen(!open)}
                        sx={{
                            border: "1px solid",
                            borderColor: theme.palette.grey[50],
                            borderRadius: "4px",
                        }}
                    >
                        <KeyboardArrowDown
                            sx={{
                                color: theme.palette.grey.main,
                            }}
                        />
                    </IconButton>
                    <Menu
                        open={open}
                        anchorEl={anchorEl.current}
                        onClose={() => setOpen(false)}
                        anchorOrigin={{
                            horizontal: "left",
                            vertical: 'bottom'
                        }}
                    >
                        <MenuItem
                            sx={{
                                color: theme.palette.grey.main,
                                height: "30px",
                                ":hover": {
                                    backgroundColor: theme.palette.grey.light,
                                },
                            }}
                            onClick={() => {
                                setOpen(false)
                                downloadAttachment()
                            }}
                        >
                            Télécharger
                        </MenuItem>
                    </Menu>
                </ListItemSecondaryAction>
            </ListItem>
        </Box>
    )
}


const EmailAttachments = ({ emailId }) => {
    const theme = useTheme()
    const { instance } = useMsal()
    const [isLoading, setIsLoading] = useState(true)
    const [attachments, setAttachments] = useState([])
    useEffect(() => {
        const getAttachments = async () => {
            try {
                setIsLoading(true)
                const data = await EmailService.getEmailAttachments(instance, emailId);
                setAttachments(data)
                setIsLoading(false)
            } catch (error) {
                toast(`${error}`)
            }
        }
        getAttachments()
    }, [])
    return (
        <Box sx={{
            p: '20px'
        }}>
            {
                isLoading ? (
                    <Skeleton
                        animation="pulse"
                        variant="rectangular"
                        sx={{
                            bgcolor: theme.palette.grey[900],
                            borderRadius: "6px",
                            height: '48px',
                            width: '260px',
                        }}
                    />
                ) : (
                    <Box sx={{
                        height: '100%',
                        width: '90%',
                        display: 'flex',
                        gap: '15px',
                        flexWrap: 'wrap'
                    }}>
                        {
                            attachments && attachments.length > 0 ? (
                                <>
                                    {
                                        attachments.map((attachment) => (
                                            <AttachmentItem key={attachment.id} attachment={attachment} />
                                        ))
                                    }
                                </>
                            ) : (
                                <NotFoundError text="Aucune pièce jointe trouvée !" />
                            )
                        }
                    </Box>
                )
            }

        </Box>
    )
}

export default EmailAttachments