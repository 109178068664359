import { useTheme } from "@emotion/react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import EmailAvatar from "./Components/EmailAvatar";
import NotFoundError from "Components/NotFoundError";
import { useSelector } from "react-redux";
import { selectUser } from "state/auth";
import { formatDate, formatTime } from "utils/format_date";
import { groupeEmails } from "utils/groupe_emails";
import { useNavigate, useParams } from "react-router-dom";

const EmailsTable = ({ emails, startTitle, endTitle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { category } = useParams();
  const user = useSelector((state) => selectUser(state));

  const getDate = (value) => {
    const date = new Date(value);
    const today = new Date();
    if (date.getDate() === today.getDate()) {
      return null;
    } else if (date.getDate() === today.getDate() - 1) {
      return "Hier";
    } else {
      return formatDate(date);
    }
  };

  const getFormatedDate = (value) => {
    const date = new Date(value);
    const limitDate = new Date(value);
    const currentDate = new Date();
    const time = formatTime(date);
    limitDate.setDate(limitDate.getDate() + 7);
    if (currentDate >= limitDate) {
      return `${formatDate(date)} ${time}`;
    } else {
      const currentWeek = currentDate.toLocaleDateString("fr-FR", {
        weekday: "long",
      });
      return `${
        currentWeek[0].toUpperCase() + currentWeek.slice(1, 3)
      } ${time}`;
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      {/* Header */}
      {emails && Array.isArray(emails) && emails.length !== 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: theme.palette.primary.main,
            boxShadow: "none",
          }}
        >
          <Table aria-label="simple table">
            <TableHead
              sx={{
                borderBottom: "1px solid",
                borderBottomColor: theme.palette.grey[1100],
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.grey[600],
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "5px 15px",
                    width: "30%",
                  }}
                >
                  {startTitle}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.grey[600],
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "5px 15px",
                    width: "60%",
                  }}
                >
                  Objet
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.grey[600],
                    fontSize: "14px",
                    fontWeight: "600",
                    p: "5px 15px",
                    width: "10%",
                  }}
                >
                  {endTitle}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupeEmails(emails).map(({ date, emails }) => (
                <React.Fragment key={date}>
                  {getDate(date) && (
                    <TableRow
                      sx={{
                        bgcolor: theme.palette.grey[1200],
                      }}
                    >
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          p: "5px 0 5px 50px",
                          color: theme.palette.grey[600],
                          borderBottom: "none",
                        }}
                      >
                        {getDate(date)}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      ></TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      ></TableCell>
                    </TableRow>
                  )}
                  {emails.map((email) => (
                    <TableRow
                      key={email.id}
                      sx={{
                        "& .MuiTableCell-root": {
                          color: theme.palette.secondary.dark,
                          fontSize: "14px",
                          borderBottom: "1px solid",
                          borderBottomColor: theme.palette.grey.light,
                          pt: "5px",
                          pb: "5px",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        if (category === "drafts") {
                          navigate(`${email.id}/edit`);
                        } else {
                          navigate(`${email.id}`);
                        }
                      }}
                    >
                      <TableCell
                        scope="row"
                        sx={{
                          borderLeft: "4px solid",
                          borderLeftColor: email.isRead
                            ? theme.palette.primary.main
                            : theme.palette.alt.main,
                          borderRadius: "0",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                            gap: "10px",
                            textWrap: "nowrap",
                          }}
                        >
                          {category === "drafts" ? (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: theme.palette.error.light,
                              }}
                            >
                              [Brouillons]
                            </Typography>
                          ) : (
                            <EmailAvatar
                              name={email.sender.emailAddress.name}
                            />
                          )}
                          <Typography
                            sx={{
                              fontWeight: !email.isRead && "bold",
                            }}
                          >
                            {email.sender.emailAddress.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell scope="row">
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              textWrap: "nowrap",
                              fontSize: "14px",
                              fontWeight: email.isRead ? "600" : "bold",
                              color: email.isRead
                                ? theme.palette.secondary[700]
                                : theme.palette.alt.main,
                            }}
                          >
                            Titre de l’objet
                          </Typography>
                          <Typography
                            sx={{
                              textWrap: "nowrap",
                              fontSize: "14px",
                              color: theme.palette.grey[600],
                            }}
                          >
                            {email.subject}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ textWrap: "nowrap" }}>
                          {getFormatedDate(email.createdDateTime)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NotFoundError
          text="Aucun e-mail trouvé!"
          sx={{
            height: "50vh",
          }}
        />
      )}
    </Box>
  );
};

export default EmailsTable;
