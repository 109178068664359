import { useTheme } from "@emotion/react";
import { FilterListOutlined } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import FlexBetween from "Components/FlexBetween";
import React, { useRef, useState } from "react";

const EmailTableHeader = ({ title, getFilterEmails, setFilterOption, filterOption }) => {
  const theme = useTheme();
  const filterRef = useRef(null)
  const [filterOpen, setFilterOpen] = useState(false)
  const filterOptins = ['all', 'seen', 'notseen']
  const getFilterOption = (option) => {
    if (option === 'all') {
      return 'Tous'
    } else if (option === 'seen') {
      return 'Lus'
    } else if (option === 'notseen') {
      return 'Non Lu'
    } else {
      return ''
    }
  }
  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.grey[1100]
      }}
    >
      {/* Header */}
      <FlexBetween
        sx={{
          width: "100%",
          height: "36px",
          p: "0 20px",
        }}
      >
        <Typography
          sx={{
            color: theme.palette.secondary[700],
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          {title}
        </Typography>
        <Button
          ref={filterRef}
          endIcon={<FilterListOutlined />}
          sx={{
            color: theme.palette.secondary[700],
            fonstSize: '14px',
            textTransform: 'none'
          }}
          onClick={() => setFilterOpen(!filterOpen)}
        >
          Filter
        </Button>
        <Menu
          anchorReference="anchorEl"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          anchorEl={filterRef.current}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          MenuListProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.grey[600],
              border: "1px solid",
              borderColor: theme.palette.primary.main,
              borderRadius: "6px",
              width: "125px",
            },
          }}
        >
          {
            filterOptins.map((option) => (
              <MenuItem
                key={option}
                sx={{
                  bgcolor: filterOption === option && theme.palette.grey[300],
                  ":hover": {
                    bgcolor: filterOption === option && theme.palette.grey[300],
                  }
                }}
                onClick={() => {
                  getFilterEmails(option)
                  setFilterOption(option)
                  setFilterOpen(false)
                }}
              >
                {getFilterOption(option)}
              </MenuItem>
            ))
          }
        </Menu>
      </FlexBetween>
    </Box>
  );
};

export default EmailTableHeader;
