import FullCalendar from "@fullcalendar/react";
import { Box } from "@mui/material";
import timeGrid from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocal from "@fullcalendar/core/locales/fr";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { formatDate, formatDay } from "utils/format_date";
import { toast } from "react-toastify";
import CustomCircularProgress from "Components/CustomCircularProgress";
import EmailService from "Api/mailing/EmailService";
import { useMsal } from "@azure/msal-react";

const EmailsWeekView = ({ selectedDate }) => {
  const theme = useTheme();
  const { instance } = useMsal();
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const eventList = () => {
    const events = emails.map((email) => {
      return {
        title: email.subject,
        date: email.createdDateTime,
        backgroundColor: theme.palette.secondary.light,
        // borderColor: priorityColor(task.priority),
      };
    });
    return events;
  };

  useEffect(() => {
    const getEmails = async () => {
      try {
        setIsLoading(true);
        const currentDate = new Date(selectedDate);
        const weekStart = new Date(selectedDate);
        weekStart.setDate(currentDate.getDate() - currentDate.getDay());
        weekStart.setHours(0, 0, 0, 0);

        // week end
        const weekEnd = new Date(selectedDate);
        weekEnd.setDate(weekStart.getDate() + 6);
        weekEnd.setHours(23, 59, 59, 999);
        const start = formatDate(weekStart).replace(/\//g, "-");
        const end = formatDate(weekEnd).replace(/\//g, "-");
        const data = await EmailService.getFilterByDateEmails(
          instance,
          start,
          end
        );
        setEmails(data);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getEmails();
  }, [selectedDate]);

  return (
    <Box
      sx={{
        "& .fc .fc-col-header-cell-cushion": {
          textDecoration: "none",
          color: theme.palette.secondary[700],
        },
        "& .fc .fc-timegrid-axis-cushion": {
          color: theme.palette.secondary[700],
        },
        "& .fc .fc-timegrid-slot-label ": {
          color: theme.palette.secondary[700],
        },
        "& .fc .fc-day-today ": {
          backgroundColor: "white",
        },
        "& .fc-direction-ltr .fc-timegrid-more-link ": {
          bgcolor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        height: "82vh",
      }}
    >
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <FullCalendar
          plugins={[timeGrid, interactionPlugin]}
          initialView="timeGridWeek"
          locale={frLocal}
          headerToolbar="none"
          events={eventList()}
          dayHeaderContent={({ date }) => (
            <>{formatDay(date).substring(0, 3)}</>
          )}
          height="100%"
          initialDate={selectedDate}
          eventMaxStack={1}
          moreLinkContent={() => {
            return (
              <Box
                sx={{
                  flexGrow: "1",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "3px",
                  mt: "5px",
                }}
              >
                <Box
                  sx={{
                    bgcolor: theme.palette.secondary.light,
                    width: "5px",
                    height: "5px",
                    borderRadius: "50%",
                  }}
                />
                <Box
                  sx={{
                    bgcolor: theme.palette.secondary.light,
                    width: "5px",
                    height: "5px",
                    borderRadius: "50%",
                  }}
                />
                <Box
                  sx={{
                    bgcolor: theme.palette.secondary.light,
                    width: "5px",
                    height: "5px",
                    borderRadius: "50%",
                  }}
                />
              </Box>
            );
          }}
          eventClick={function (info) {
            info.jsEvent.preventDefault();
          }}
          moreLinkClick={() => null}
          // dateClick={(date) => handleDateClick(date)}
        />
      )}
    </Box>
  );
};

export default EmailsWeekView;
