import { Navigate, Route, Routes } from "react-router-dom";
import EmailLayout from "./EmailLayout";
import EmailCalendar from "./EmailCalendar";
import NotFound from "Pages/NotFound";
import AddEmail from "./AddEmail";
import EmailsDetails from "./EmailsDetails";
import EmailsList from "./EmailsList";
import TransferEmails from "./TransferEmails";
import ReplyEmails from "./ReplyEmails";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Box, Typography } from "@mui/material";
import { SignInButton } from "./Components/SignInButton";
import { useTheme } from "@emotion/react";
import MainLayout from "Components/Layouts/MainLayout";
import MainHeader from "Components/Headers/MainHeader";

const Emails = () => {
  const theme = useTheme();

  return (
    <>
      <MainHeader />
      <MainLayout>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <UnauthenticatedTemplate>
            <Box
              sx={{
                width: "100%",
                height: "80vh",
                borderBottom: "1px solid",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.secondary[700],
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                Veuillez vous connecter pour voir vos informations de courrier
              </Typography>
              <SignInButton />
            </Box>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Routes>
              <Route path="/" element={<EmailLayout />}>
                <Route path="" element={<Navigate to="inbox" />} />
                <Route path="/:category/*">
                  <Route path="" element={<EmailsList />} />
                  <Route path=":emailId/*">
                    <Route path="" element={<EmailsDetails />} />
                    <Route path="reply" element={<ReplyEmails />} />
                    <Route path="reply-all" element={<ReplyEmails />} />
                    <Route path="transfer" element={<TransferEmails />} />
                    <Route path="edit" element={<AddEmail />} />
                  </Route>
                </Route>
                <Route path="/add" element={<AddEmail />} />
              </Route>
              <Route path="/calendar" element={<EmailCalendar />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </AuthenticatedTemplate>
        </Box>
      </MainLayout>
    </>
  );
};

export default Emails;
