import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  getNotificationsEndPoint,
  sortNotificationsEndPoint,
} from "./Constants";
import { date } from "yup";

const NotificationService = {
  getNotifications: async (selectedDate) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getNotificationsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date: selectedDate,
        },
      });
      const notifications = response.data.notifications;
      return notifications;
    } catch (error) {
      handleError(error);
    }
  },
  sortNotifications: async (selectedDate, term) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(sortNotificationsEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date: selectedDate,
          term,
        },
      });
      const notifications = response.data.notifications;
      return notifications;
    } catch (error) {
      handleError(error);
    }
  },
};

export default NotificationService;
