import { useTheme } from '@emotion/react'
import { Box, Button, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, Typography } from '@mui/material'
import AuthService from 'Api/AuthService'
import CustomAutoComplete from 'Components/CustomAutoComplete'
import CustomCircularProgress from 'Components/CustomCircularProgress'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setGlobalIsLoading } from 'state/global'
import TextEditor from './TextEditor'
import { CloseOutlined, DescriptionOutlined, EmailOutlined } from '@mui/icons-material'
import EmailInfo from './Components/EmailInfo'
import { useDispatch } from 'react-redux'
import EmailService from 'Api/mailing/EmailService'
import { useMsal } from '@azure/msal-react'

const TransferEmails = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { instance } = useMsal()
    const [content, setContent] = useState('');
    const { emailId, category } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [users, setUsers] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [email, setEmail] = useState({});
    useEffect(() => {
        const getEmail = async () => {
            try {
                const data = await EmailService.getEmailDetails(instance, emailId);
                setEmail(data);
                setIsLoading(false);
            } catch (error) {
                toast(`${error}`);
            }
        }
        const getUsers = async () => {
            try {
                setIsLoading(true);
                const data = await AuthService.getStaff();
                setUsers(data);
                getEmail()
            } catch (error) {
                toast(`${error}`);
            }
        };
        getUsers();
    }, []);
    const searchOptions = (options, state) => {
        return options.filter((option) => `${option.first_name} ${option.last_name} ${option.email}`.includes(state.inputValue));
    };
    const addEmail = async (values) => {
        try {
            dispatch(setGlobalIsLoading({ isLoading: true }))
            const transferEmail = document.getElementById('email-info').innerHTML
            const body = `<div><div>${transferEmail}</div><hr/><div>${content}</div></div>`
            const data = {
                message: {
                    subject: values.subject,
                    body: {
                        contentType: "html",
                        content: body,
                    },
                }
            }
            data.message.toRecipients = values.toRecipients.map((item) => {
                return {
                    emailAddress: {
                        address: item.email
                    }
                }
            })
            data.message.ccRecipients = values.ccRecipients.map((item) => {
                return {
                    emailAddress: {
                        address: item.email
                    }
                }
            })
            data.message.bccRecipients = values.bccRecipients.map((item) => {
                return {
                    emailAddress: {
                        address: item.email
                    }
                }
            })
            if (attachments && attachments.length > 0) {
                const promises = attachments.map((file) => {
                  return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      resolve({
                        "@odata.type": "#microsoft.graph.fileAttachment",
                        name: file.name,
                        contentType: file.type,
                        contentBytes: reader.result.split(',')[1],
                      });
                    }
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                  })
                });
                data.message.attachments = await Promise.all(promises)
              }
            await EmailService.forwardEmail(instance, email.id, data)
            dispatch(setGlobalIsLoading({ isLoading: false }))
            navigate("/emails/sentItems")
        } catch (error) {
            dispatch(setGlobalIsLoading({ isLoading: false }))
            toast(`${error}`)
        }
    }

    return (
        <Box sx={{
            width: '100%',
            height: '100%'
        }}>
            <Typography sx={{
                color: theme.palette.secondary[700],
                fontSize: "16px",
                fontWeight: "600",
                textTransform: "none",
                borderBottom: "1px solid",
                borderBottomColor: theme.palette.grey[1100],
                p: '10px'
            }}>
                Transférer
            </Typography>
            {
                isLoading ?
                    (
                        <CustomCircularProgress sx={{
                            height: '90%'
                        }} />
                    )
                    :
                    (
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                toRecipients: [],
                                ccRecipients: [],
                                bccRecipients: [],
                            }}
                            onSubmit={addEmail}
                        // validationSchema={emailSchema}
                        >
                            {({ values, handleSubmit, setFieldValue }) => (
                                <Form>
                                    <Box sx={{
                                        height: '80vh',
                                        overflow: 'auto'
                                    }}>
                                        <List>
                                            <Typography sx={{
                                                color: theme.palette.secondary[700],
                                                fontSize: "20px",
                                                fontWeight: "600",
                                                textTransform: "none",
                                                borderBottom: "1px solid",
                                                borderBottomColor: theme.palette.grey[1100],
                                                p: '20px 10px'
                                            }}>
                                                {email.subject}
                                            </Typography>
                                            <CustomAutoComplete
                                                hint="Vers :"
                                                options={users}
                                                toggleOption={(value) => setFieldValue('toRecipients', value)}
                                                searchOptions={searchOptions}
                                                filterOptions={(option) => {
                                                    return !values.ccRecipients.includes(option) && !values.bccRecipients.includes(option)
                                                }}
                                                initialValue={values.toRecipients}
                                            />
                                            <CustomAutoComplete
                                                hint="Cc :"
                                                options={users}
                                                toggleOption={(value) => setFieldValue('ccRecipients', value)}
                                                searchOptions={searchOptions}
                                                filterOptions={(option) => {
                                                    return !values.toRecipients.includes(option) && !values.bccRecipients.includes(option)
                                                }}
                                                initialValue={values.ccRecipients}
                                            />
                                            <CustomAutoComplete
                                                hint="Cci :"
                                                options={users}
                                                toggleOption={(value) => setFieldValue('bccRecipients', value)}
                                                searchOptions={searchOptions}
                                                filterOptions={(option) => {
                                                    return !values.toRecipients.includes(option) && !values.ccRecipients.includes(option)
                                                }}
                                                initialValue={values.bccRecipients}
                                            />
                                            <EmailInfo email={email} isTransfer={true} />
                                            <TextEditor setContent={setContent} initialValue={''} setAttachments={setAttachments} />
                                            <Box sx={{
                                                width: '90%',
                                                display: 'flex',
                                                gap: '15px',
                                                flexWrap: 'wrap',
                                                m: '20px'
                                            }}>
                                                {attachments.map((file) => (
                                                    <ListItem sx={{
                                                        width: '260px',
                                                        border: '1px solid',
                                                        borderColor: theme.palette.grey[400],
                                                        borderRadius: "6px",
                                                    }}>
                                                        <ListItemIcon>
                                                            <DescriptionOutlined sx={{ color: theme.palette.grey[400], }} />
                                                        </ListItemIcon>
                                                        <ListItemAvatar sx={{
                                                            fontSize: '12px',
                                                            color: theme.palette.grey[400],
                                                            width: '150px',
                                                        }}>
                                                            <Typography sx={{
                                                                width: '130px',
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'clip',
                                                                textWrap: 'nowrap',
                                                            }}>
                                                                {file.name}
                                                            </Typography>
                                                            <Typography>
                                                                {file.size}
                                                            </Typography>
                                                        </ListItemAvatar>
                                                        <ListItemSecondaryAction sx={{
                                                            p: '0',
                                                            m: '0'
                                                        }}>
                                                            <IconButton
                                                                sx={{
                                                                    border: "1px solid",
                                                                    borderColor: theme.palette.grey[50],
                                                                    borderRadius: "4px",
                                                                    p: '0',
                                                                    m: '0'
                                                                }}
                                                                onClick={() => setAttachments(prevElm => prevElm.filter((element) => element !== file))}
                                                            >
                                                                <CloseOutlined
                                                                    sx={{
                                                                        color: theme.palette.grey.main,
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                            </Box>
                                            <Box sx={{
                                                width: '100%',
                                                height: '48px',
                                                backgroundColor: theme.palette.grey[1300],
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px',
                                                pl: '10px'
                                            }}>
                                                <Button
                                                    startIcon={<EmailOutlined />}
                                                    sx={{
                                                        width: "107px",
                                                        height: "32px",
                                                        fontSize: '14px',
                                                        textTransform: 'none',
                                                        backgroundColor: theme.palette.alt[900],
                                                        borderRadius: '3px',
                                                        fontWeight: '400',
                                                        ":hover": {
                                                            backgroundColor: theme.palette.alt[900],
                                                        }
                                                    }}
                                                    onClick={(event) => handleSubmit(event)}
                                                >
                                                    Envoyer
                                                </Button>
                                                <Button
                                                    sx={{
                                                        width: "107px",
                                                        height: "32px",
                                                        fontSize: '14px',
                                                        textTransform: 'none',
                                                        color: theme.palette.grey[700],
                                                        border: '1px solid',
                                                        borderColor: theme.palette.grey.main,
                                                        borderRadius: '3px',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Annuler
                                                </Button>
                                            </Box>
                                        </List>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    )}
        </Box>
    )
}

export default TransferEmails