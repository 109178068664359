import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  createEstimateEndPoint,
  deleteEstimateEndPoint,
  getAllEstimatesEndPoint,
  getClientEstimatesEndPoint,
  getEstimateDetailsEndPoint,
  getSitesEstimateEndPoint,
  searchEstimatesEndPoint,
  updateEstimateDetailsEndPoint,
} from "./Constants";

const EstimateService = {
  createEstimate: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.post(
        createEstimateEndPoint,
        { ...data, client: clientId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const estimate = response.data.estimate;
  
      // Log pour vérifier la réponse du serveur
      console.log("Réponse de l'API : ", estimate);
      return estimate;
    } catch (error) {
      console.error("Erreur lors de la création de l'estimation : ", error);
      handleError(error);
      return null;  // ou renvoyer l'erreur si nécessaire
    }
  },
  
  getEstimateDetails: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getEstimateDetailsEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const estimate = response.data.estimate;
      return estimate;
    } catch (error) {
      handleError(error);
    }
  },

  getEstimatesDetails: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getAllEstimatesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const estimates = response.data.estimates;
      return estimates;
    } catch (error) {
      handleError(error);
    }
  },

  getClientEstimates: async () => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(getClientEstimatesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          clientId,
        },
      });
      const estimates = response.data.estimates;
      return estimates;
    } catch (error) {
      handleError(error);
    }
  },

  getSiteEstimateDetails: async (siteId) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(getSitesEstimateEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          siteId,
        },
      });
      const estimate = response.data.estimate;
      return estimate;
    } catch (error) {
      handleError(error);
    }
  },

  updateEstimateDetails: async (id, data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.put(
        updateEstimateDetailsEndPoint(id),
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const estimate = response.data.estimate;
      return estimate;
    } catch (error) {
      handleError(error);
    }
  },

  deleteEstimate: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      await axiosClient.delete(deleteEstimateEndPoint(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      handleError(error);
    }
  },

  searchEstimates: async (query) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const clientId = localStorage.getItem("idClient");
      const response = await axiosClient.get(searchEstimatesEndPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          query,
          client: clientId,
        },
      });
      const estimates = response.data.estimates;
      return estimates;
    } catch (error) {
      handleError(error);
    }
  },
};

export default EstimateService;
