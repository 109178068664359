import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getAllPersonnelsByClient } from '../../redux/actions/PersonnelAction';

function PersonnelList({ fiches, handleDataFromChild, handleMonthFromChild,handlePersonnelFromChild }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // mois actuel
    const personnels = useSelector(state => state.personnel.personnels);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const idClient = localStorage.getItem('idClient')

                setLoading(true);
                await dispatch(getAllPersonnelsByClient(idClient));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log('Erreur lors de la récupération des données :', error);
            }
        };
        fetchData();
    }, [dispatch]);
    useEffect(() => {
        if (fiches.length > 0) {
            setSelectedRow(fiches[0]); // Sélectionne la première ligne par défaut
        }
    }, [fiches]);

    const filteredOptions = personnels.filter(personnel =>
        `${personnel?.firstname} ${personnel?.lastName}` || `${personnel?.NumEmpl}`
            .toLowerCase()
            .includes(searchInput.toLowerCase())
    );
    const changeMonth = (direction) => {
        setSelectedOption(null)
        if (direction === 'previous') {
            setCurrentMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1)); // Si le mois actuel est janvier, passe à décembre
        } else {
            setCurrentMonth((prevMonth) => (prevMonth === 11 ? 0 : prevMonth + 1)); // Si le mois actuel est décembre, passe à janvier
        }
    };

    useEffect(() => {
        handleMonthFromChild(currentMonth)
    }, [currentMonth])
    const handleChangeEmp = (selectedOption) => {
        setSelectedOption(selectedOption);
        handlePersonnelFromChild(selectedOption)
    };

    const handleRowClick = (fiche) => {
        handleDataFromChild(fiche);
        setSelectedRow(fiche); // Met à jour la ligne sélectionnée
    };
    

    return (
        <div className="container-fluid">

            <div className="personnel-header-container">

                <div className='filter-month' >
                    <button onClick={() => changeMonth('previous')}><svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.3871 0.209705L5.29289 0.292893L0.292893 5.29289C-0.0675907 5.65338 -0.0953203 6.22061 0.209705 6.6129L0.292893 6.70711L5.29289 11.7071C5.68342 12.0976 6.31658 12.0976 6.70711 11.7071C7.06759 11.3466 7.09532 10.7794 6.7903 10.3871L6.70711 10.2929L3.414 6.999L14 7C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5L3.416 4.999L6.70711 1.70711C7.06759 1.34662 7.09532 0.779392 6.7903 0.387101L6.70711 0.292893C6.34662 -0.0675907 5.77939 -0.0953203 5.3871 0.209705Z" fill="#1E5EFF" />
                    </svg>
                    </button>
                    <p>{currentMonth === 0 ? "Janvier" : currentMonth === 1 ? "Février" : currentMonth === 2 ? "Mars" : currentMonth === 3 ? "Avril" : currentMonth === 4 ? "Mai" : currentMonth === 5 ? "Juin" : currentMonth === 6 ? "Juillet" : currentMonth === 7 ? "Août" : currentMonth === 8 ? "Septembre" : currentMonth === 9 ? "Octobre" : currentMonth === 10 ? "Novembre" : "Décembre"}</p>
                    <button onClick={() => changeMonth('next')}><svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.6129 0.209705C9.22061 -0.0953203 8.65338 -0.0675907 8.29289 0.292893L8.2097 0.387101C7.90468 0.779392 7.93241 1.34662 8.29289 1.70711L11.5852 5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H11.5852L8.29289 10.2929L8.2097 10.3871C7.90468 10.7794 7.93241 11.3466 8.29289 11.7071C8.68342 12.0976 9.31658 12.0976 9.70711 11.7071L14.7071 6.70711L14.7903 6.6129C15.0953 6.22061 15.0676 5.65338 14.7071 5.29289L9.70711 0.292893L9.6129 0.209705Z" fill="#1E5EFF" />
                    </svg>
                    </button>
                </div>
                <div className='SearchPersonnelContainer'>
                    <Select

                        name="employe"
                        value={selectedOption}
                        onChange={(selectedOption) => handleChangeEmp(selectedOption)}
                        options={filteredOptions}
                        isSearchable
                        isClearable
                        onInputChange={(inputValue) => setSearchInput(inputValue)}
                        placeholder="Sélectionnez un employé"
                        className='gestionContainerHeaderSelect'
                        getOptionLabel={(option) => `${option.firstname} ${option.lastName} - ${option.NumEmpl}`}
                        getOptionValue={(option) => option._id}
                        styles={{
                            option: (provided) => ({
                                ...provided,
                                color: 'black', // Couleur du texte des options
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                color: 'black', // Couleur du texte de la valeur sélectionnée
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                color: 'black', // Couleur du texte du placeholder
                            }),
                        }}
                    />
                </div>


            </div>
            <div className="personnel-table-content">
                <table className="table-personnel">
                    <thead>
                        <tr>
                            <th>Employé</th>
                            <th>Référence</th>
                            <th>Date de création</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fiches.map((fiche, index) => (
                            <tr key={index}   onClick={() => handleRowClick(fiche)}
                            style={{
                                backgroundColor: selectedRow === fiche ? '#d3d3d3' : 'transparent'
                            }}                            
                            >
                                <td>{fiche?.personnel?.firstname} {fiche?.personnel?.lastName} </td>
                                <td>{fiche?.personnel?.NumEmpl}</td>
                                <td>{fiche?.creationDate.substr(0, 10)}</td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
            {/* <div className="pagination-container">
                {renderPagination()}
            </div> */}
        </div>
    );
}

export default PersonnelList;
