import { Box, Button, IconButton, Menu, MenuItem, SvgIcon, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import EmailAvatar from './EmailAvatar'
import FlexBetween from 'Components/FlexBetween'
import { MoreHorizOutlined, ReplyAllOutlined, ShortcutOutlined } from '@mui/icons-material'
import EmailInfo from './EmailInfo'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { formatDay, formatTime } from 'utils/format_date'
import { formatDate } from '@fullcalendar/core'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from 'state/auth'
import { emailIcons } from '../emailIcons'
import { setGlobalIsLoading } from 'state/global'
import EmailService from 'Api/EmailService'
import { toast } from 'react-toastify'
import CustomDialog from 'Components/CustomDialog'
import EmailAttachments from './EmailAttachments'
import { useMsal } from '@azure/msal-react'

const EmailBox = ({ email,showAttachments }) => {
    const theme = useTheme()
    const {instance} = useMsal()
    const menuRef = useRef(null)
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const { emailId, category } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const user = useSelector((state) => selectUser(state));
    const [deleteOpen, setDeleteOpen] = useState(false)
    const getReceivers = () => {
        const values = email.toRecipients.map((item) => {
            return item.emailAddress.address === user.email ? 'moi' : `${email.sender.emailAddress.name} <${email.sender.emailAddress.address}>`
        })
        return values.join(',')
    }
    const deleteEmail = async () => {
        try {
            dispatch(setGlobalIsLoading({ isLoading: true }))
            await EmailService.deleteEmail(instance,emailId)
            dispatch(setGlobalIsLoading({ isLoading: false }))
            navigate(`/emails/${category}`)
        } catch (error) {
            dispatch(setGlobalIsLoading({ isLoading: false }))
            toast(`${error}`)
        }
    }
    const archiveEmail = async () => {
        try {
            dispatch(setGlobalIsLoading({ isLoading: true }))
            await EmailService.updateEmailCategory(instance,emailId, 'archive')
            toast(`Email déplacé vers Archive avec succès`)
            navigate(`/emails/${category}`)
            dispatch(setGlobalIsLoading({ isLoading: false }))
        } catch (error) {
            dispatch(setGlobalIsLoading({ isLoading: false }))
            toast(`${error}`)
        }
    }
    const markAsSeen = async () => {
        try {
            dispatch(setGlobalIsLoading({ isLoading: true }))
            await EmailService.markEmailAsSeen(instance,emailId)
            toast('Email marqué comme lu avec succès')
            dispatch(setGlobalIsLoading({ isLoading: false }))
        } catch (error) {
            dispatch(setGlobalIsLoading({ isLoading: false }))
            toast(`${error}`)
        }
    }
    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                gap: '20px',
                color: 'black',
                p: '10px',
            }}>
                <EmailAvatar name={email.sender.emailAddress.name} sx={{ width: '42px', height: '42px',fontSize:'14px' }} />
                <Box flex='1'>
                    <Typography
                        sx={{
                            fontSize: '18px',                            
                        }}
                    >
                        {`${email.sender.emailAddress.name} <${email.sender.emailAddress.address}>`}
                    </Typography>
                    <Typography sx={{
                        fontSize: '14px',
                        textWrap: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '460px'
                    }}>
                        à: {getReceivers()}
                    </Typography>
                </Box>
                <Box>
                    <Box sx={{
                        display: 'flex'
                    }}>
                        <IconButton
                            onClick={() => {
                                if (location.pathname.includes('reply')) {
                                    navigate(`/emails/${category}/${email.id}/`)
                                } else {
                                    navigate(`/emails/${category}/${email.id}/reply`)
                                }
                            }}
                        >
                            <SvgIcon sx={{ color: theme.palette.alt.main, fontSize: '25px' }}>{emailIcons.responde}</SvgIcon>
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                if (location.pathname.includes('reply-all')) {
                                    navigate(`/emails/${category}/${email.id}/`)
                                } else {
                                    navigate(`/emails/${category}/${email.id}/reply-all`)
                                }
                            }}
                        >
                            <SvgIcon sx={{ color: theme.palette.alt.main, fontSize: '25px' }}>{emailIcons.respondeall}</SvgIcon>
                        </IconButton>
                        <IconButton
                            onClick={() => navigate(`/emails/${category}/${email.id}/transfer`)}
                        >
                            <SvgIcon sx={{ color: theme.palette.alt.main, fontSize: '25px' }}>{emailIcons.transfer}</SvgIcon>
                        </IconButton>
                        <IconButton
                            ref={menuRef}
                            onClick={() => setOpen(!open)}
                        >
                            <SvgIcon sx={{ color: theme.palette.alt.main, fontSize: '25px', mb: '-10px' }}>{emailIcons.more}</SvgIcon>
                        </IconButton>
                        <Menu
                            anchorReference="anchorEl"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            anchorEl={menuRef.current}
                            open={open}
                            onClose={() => setOpen(false)}
                            MenuListProps={{
                                style: {
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.grey[600],
                                    border: "1px solid",
                                    borderColor: theme.palette.primary.main,
                                    borderRadius: "6px",
                                    width: "160px",
                                },
                            }}
                        >
                            <MenuItem onClick={() => {
                                setOpen(false)
                                setDeleteOpen(true)
                            }}>
                                Supprimer
                            </MenuItem>
                            <MenuItem onClick={() => {
                                setOpen(false)
                                archiveEmail()
                            }}>
                                Archiver
                            </MenuItem>
                            <MenuItem onClick={() => {
                                setOpen(false)
                                markAsSeen()
                            }}>
                                Marquer comme lu
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Typography sx={{
                        fontSize: '12px',
                        color: theme.palette.grey[400],
                    }}>
                        {formatDay(email.createdDateTime).substring(0, 3)} {formatDate(email.createdDateTime)} {formatTime(email.createdDateTime)}
                    </Typography>
                </Box>
            </Box>
            <EmailInfo email={email} isTransfer={email.type === 'transfer'} />
            {email.hasAttachments && showAttachments && (
                <EmailAttachments emailId={email.id} />
            )}
            <Box
                sx={{
                    m: '20px 60px 15px 60px'
                }}
            >
                <Button
                    startIcon={
                        <SvgIcon sx={{ color: theme.palette.alt.main, fontSize: '25px !important', m: '12px -5px 0 10px' }}>{emailIcons.responde}</SvgIcon>
                    }
                    sx={{
                        fontSize: '14px',
                        color: theme.palette.secondary[700],
                        textTransform: 'none',
                        border: '1px solid',
                        borderColor: theme.palette.grey[1400],
                        borderRadius: '4px',
                        height: '32px',
                        width: '114px',
                        mr: '10px',

                    }}
                    onClick={() => {
                        if (location.pathname.includes('reply')) {
                            navigate(`/emails/${category}/${email.id}/`)
                        } else {
                            navigate(`/emails/${category}/${email.id}/reply`)
                        }
                    }}
                >Répondre</Button>
                <Button
                    startIcon={
                        <SvgIcon sx={{ color: theme.palette.alt.main, fontSize: '25px !important', m: '12px -5px 0 10px' }}>{emailIcons.transfer}</SvgIcon>
                    }
                    sx={{
                        fontSize: '14px',
                        color: theme.palette.secondary[700],
                        textTransform: 'none',
                        border: '1px solid',
                        borderColor: theme.palette.grey[1400],
                        borderRadius: '4px',
                        height: '32px',
                        width: '114px',
                    }}
                    onClick={() => navigate(`/emails/${category}/${email.id}/transfer`)}
                >Transférer</Button>
            </Box>
            <CustomDialog
                title={`Supprimer l'email`}
                content={`Vous êtes sûr de supprimer l'email?`}
                onClick={deleteEmail}
                open={deleteOpen}
                setOpen={setDeleteOpen}
            />
        </Box>
    )
}

export default EmailBox