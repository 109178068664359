import { useTheme } from "@emotion/react";
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import avatar from "../../Images/avatar.png";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "state/auth";
import { formatDate, formatTime } from "utils/format_date";
import {
  selectSelectedConversation,
  setSelectedConversation,
} from "state/conversation";

const Chat = ({ conversation }) => {
  const theme = useTheme();
  const user = useSelector(state => state.authentification.user)
  const selectedConversation = useSelector((state) =>
    selectSelectedConversation(state)
  );
  const dispatch = useDispatch();

  const getConversationName = () => {
    if (conversation.isGroupChat) {
      return conversation.name;
    } else {
      const college = conversation.users.filter(
        (item) => item._id !== user._id
      )[0] ?? {};
      return college.firstName + " " + college.lastName;
    }
  };

  const getConversationDateTime = () => {
    if (conversation.lastMessage) {
      if (
        formatDate(conversation?.lastMessage?.createdAt) ===
        formatDate(new Date())
      ) {
        return formatTime(conversation?.lastMessage?.createdAt);
      } else {
        return formatDate(conversation?.lastMessage?.createdAt);
      }
    } else {
      return "";
    }
  };

  const getLastMessage = () => {
    if (conversation.lastMessage) {
      const isSent = conversation.lastMessage.sender._id === user._id;
      if (isSent) {
        return "Vous : " + conversation.lastMessage.content;
      } else {
        if (conversation.isGroupChat) {
          return (
            conversation.lastMessage.sender.firstName +
            " " +
            conversation.lastMessage.sender.lastName +
            " : " +
            conversation.lastMessage.content
          );
        } else {
          return conversation.lastMessage.content;
        }
      }
    } else {
      return "";
    }
  };

  return (
    <ListItem
      sx={{
        height: "72px",
        width: "100%",
        p: "0",
      }}
    >
      <ListItemButton
        sx={{
          width: "100%",
          bgcolor:
            selectedConversation?._id === conversation._id &&
            theme.palette.grey[400],
          p: "0",
          ":hover": {
            bgcolor:
              selectedConversation?._id === conversation._id
                ? theme.palette.grey[400]
                : theme.palette.grey[200],
          },
        }}
        onClick={() => dispatch(setSelectedConversation({ conversation }))}
      >
        <ListItemAvatar
          sx={{
            height: "76px",
            width: "76px",
            p: "0",
            borderRadius: "50%",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            backgroundImage: `url(${avatar})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "70%",
          }}
        />
        <ListItemText
          sx={{
            display: "flex",
            p: "0",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: theme.palette.secondary[700],
              }}
            >
              {getConversationName()}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: theme.palette.grey[10],
                whiteSpace: "nowrap",
                width: "250px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {getLastMessage()}
            </Typography>
          </Box>
        </ListItemText>
        <ListItemSecondaryAction
          sx={{
            p: "0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: theme.palette.grey[10],
            }}
          >
            {getConversationDateTime()}
          </Typography>
          {conversation.unseenCount > 0 && (
            <Box
              sx={{
                height: "18px",
                width: "18px",
                p: "0",
                borderRadius: "50%",
                bgcolor: theme.palette.error[600],
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {conversation.unseenCount}
            </Box>
          )}
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
};

export default Chat;
