import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EmailsTable from "./EmailsTable";
import CustomCircularProgress from "Components/CustomCircularProgress";
import EmailTableHeader from "./Components/EmailTableHeader";
import { toast } from "react-toastify";
import { setGlobalIsLoading } from "state/global";
import { useDispatch } from "react-redux";
import EmailService from "Api/mailing/EmailService";
import { useMsal } from "@azure/msal-react";

const EmailsList = () => {
  const { category } = useParams();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [emails, setEmails] = useState([]);
  const dispatch = useDispatch();
  const [filterOption, setFilterOption] = useState("all");
  const [filterEmails, setFilterEmails] = useState(null);
  useEffect(() => {
    const getEmails = async () => {
      try {
        setIsLoading(true);
        setFilterEmails(null);
        setFilterOption("all");
        setEmails([]);
        const data = await EmailService.getUserEmails(instance, category);
        setEmails(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getEmails();
  }, [category]);
  const getTitle = () => {
    if (category === "inbox") {
      return { title: "Messages reçus", start: "De", end: "Reçu" };
    } else if (category === "sentItems") {
      return { title: "Messages envoyés", start: "Vers", end: "Reçu" };
    } else if (category === "drafts") {
      return { title: "Brouillons", start: "Vers", end: "Modifié" };
    } else if (category === "deleteditems") {
      return { title: "Corbeille", start: "", end: "Modifié" };
    } else if (category === "junkemail") {
      return { title: "Spam", start: "De", end: "Reçu" };
    } else if (category === "archive") {
      return { title: "Archive", start: "Vers", end: "Archivé le" };
    } else {
      return { title: "", start: "", end: "" };
    }
  };
  const getFilterEmails = async (option) => {
    try {
      if (option === "all") {
        setFilterEmails(null);
        return;
      }
      if (!["seen", "notseen"].includes(option)) {
        throw new Error("Option de filtre invalide");
      }
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = await EmailService.getFilterEmails(
        instance,
        category,
        option === "seen"
      );
      setFilterEmails(data);
      dispatch(setGlobalIsLoading({ isLoading: false }));
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {/* Header */}
      <EmailTableHeader
        title={getTitle().title}
        getFilterEmails={getFilterEmails}
        setFilterOption={setFilterOption}
        filterOption={filterOption}
      />
      {isLoading ? (
        <CustomCircularProgress sx={{ height: "70vh" }} />
      ) : (
        <EmailsTable
          startTitle={getTitle().start}
          endTitle={getTitle().end}
          emails={filterEmails ? filterEmails : emails}
        />
      )}
    </Box>
  );
};

export default EmailsList;
