import FullCalendar from "@fullcalendar/react";
import { Box } from "@mui/material";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocal from "@fullcalendar/core/locales/fr";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { formatDate, formatDay } from "utils/format_date";
import { toast } from "react-toastify";
import CustomCircularProgress from "Components/CustomCircularProgress";
import EmailService from "Api/mailing/EmailService";
import { useMsal } from "@azure/msal-react";

const EmailsMonthView = ({ selectedDate }) => {
  const theme = useTheme();
  const { instance } = useMsal();
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const eventList = () => {
    const events = emails.map((email) => {
      return {
        title: email.subject,
        date: email.createdDateTime,
        backgroundColor: theme.palette.secondary.light,
        // borderColor: priorityColor(task.priority),
      };
    });
    return events;
  };

  useEffect(() => {
    const getEmails = async () => {
      try {
        setIsLoading(true);
        // month start
        const monthStart = new Date(selectedDate);
        monthStart.setDate(1);
        monthStart.setHours(0, 0, 0, 0);

        // month end
        const monthEnd = new Date(selectedDate);
        monthEnd.setMonth(monthEnd.getMonth() + 1);
        monthEnd.setDate(0);
        monthEnd.setHours(23, 59, 59, 999);
        const start = formatDate(monthStart).replace(/\//g, "-");
        const end = formatDate(monthEnd).replace(/\//g, "-");
        const data = await EmailService.getFilterByDateEmails(
          instance,
          start,
          end
        );
        setEmails(data);
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getEmails();
  }, [selectedDate]);
  return (
    <Box
      sx={{
        "& .fc-daygrid-day-frame": {
          bgcolor: theme.palette.grey[100],
        },
        "& .fc-daygrid-event-harness": {
          width: "90%",
          textOverflow: "ellipsis",
        },
        "& .fc-daygrid-day-events": {
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .fc .fc-col-header-cell-cushion": {
          textDecoration: "none",
          color: theme.palette.secondary[700],
        },
        "& .fc .fc-daygrid-day-number": {
          textDecoration: "none",
          color: theme.palette.secondary[700],
        },
        "& .fc-direction-ltr .fc-daygrid-event.fc-event-end": {
          textDecoration: "none",
          color: theme.palette.secondary[700],
        },
        height: "82vh",
      }}
    >
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale={frLocal}
          headerToolbar="none"
          events={eventList()}
          height="100%"
          initialDate={selectedDate}
          dayHeaderContent={({ date }) => (
            <>{formatDay(date).substring(0, 3)}</>
          )}
          dayMaxEvents={3}
          moreLinkContent={() => {
            return (
              <Box
                sx={{
                  flexGrow: "1",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "3px",
                  mt: "5px",
                }}
              >
                <Box
                  sx={{
                    bgcolor: theme.palette.secondary.light,
                    width: "5px",
                    height: "5px",
                    borderRadius: "50%",
                  }}
                />
                <Box
                  sx={{
                    bgcolor: theme.palette.secondary.light,
                    width: "5px",
                    height: "5px",
                    borderRadius: "50%",
                  }}
                />
                <Box
                  sx={{
                    bgcolor: theme.palette.secondary.light,
                    width: "5px",
                    height: "5px",
                    borderRadius: "50%",
                  }}
                />
              </Box>
            );
          }}
          eventClick={function (info) {
            info.jsEvent.preventDefault();
          }}
          moreLinkClick={() => null}
        />
      )}
    </Box>
  );
};

export default EmailsMonthView;
