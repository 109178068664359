import React from 'react'
import "./Style.css"

function AdminCard({ admin, activeAdmin, setActiveAdmin }) {
    return (
        <div 
            className={`AdminCardContainer ${admin._id === activeAdmin._id ? 'active' : ''}`} 
            onClick={() => setActiveAdmin(admin)}
        >
            <div>
                <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="50.3138" height="50" rx="25" fill="#00001B" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.5176 19.6426C17.5176 15.2046 21.1379 11.6069 25.6037 11.6069C30.0696 11.6069 33.6899 15.2046 33.6899 19.6426C33.6899 24.0807 30.0696 27.6784 25.6037 27.6784C21.1379 27.6784 17.5176 24.0807 17.5176 19.6426Z" fill="#1E5EFF" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.6797 46.156C13.0322 40.1792 18.4056 35.7144 24.8283 35.7144C31.3306 35.7144 36.7574 40.2905 38.0254 46.3788C34.1038 48.6758 29.5481 50 24.8267 49.9999C19.9813 49.9999 15.4677 48.5882 11.6797 46.156Z" fill="#1E5EFF" />
                </svg>
            </div>
            <div className='AdminCardContainerINfo'>
                <h6>{admin.firstName} {admin.lastName}</h6>
                <p>{admin.createdAt.slice(0, 10)}</p>
            </div>
        </div>
    )
}

export default AdminCard
