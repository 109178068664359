import { useTheme } from "@emotion/react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import SiteService from "Api/SiteService";
import CustomCircularProgress from "Components/CustomCircularProgress";
import FlexBetween from "Components/FlexBetween";
import NotFoundError from "Components/NotFoundError";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/format_date";
import { toast } from "react-toastify";
import EstimateService from "Api/EstimateService";
import { setGlobalIsLoading } from "state/global";
import { useDispatch } from "react-redux";
import { addEstimate } from "state/financials";
import EstimateArray from "./EstimateArray";
import PopUp from "Components/Popup";
import AddSuccessPopUp from "Components/AddSuccessPopUp";

const AddEstimate = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const dispatch = useDispatch();
  const [estimates, setEstimates] = useState([]);
  const navigate = useNavigate();
  const [addSuccessOpen, setAddSuccessOpen] = useState(false);

  const getSiteType = (type) => {
    if (type === "residential") {
      return "résidentiel";
    } else if (type === "commercial") {
      return "commercial";
    } else if (type === "infrastructure") {
      return "infrastructure";
    } else {
      return "";
    }
  };

  useEffect(() => {
    const getSites = async () => {
      try {
        setIsLoading(true);
        const data = await SiteService.getSitesWithoutEstimate();
        setSelectedSite(data[0]);
        setSites(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getSites();
  }, []);

  const onClick = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      console.log("Estimates to send: ", estimates);
      const data = await EstimateService.createEstimate({
        site: selectedSite?._id,
        estimates,
      });
      dispatch(addEstimate({ estimate: data }));
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setAddSuccessOpen(true);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  
  useEffect(() => {
    console.log("Estimates updated: ", estimates);
  }, [estimates]);
  
  return (
    <Box 
    sx={{
      height: "90vh",
      overflow: "auto",
      width: "100%",
    }}>
      {/* Header */}
      <FlexBetween className="header-gest-pages"> 
        <Typography
          variant="h3"
          fontWeight="bold"
          color={theme.palette.secondary.light}
        >
          Nouvelle Estimation
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            sx={{
              width: "100px",
              fontSize: "16px",
              fontWeight: "400",
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.alt.main,
              border: "1px solid",
              borderRadius: "4px",
              borderColor: theme.palette.grey.light,
              textTransform: "none",
              ":hover": {
                backgroundColor: theme.palette.primary[400],
              },
            }}
            onClick={() => navigate(-1)}
          >
            Annuler
          </Button>
          <Button
            sx={{
              width: "100px",
              fontSize: "16px",
              fontWeight: "400",
              backgroundColor: theme.palette.alt.main,
              textTransform: "none",
              ":hover": {
                backgroundColor: theme.palette.alt[400],
              },
            }}
            onClick={onClick}
          >
            Ajouter
          </Button>
        </Box>
      </FlexBetween>
      <Box
        sx={{
          backgroundColor: theme.palette.grey[100],
          borderRadius: "6px",
          height: "100%",    
          padding: "5px",
          p: "20px",
          width: "95%",
          margin:"auto",
        }}
      >
        {sites && Array.isArray(sites) && sites.length > 0 ? (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              rowGap: "20px",
            }}
          >
            <TextField
              inputProps={{
                id: "sites",
              }}
              label="Chantier"
              select
              onChange={(e) => {
                setSelectedSite(
                  sites.find((item) => item._id === e.target.value)
                );
              }}
              value={selectedSite?._id}
              SelectProps={{
                IconComponent: () => (
                  <KeyboardArrowDown
                    sx={{
                      color: theme.palette.grey.main,
                    }}
                  />
                ),
                style: {
                  color: theme.palette.grey[400],
                  backgroundColor: theme.palette.primary.main,
                  height: "45px",
                },
              }}
              InputLabelProps={{
                htmlFor: "sites",
                style: {
                  fontSize: "16px",
                  color: theme.palette.grey[400],
                  marginTop: "-6px",
                },
              }}
              sx={{
                border: "1px solid",
                borderColor: theme.palette.grey[50],
                borderRadius: "4px",
              }}
            >
              {sites?.map((site) => (
                <MenuItem
                  key={site._id}
                  sx={{
                    color: theme.palette.grey.main,
                    backgroundColor:
                      site._id === selectedSite?._id &&
                      theme.palette.grey.light,
                    height: "30px",
                    ":hover": {
                      backgroundColor: theme.palette.grey.light,
                    },
                  }}
                  value={site._id}
                >
                  {site.name}
                </MenuItem>
              ))}
            </TextField>
            <Box
              sx={{
                height: "45px",
                backgroundColor: theme.palette.primary.main,
                p: "5px 20px",
                border: "1px solid",
                borderColor: theme.palette.grey[200],
                borderRadius: "4px",
              }}
            >
              <FlexBetween>
                <Box>
                  <Typography
                    sx={{
                      color: theme.palette.grey[600],
                      fontSize: "12px",
                    }}
                  >
                    Type de chantier
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.grey[400],
                      fontSize: "12px",
                    }}
                  >
                    {selectedSite?.type ? getSiteType(selectedSite.type) : "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: theme.palette.grey[600],
                      fontSize: "12px",
                    }}
                  >
                    Date de début
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.grey[400],
                      fontSize: "12px",
                    }}
                  >
                    {selectedSite?.begin_date
                      ? formatDate(selectedSite.begin_date)
                      : "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: theme.palette.grey[600],
                      fontSize: "12px",
                    }}
                  >
                    Date de fin
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.grey[400],
                      fontSize: "12px",
                    }}
                  >
                    {selectedSite?.end_date
                      ? formatDate(selectedSite.end_date)
                      : "-"}
                  </Typography>
                </Box>
              </FlexBetween>
            </Box>
            {isLoading ? (
              <CustomCircularProgress />
            ) : (
              <>
                {selectedSite && (
                  <EstimateArray
                    title="Estimation des ressources matérielles"
                    type="material"
                    estimates={estimates}
                    setEstimates={setEstimates}
                  />
                )}
                {selectedSite && (
                  <EstimateArray
                    title="Estimation des ressources humaines"
                    type="human"
                    estimates={estimates}
                    setEstimates={setEstimates}
                  />
                )}
                {selectedSite && (
                  <EstimateArray
                    title="Estimation des charges logistiques"
                    type="logical"
                    estimates={estimates}
                    setEstimates={setEstimates}
                  />
                )}
                {selectedSite && (
                  <EstimateArray
                    title="Estimation des autres charges"
                    type="other"
                    estimates={estimates}
                    setEstimates={setEstimates}
                  />
                )}
              </>
            )}
          </Box>
        ) : (
          <NotFoundError text="Aucun chantier trouvé !" />
        )}
        {!selectedSite &&
          (!sites || !Array.isArray(sites) || sites.length > 0) && (
            <NotFoundError text="Veuillez sélectionner un chanier pour continuer!" />
          )}
      </Box>
      <PopUp open={addSuccessOpen}>
        <AddSuccessPopUp
          title={"Ajout de nouvelle estimation confirmé"}
          onClick={() => {
            setAddSuccessOpen(false);
            navigate("/financials/estimates");
          }}
        />
      </PopUp>
    </Box>
  );
};

export default AddEstimate;
