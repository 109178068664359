import React, { useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import './Forms.css'
import { addPersonnels } from '../../redux/actions/PersonnelAction';
import { useDispatch } from 'react-redux';
import ValidateModal from '../Modals/ValidateModal';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    NumEmpl: Yup.string().required('Employee number is required'),
    phone: Yup.string().required('Phone number is required'),
    nationalite: Yup.string().required('Nationality is required'),
    identite: Yup.string().required('Identity is required'),
    PaysNaissance: Yup.string().required('Country of birth is required'),
    villeNaissance: Yup.string().required('City of birth is required'),
    dateNaissance: Yup.string().required('Date of birth is required'),
    age: Yup.string().required('Age is required'),
    adresse: Yup.string().required('Address is required'),
    departement: Yup.string().required('Department is required'),
    TypeContrat: Yup.string().required('Contract type is required'),
    nss: Yup.string().required('Social security number is required'),
    TypeTravail: Yup.string().required('Work type is required'),
    tempsTravail: Yup.string().required('Work time is required'),
    salaire: Yup.string().required('Salary is required'),
    soldeConge: Yup.string().required('Annual leave balance is required'),
    dateEntree: Yup.string().required('Entry date is required'),
    // dateSortie: Yup.string().required('Exit date is required'),
    dossierComplet: Yup.boolean().required('Complete file status is required'),
    dpai: Yup.boolean().required('DPAI status is required'),
    contrat: Yup.boolean().required('Contract status is required'),
    commentaire: Yup.string()
})
function AddPeronnel() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const successAdd = () => {
        openModal()
        setTimeout(() => {
            closeModal()

        }, 2000);
    }
    // const handleSave = async (values) => {


    //     await dispatch(addPersonnels(values))
    //         .then((result) => {
    //             if (result) {
    //                 successAdd()
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });

    // }

    const handleSave = async (values) => {
        try {
           
            await dispatch(addPersonnels(values));
            successAdd();
            await new Promise(resolve => setTimeout(resolve, 2000));
            navigate(-1);
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div className="formulaire-main-container">
            {modalOpen &&
                <ValidateModal isOpen={modalOpen} onClose={closeModal} message="Demande d'ajout envoyée" />
            }
            <Formik
                enableReinitialize
                validationSchema={validationSchema}

                initialValues={{
                    firstname: '',
                    lastName: '',
                    NumEmpl: '',
                    phone: '',
                    nationalite: '',
                    identite: '',
                    PaysNaissance: '',
                    villeNaissance: '',
                    dateNaissance: '',
                    age: '',
                    adresse: '',
                    departement: '',
                    TypeContrat: '',
                    nss: '',
                    TypeTravail: '',
                    tempsTravail: '',
                    salaire: '',
                    soldeConge: '',
                    dateEntree: '',
                    dateSortie: '',
                    dossierComplet: false,
                    dpai: false,
                    contrat: false,
                    commentaire: '',
                    client: localStorage.getItem('idClient')

                }}
                onSubmit={(values, actions) => {
                    handleSave(values);

                }}
            >
                {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    errors,
                    isValid,
                    touched,
                    setFieldValue,
                }) => (
                    <Form className="formulaire-conatiner">
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className='form-title'>Informations Personnelles</h3>
                                <div className="mb-3">
                                    <label htmlFor="firstname" className="form-label">Nom</label>
                                    <input type="text" className="form-control"
                                        name="firstname"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstname}
                                    />
                                    {errors.firstname && touched.firstname && (
                                        <span className="errorText">
                                            {errors.firstname}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="lastName" className="form-label">Prénom</label>
                                    <input type="text" className="form-control"
                                        name="lastName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                    />
                                    {errors.lastName && touched.lastName && (
                                        <span className="errorText">
                                            {errors.lastName}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="NumEmpl" className="form-label">Numéro de l’employé</label>
                                        <input type="text" className="form-control"
                                            name="NumEmpl"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.NumEmpl}
                                        />
                                        {errors.NumEmpl && touched.NumEmpl && (
                                            <span className="errorText">
                                                {errors.NumEmpl}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="phone" className="form-label">Numéro de téléphone</label>
                                        <input type="text" className="form-control"
                                            name="phone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone}
                                        />
                                        {errors.phone && touched.phone && (
                                            <span className="errorText">
                                                {errors.phone}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="nationalite" className="form-label">Nationalité</label>
                                        <input type="text" className="form-control"
                                            name="nationalite"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nationalite}
                                        />
                                        {errors.nationalite && touched.nationalite && (
                                            <span className="errorText">
                                                {errors.nationalite}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="identite" className="form-label">Identité</label>
                                        <input type="text" className="form-control"
                                            name="identite"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.identite}
                                        />
                                        {errors.identite && touched.identite && (
                                            <span className="errorText">
                                                {errors.identite}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="PaysNaissance" className="form-label">Pays de naissance </label>
                                        <input type="text" className="form-control"
                                            name="PaysNaissance"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.PaysNaissance}
                                        />
                                        {errors.PaysNaissance && touched.PaysNaissance && (
                                            <span className="errorText">
                                                {errors.PaysNaissance}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="villeNaissance" className="form-label">Ville de naissance</label>
                                        <input type="text" className="form-control"
                                            name="villeNaissance"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.villeNaissance}
                                        />
                                        {errors.villeNaissance && touched.villeNaissance && (
                                            <span className="errorText">
                                                {errors.villeNaissance}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="dateNaissance" className="form-label">Date de naissance</label>
                                        <input type="date" className="form-control"
                                            name="dateNaissance"
                                            onChange={(e) => {
                                                handleChange(e);
                                                const { value } = e.target;
                                                if (value) {
                                                  const today = new Date();
                                                  const birthDate = new Date(value);
                                                  let age = today.getFullYear() - birthDate.getFullYear();
                                                  const monthDiff = today.getMonth() - birthDate.getMonth();
                                                  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                                                    age--;
                                                  }
                                                  setFieldValue('age', age);
                                                }
                                              }}
                                              onBlur={handleBlur}
                                              value={values.dateNaissance}
                                        />
                                        {errors.dateNaissance && touched.dateNaissance && (
                                            <span className="errorText">
                                                {errors.dateNaissance}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="age" className="form-label">Age</label>
                                        <input type="text" className="form-control"
                                            name="age"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.age}
                                            readOnly
                                        />
                                        {errors.age && touched.age && (
                                            <span className="errorText">
                                                {errors.age}
                                            </span>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className='form-title'>Informations Professionnelles</h3>
                                <div className="mb-3">
                                    <label htmlFor="adresse" className="form-label">Adresse</label>
                                    <input type="text" className="form-control"
                                        name="adresse"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.adresse}
                                    />
                                    {errors.adresse && touched.adresse && (
                                        <span className="errorText">
                                            {errors.adresse}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="departement" className="form-label">Département</label>
                                    <input type="text" className="form-control"
                                        name="departement"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.departement}
                                    />
                                    {errors.departement && touched.departement && (
                                        <span className="errorText">
                                            {errors.departement}
                                        </span>
                                    )}
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="TypeContrat" className="form-label">Type de contrat</label>
                                        {/* <input type="text" className="form-control"
                                            name="TypeContrat"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.TypeContrat}
                                        /> */}
                                        <select name="TypeContrat" className="form-control" onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.TypeContrat}>
                                            <option value="" disabled> Sélectionnez</option>
                                            <option value="CDI">CDI</option>
                                            <option value="CDD">CDD</option>
                                            <option value="CDI-C">CDI-C</option>
                                            <option value="Contrat d'apprentissage">Contrat d'apprentissage</option>
                                            <option value="Contrat de professionnalisation">Contrat de professionnalisation</option>
                                            <option value="Stage">Stage</option>
                                            <option value="Intérim">Intérim</option>
                                            <option value="Indépendant">Indépendant</option>
                                            <option value="Autre">Autre</option>
                                        </select>
                                        {errors.TypeContrat && touched.TypeContrat && (
                                            <span className="errorText">
                                                {errors.TypeContrat}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="nss" className="form-label">NSS (Numéro de Sécurité Sociale)</label>
                                        <input type="text" className="form-control"
                                            name="nss"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nss}
                                        />
                                        {errors.nss && touched.nss && (
                                            <span className="errorText">
                                                {errors.nss}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="TypeTravail" className="form-label">Type de travail</label>
                                        {/* <input type="text" className="form-control"
                                            name="TypeTravail"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.TypeTravail}
                                        /> */}
                                        <select name="TypeTravail" className="form-control" onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.TypeTravail}>
                                            <option value="" disabled> Sélectionnez</option>
                                            <option value="Cadre">Cadre</option>
                                            <option value="Employé">Employé</option>
                                            <option value="Ouvrier">Ouvrier</option>
                                            <option value="Artisan">Artisan</option>
                                            <option value="Commerçant">Commerçant</option>
                                            <option value="Chef d'entreprise">Chef d'entreprise</option>
                                        </select>
                                        {errors.TypeTravail && touched.TypeTravail && (
                                            <span className="errorText">
                                                {errors.TypeTravail}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="tempsTravail" className="form-label">Temps de travail</label>
                                        {/* <input type="text" className="form-control"
                                            name="tempsTravail"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.tempsTravail}
                                        /> */}
                                          <select name="tempsTravail" className="form-control" onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.tempsTravail}>
                                            <option value="" disabled> Sélectionnez</option>
                                            <option value="Temps plein">Temps plein</option>
                                            <option value="Temps partiel">Temps partiel</option>
                                            <option value="Travail posté">Travail posté</option>
                                            <option value="Travail saisonnier">Travail saisonnier</option>
                                            <option value="Horaire flexible">Horaire flexible</option>
                                            <option value="Forfait jour">Forfait jour</option>
                                        </select>
                                        {errors.tempsTravail && touched.tempsTravail && (
                                            <span className="errorText">
                                                {errors.tempsTravail}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="salaire" className="form-label">Salaire</label>
                                        <input type="text" className="form-control"
                                            name="salaire"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.salaire}
                                        />
                                        {errors.salaire && touched.salaire && (
                                            <span className="errorText">
                                                {errors.salaire}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="soldeConge" className="form-label">Solde de congé annuel</label>
                                        <input type="text" className="form-control"
                                            name="soldeConge"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.soldeConge}
                                        />
                                        {errors.soldeConge && touched.soldeConge && (
                                            <span className="errorText">
                                                {errors.soldeConge}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className='small_inpt_form'>
                                        <label htmlFor="dateEntree" className="form-label">Date d’entrée</label>
                                        <input type="date" className="form-control"
                                            name="dateEntree"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.dateEntree}
                                        />
                                        {errors.dateEntree && touched.dateEntree && (
                                            <span className="errorText">
                                                {errors.dateEntree}
                                            </span>
                                        )}
                                    </div>
                                    <div className='small_inpt_form'>
                                        <label htmlFor="dateSortie" className="form-label">Date de sortie</label>
                                        <input type="date" className="form-control"
                                            name="dateSortie"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.dateSortie}
                                        />
                                        {errors.dateSortie && touched.dateSortie && (
                                            <span className="errorText">
                                                {errors.dateSortie}
                                            </span>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <h3 className='form-title'>Informations administrative</h3>
                                <div className="d-flex align-items-center justify-content-evenly mt-3">

                                    <div className='radio-form-container'>
                                        <div className="form-check">
                                            <label htmlFor="dossierComplet" className="form-label">Complete File</label>
                                            <input type="radio" name="dossierComplet" value="true" onChange={handleChange} defaultChecked={values.dossierComplet} />  <label className="form-check-label" htmlFor="dossierComplet">Oui</label>
                                            <input type="radio" name="dossierComplet" value="false" onChange={handleChange} defaultChecked={!values.dossierComplet} />  <label className="form-check-label" htmlFor="dossierComplet">Non</label>
                                        </div>
                                        {errors.dossierComplet && touched.dossierComplet && (
                                            <span className="errorText">
                                                {errors.dossierComplet}
                                            </span>
                                        )}
                                    </div>
                                    <div className='radio-form-container'>
                                        <div className="form-check">
                                            <label htmlFor="dpai" className="form-label">DPAI</label>
                                            <input type="radio" name="dpai" value="true" onChange={handleChange} defaultChecked={values.dpai} /> <label className="form-check-label" htmlFor="dpai">Oui</label>
                                            <input type="radio" name="dpai" value="false" onChange={handleChange} defaultChecked={!values.dpai} />  <label className="form-check-label" htmlFor="dpai">Non</label>
                                        </div>
                                        {errors.dpai && touched.dpai && (
                                            <span className="errorText">
                                                {errors.dpai}
                                            </span>
                                        )}
                                    </div>
                                    <div className='radio-form-container'>
                                        <div className="form-check">
                                            <label htmlFor="contrat" className="form-label">Contrat</label>
                                            <input type="radio" name="contrat" value="true" onChange={handleChange} defaultChecked={values.contrat} />  <label className="form-check-label" htmlFor="contrat">Oui</label>
                                            <input type="radio" name="contrat" value="false" onChange={handleChange} defaultChecked={!values.contrat} />  <label className="form-check-label" htmlFor="contrat">Non</label>
                                        </div>
                                        {errors.contrat && touched.contrat && (
                                            <span className="errorText">
                                                {errors.contrat}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="commentaire" className="form-label">Commentaire</label>
                                    <textarea type="text" className="form-control"
                                        name="commentaire"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.commentaire}
                                    />
                                    {errors.commentaire && touched.commentaire && (
                                        <span className="errorText">
                                            {errors.commentaire}
                                        </span>
                                    )}
                                </div>
                                <div className="d-flex flex-row justify-content-end" >
                                    <button type="submit" onClick={handleSubmit} style={{ width: "120px" }} className='DarkBtn d-flex justify-content-center'>Ajouter</button>
                                </div>
                            </div>
                        </div>
                    </Form >

                )}
            </Formik>
        </div>

    )
}

export default AddPeronnel