import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedConversation } from "state/conversation";
import { formatTime } from "utils/format_date";

const Message = ({ message, isSent }) => {
  const theme = useTheme();
  const selectedConversation = useSelector((state) => selectSelectedConversation(state))

  const getSender = ()=>{
    if(isSent){
      return ''
    }else{
      if(selectedConversation.isGroupChat){
        return message.sender.firstName + ' ' + message.sender.lastName
      }else{
        return ''
      }
    }
  }

  return (
    <Box
      sx={{
        bgcolor: isSent ? theme.palette.alt[900] : theme.palette.grey.light,
        padding: "10px",
        margin: "5px 20px",
        borderRadius: "4px",
        width: "55%",
        alignSelf: isSent && "end",
      }}
    >
      <Typography
        sx={{
          color: isSent
            ? theme.palette.primary.main
            : theme.palette.secondary[700],
          fontSize: "16px",
          p: "0 10px",
          wordBreak:'break-word'
        }}
      >
        {message.content}
      </Typography>
      <Box sx={{
        display:'flex',
        justifyContent:'right',
        gap:'10px'
      }}>
          <Typography
            sx={{
              fontSize: "12px",
              color: theme.palette.alt[900],
            }}
          >
            {getSender()}
          </Typography>
        <Typography
          sx={{
            color: theme.palette.secondary[900],
            fontSize: "12px",
          }}
        >
          {formatTime(message.createdAt)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Message;
