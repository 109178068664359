import { useTheme } from "@emotion/react";
import { Box, List } from "@mui/material";
import ConversationService from "Api/ConversationService";
import Chat from "Pages/Messages/Chat";
import CustomCircularProgress from "Components/CustomCircularProgress";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectChats, setChats } from "state/conversation";
import { toast } from "react-toastify";
import NotFoundError from "Components/NotFoundError";

const CollegeList = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const chats = useSelector((state) => selectChats(state));

  useEffect(() => {
    const getCollege = async () => {
      try {
        setIsLoading(true);
        const data = await ConversationService.getAllChats();
        dispatch(setChats({ chats: data }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getCollege();
  }, []);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.grey[100],
        height: "84vh",
        width: "100%",
      }}
    >
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <>
          {chats && Array.isArray(chats) && chats.length !== 0 ? (
            <Box
              sx={{
                height: "82vh",
                overflow: "auto",
              }}
            >
              {chats.map((chat) => (
                <Chat key={chat._id} conversation={chat} />
              ))}
            </Box>
          ) : (
            <NotFoundError text="Aucun collègue trouvé !" />
          )}
        </>
      )}
    </Box>
  );
};

export default CollegeList;
