import React, { useState} from 'react';
import ValidateModal from './ValidateModal';
import AddAdminForm from 'Components/Dashboard/GestionRoleComponents/AddAdmin';

const AddAdminModal = ({ isOpen, onClose }) => {
    const [loading, setLoading] = useState(false);
   
  

 




 

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
 

   
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
       
            {loading ? <p>Chargement...</p> :
                <div className="modal-content-personnel" >
                   <AddAdminForm closeModalAdd={onClose}/>
                </div>
            }
        </div>
    );
};

export default AddAdminModal;
