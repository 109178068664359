import React, { useEffect, useState } from "react";
import "./Notif.css";
import { toast } from "react-toastify";
import NotificationService from "Api/NotificationService";
import { Skeleton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { formatDate } from "utils/format_date";
import { useDispatch, useSelector } from "react-redux";
import { selectNotifications, setNotifications } from "state/notification";
import { useNavigate } from "react-router-dom";

const NotificationItem = ({ notif, notificationNavigate }) => {
  return (
    <div className="NotifContent" onClick={() => notificationNavigate(notif)}>
      <div>
        <p className="notifTitle">{notif.title}</p>
        <p className="notifTime">{formatDate(notif.createdAt)}</p>
      </div>
      <p className="notifText">{notif.description}</p>
    </div>
  );
};

const NotificationsList = ({ notifications }) => {
  const navigate = useNavigate();
  const notificationNavigate = (notif) => {
    localStorage.setItem("NameClient", notif.client.nom);
    if (notif.type === "site") {
      localStorage.setItem("idClient", notif.client._id);
      navigate(`/sites/${notif.site._id}`);
    } else if (notif.type === "invoice") {
      localStorage.setItem("idClient", notif.client._id);
      navigate(`/invoices/${notif.invoice._id}`);
    } else if (notif.type === "quote") {
      localStorage.setItem("idClient", notif.client._id);
      navigate(`/quotes/${notif.quote._id}`);
    } else if (notif.type === "financials-invoice") {
      localStorage.setItem("idClient", notif.client._id);
      navigate(`/financials/invoices/${notif.financials_invoice._id}`);
    } else if (notif.type === "financials-quote") {
      localStorage.setItem("idClient", notif.client._id);
      navigate(`/financials/quotes/${notif.financials_quote._id}`);
    } else if (notif.type === "commande") {
      localStorage.setItem("idClient", notif.client._id);
      navigate(`/financials/commandes/${notif.commande._id}`);
    } else {
      toast("Type de notification inconnu");
    }
  };
  return (
    <>
      {notifications && notifications.length > 0 ? (
        <>
          {notifications.map((notif) => (
            <NotificationItem
              key={notif._id}
              notif={notif}
              notificationNavigate={notificationNavigate}
            />
          ))}
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70%",
              color: "black",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            Aucune notification trouvée!
          </div>
        </>
      )}
    </>
  );
};

function NotificationClient({ selectedDate }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const notifications = useSelector((state) => selectNotifications(state));
  const [filterBy, setFilterBy] = useState("");
  const [filtredNotifications, setFilredNotifications] = useState(null);
  const filterOptions = ["", "name", "date"];
  useEffect(() => {
    const getNotifications = async () => {
      try {
        setFilredNotifications(null);
        setFilterBy("");
        setIsLoading(true);
        const data = await NotificationService.getNotifications(selectedDate);
        dispatch(setNotifications({ notifications: data ?? [] }));
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getNotifications();
  }, [selectedDate]);
  const sortNotifications = async (option) => {
    try {
      if (!option || !filterOptions.includes(option)) {
        setFilterBy("");
        setFilredNotifications(null);
        return;
      }
      setIsLoading(true);
      const currentNotifications = await NotificationService.sortNotifications(
        selectedDate,
        option
      );
      setFilredNotifications(currentNotifications);
      setFilterBy(option);
      setIsLoading(false);
    } catch (error) {
      setFilredNotifications(null);
      setIsLoading(false);
      toast(`${error}`);
    }
  };
  return (
    <div
      className="notifContainerDash"
      style={{
        height: "335px",
        borderRadius: "5px",
      }}
    >
      <div className="d-flex flex-row justify-content-between align-items-center notifContHeader">
        <h4>Notification</h4>
        <select
          value={filterBy}
          onChange={(e) => sortNotifications(e.target.value)}
          style={{
            width: "100px",
            height: "30px",
            fontSize: "1rem",
            fontWeight: "bold",
            color: "black",
            backgroundColor: "white",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          <option value="" style={{ fontWeight: "bold" }}>
            Trier par
          </option>
          <option value="name">Nom</option>
          <option value="date">Date</option>
        </select>
      </div>

      {isLoading ? (
        <>
          <Skeleton
            animation="pulse"
            variant="rectangular"
            sx={{
              bgcolor: theme.palette.grey[900],
              m: "20px",
              borderRadius: "6px",
              height: "80px",
            }}
          />
        </>
      ) : (
        <NotificationsList
          notifications={filtredNotifications ?? notifications}
        />
      )}
    </div>
  );
}

export default NotificationClient;
