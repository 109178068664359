import { useTheme } from "@emotion/react";
import {
  ArrowBackOutlined,
  CloseOutlined,
  DescriptionOutlined,
  EmailOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material";
import AuthService from "Api/AuthService";
import CustomAutoComplete from "Components/CustomAutoComplete";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomCircularProgress from "Components/CustomCircularProgress";
import { setGlobalIsLoading } from "state/global";
import { Form, Formik } from "formik";
import CustomField from "Components/CustomField";
import TextEditor from "./TextEditor";
import { useDispatch } from "react-redux";
import EmailService from "Api/mailing/EmailService";
import { useMsal } from "@azure/msal-react";

const AddEmail = () => {
  const theme = useTheme();
  const { emailId, category } = useParams();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [email, setEmail] = useState({
    subject: "",
    content: "",
    toRecipients: [],
    ccRecipients: [],
    bccRecipients: [],
  });
  const formatData = (value) => {
    return {
      first_name: value.emailAddress.name.split(" ")[0] ?? "",
      last_name: value.emailAddress.name.split(" ")[1] ?? "",
      email: value.emailAddress.address ?? "",
    };
  };

  useEffect(() => {
    const getEmail = async () => {
      try {
        const data = await EmailService.getEmailDetails(instance, emailId);
        setEmail({
          subject: data.subject,
          content: data.body.content,
          toRecipients: data.toRecipients.map((item) => formatData(item)),
          ccRecipients: data.ccRecipients.map((item) => formatData(item)),
          bccRecipients: data.bccRecipients.map((item) => formatData(item)),
        });
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    const getUsers = async () => {
      try {
        setIsLoading(true);
        const data = await AuthService.getAllUsers();
        setUsers(data);
        if (emailId) {
          getEmail();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        toast(`${error}`);
      }
    };
    getUsers();
  }, []);

  const searchOptions = (options, state) => {
    return options.filter((option) => option.includes(state.inputValue));
  };

  const addEmail = async (values) => {
    return;
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = {
        message: {
          subject: values.subject,
          body: {
            contentType: "html",
            content,
          },
        },
      };
      data.message.toRecipients = values.toRecipients.map((item) => {
        return {
          emailAddress: {
            address: item.email,
          },
        };
      });
      data.message.ccRecipients = values.ccRecipients.map((item) => {
        return {
          emailAddress: {
            address: item.email,
          },
        };
      });
      data.message.bccRecipients = values.bccRecipients.map((item) => {
        return {
          emailAddress: {
            address: item.email,
          },
        };
      });
      if (attachments && attachments.length > 0) {
        const promises = attachments.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve({
                "@odata.type": "#microsoft.graph.fileAttachment",
                name: file.name,
                contentType: file.type,
                contentBytes: reader.result.split(",")[1],
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        });
        data.message.attachments = await Promise.all(promises);
      }
      await EmailService.sendEmail(instance, data);
      dispatch(setGlobalIsLoading({ isLoading: false }));
      navigate("/emails/sentItems");
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: "1px solid",
        borderBottomColor: theme.palette.grey[1100],
        height: "82vh",
        overflow: "auto",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          width: "100%",
          height: "36px",
        }}
      >
        <Button
          startIcon={
            <ArrowBackOutlined
              sx={{
                width: "24px",
                height: "24px",
              }}
            />
          }
          sx={{
            color: theme.palette.secondary[700],
            fontSize: "16px",
            fontWeight: "600",
            textTransform: "none",
          }}
          onClick={() => navigate("/emails/sentItems")}
        >
          Messages envoyés
        </Button>
      </Box>
      {/* Body */}
      {isLoading ? (
        <CustomCircularProgress sx={{ height: "90%" }} />
      ) : (
        <Formik
          initialValues={email}
          onSubmit={addEmail}
          enableReinitialize={true}
          // validationSchema={emailSchema}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <Form>
              <Box>
                <CustomAutoComplete
                  name="toRecipients"
                  hint="Vers :"
                  options={users.map((user) => user.email)}
                  toggleOption={(value) => setFieldValue("toRecipients", value)}
                  searchOptions={searchOptions}
                  value={values.toRecipients}
                  filterOptions={(option) => {
                    return (
                      !values.ccRecipients.includes(option) &&
                      !values.bccRecipients.includes(option)
                    );
                  }}
                  initialValue={values.toRecipients}
                />
                <CustomAutoComplete
                  name="ccRecipients"
                  hint="cc :"
                  options={users.map((user) => user.email)}
                  value={values.ccRecipients}
                  toggleOption={(value) => setFieldValue("ccRecipients", value)}
                  searchOptions={searchOptions}
                  filterOptions={(option) => {
                    return (
                      !values.toRecipients.includes(option) &&
                      !values.bccRecipients.includes(option)
                    );
                  }}
                  initialValue={values.ccRecipients.map(
                    (item) => item.emailAddress
                  )}
                />
                <CustomAutoComplete
                  name="bccRecipients"
                  hint="cci :"
                  value={values.bccRecipients}
                  options={users.map((user) => user.email)}
                  toggleOption={(value) =>
                    setFieldValue("bccRecipients", value)
                  }
                  searchOptions={searchOptions}
                  filterOptions={(option) => {
                    return (
                      !values.toRecipients.includes(option) &&
                      !values.ccRecipients.includes(option)
                    );
                  }}
                  initialValue={values.bccRecipients.map(
                    (item) => item.emailAddress
                  )}
                />
                <CustomField
                  name={"subject"}
                  value={values.subject}
                  placeholder="(Object)"
                  fieldStyle={{
                    height: "40px",
                    borderBottom: "none",
                  }}
                  sx={{
                    m: "0",
                  }}
                  titleStyle={{
                    display: "none",
                  }}
                  InputProps={{
                    style: {
                      color: theme.palette.secondary[700],
                      fontSize: "20px",
                      height: "40px",
                      textWrap: "nowrap",
                    },
                  }}
                />
                <TextEditor
                  setContent={setContent}
                  initialValue={values.content}
                  setAttachments={setAttachments}
                />
                <Box
                  sx={{
                    width: "90%",
                    display: "flex",
                    gap: "15px",
                    flexWrap: "wrap",
                    m: "20px",
                  }}
                >
                  {attachments.map((file) => (
                    <ListItem
                      sx={{
                        width: "260px",
                        border: "1px solid",
                        borderColor: theme.palette.grey[400],
                        borderRadius: "6px",
                      }}
                    >
                      <ListItemIcon>
                        <DescriptionOutlined
                          sx={{ color: theme.palette.grey[400] }}
                        />
                      </ListItemIcon>
                      <ListItemAvatar
                        sx={{
                          fontSize: "12px",
                          color: theme.palette.grey[400],
                          width: "150px",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "130px",
                            textOverflow: "ellipsis",
                            overflow: "clip",
                            textWrap: "nowrap",
                          }}
                        >
                          {file.name}
                        </Typography>
                        <Typography>{file.size}</Typography>
                      </ListItemAvatar>
                      <ListItemSecondaryAction
                        sx={{
                          p: "0",
                          m: "0",
                        }}
                      >
                        <IconButton
                          sx={{
                            border: "1px solid",
                            borderColor: theme.palette.grey[50],
                            borderRadius: "4px",
                            p: "0",
                            m: "0",
                          }}
                          onClick={() =>
                            setAttachments((prevElm) =>
                              prevElm.filter((element) => element !== file)
                            )
                          }
                        >
                          <CloseOutlined
                            sx={{
                              color: theme.palette.grey.main,
                            }}
                          />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "48px",
                    backgroundColor: theme.palette.grey[1300],
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    pl: "10px",
                  }}
                >
                  <Button
                    startIcon={<EmailOutlined />}
                    sx={{
                      width: "107px",
                      height: "32px",
                      fontSize: "14px",
                      textTransform: "none",
                      backgroundColor: theme.palette.alt[900],
                      borderRadius: "3px",
                      fontWeight: "400",
                      ":hover": {
                        backgroundColor: theme.palette.alt[900],
                      },
                    }}
                    onClick={(event) => handleSubmit(event)}
                  >
                    Envoyer
                  </Button>
                  <Button
                    sx={{
                      width: "107px",
                      height: "32px",
                      fontSize: "14px",
                      textTransform: "none",
                      color: theme.palette.grey[700],
                      border: "1px solid",
                      borderColor: theme.palette.grey.main,
                      borderRadius: "3px",
                      fontWeight: "600",
                    }}
                    onClick={() => navigate("/emails/sentItems")}
                  >
                    Annuler
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default AddEmail;
