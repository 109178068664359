import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Switch } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { addAdmin } from '../../../redux/actions/UserAction';
import "./AddStyle.css"
import ValidateModal from 'Components/Modals/ValidateModal';
const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#06A561',
    '&:hover': {
      backgroundColor: alpha('#06A561', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#06A561',
  },
  '& .MuiSwitch-track ': {
    backgroundColor: '#000',
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddAdminForm = ({closeModalAdd}) => {
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsAlertOpen(false);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    userName: '',
    phone: '',
    email: '',
    password: '',
    role: 'ADMIN',
    hrManagement: false,
    projectManagement: false,
    financialManagement: false,
  };

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Le prénom est obligatoire'),
    lastName: Yup.string().required('Le nom est obligatoire'),
    phone: Yup.string().required('Le téléphone est obligatoire'),
    userName: Yup.string().required("Le nom d'utilisateur est obligatoire"),
    password: Yup.string().required('Le mot de passe est obligatoire'),
    email: Yup.string().email('Format de courriel invalide').required('Le courriel est obligatoire'),
  });

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const successAdd = async () => {
    openModal();
    await new Promise(resolve => setTimeout(resolve, 3000));
    closeModal();
    closeModalAdd()
  };

  const handleSubmit = (values) => {
   
    dispatch(addAdmin(values))
      .then(result => {
        if (result) {
          successAdd();
        }
        else {
          setIsAlertOpen(true);
        }
      })
      .catch(error => {
        console.log('Erreur', error);
        if (error) {
          setIsAlertOpen(true);
        }
      });
  };

  return (
    <>
      {modalOpen &&
                <ValidateModal isOpen={modalOpen} onClose={closeModal} message="L'ajout de l'administrateur est éffectué avec succèes" />
            }
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isAlertOpen}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Erreur d'ajout Admin !
        </Alert>
      </Snackbar>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <Form style={{ width: '100%', height: '100%' }}>
          <div className="mb-3">
            <h4>
              Nouveau Administrateur
            </h4>
          </div>
          <div className="mb-3">
            <div className="culumnFormContainer">
              <label className="form-label" htmlFor="firstName">Prénom</label>
              <Field className="form-control" type="text" name="firstName" />
              <ErrorMessage name="firstName" component="span" />
            </div>
          </div>
          <div className="mb-3">
            <div className="culumnFormContainer">
              <label className="form-label"  htmlFor="lastName">Nom</label>
              <Field className="form-control" type="text" name="lastName" />
              <ErrorMessage name="lastName" component="span" />
            </div>
          </div>
          <div className="mb-3"> <div className="culumnFormContainer">
            <label className="form-label"  htmlFor="userName">Nom d'utilisateur</label>
            <Field className="form-control" type="text" name="userName" />
            <ErrorMessage name="userName" component="span" />
          </div></div>
          <div className="mb-3">
          <div className="culumnFormContainer">
            <label className="form-label"  htmlFor="email">Courriel</label>
            <Field className="form-control" type="email" name="email" />
            <ErrorMessage name="email" component="span" />
          </div>
          </div>
          <div className="mb-3"><div className="culumnFormContainer">
            <label className="form-label"  htmlFor="password">Mot de passe</label>
            <Field className="form-control" type="password" name="password" />
            <ErrorMessage name="password" component="span" />
          </div></div>
          
          <div className="mb-3">
            
            <div className="culumnFormContainer">
              <label className="form-label"  htmlFor="phone">Téléphone</label>
              <Field className="form-control" type="number" name="phone" />
              <ErrorMessage name="phone" component="span" />
            </div>
          </div>
          <div className="mb-3">
            <h5 className='mb-2'>Fonctions en charge :</h5>
            <div className='d-flex align-items-center'>
              <Field as={PinkSwitch} name="hrManagement" />
              <p className="detailsAdmin-text">Gestion des ressources humaines</p>
            </div>
            <div className='d-flex align-items-center'>
              <Field as={PinkSwitch} name="projectManagement" />
              <p className="detailsAdmin-text">Gestion des chantiers et exploitation</p>
            </div>
            <div className='d-flex align-items-center '>
              <Field as={PinkSwitch} name="financialManagement" />
              <p className="detailsAdmin-text">Gestion financière</p>
            </div>
          </div>
          <button className="DarkBtn " style={{width:"100%", justifyContent:"center"}} type="submit">Confirmer</button>
        </Form>
      </Formik>
    </>
  );
};

export default AddAdminForm;
