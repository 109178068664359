import { useTheme } from "@emotion/react";
import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { setGlobalIsLoading } from "state/global";
import ConversationService from "Api/ConversationService";
import avatar from "../../Images/avatar.png";
import CustomCircularProgress from "Components/CustomCircularProgress";
import AuthService from "Api/AuthService";
import { selectUser } from "state/auth";
import { useDispatch, useSelector } from "react-redux";
import { addConversation } from "state/conversation";
import { toast } from "react-toastify";

const AddGroup = ({ setIsAddGroup }) => {
  const theme = useTheme();
  const [name, setName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector((state) => selectUser(state));

  const [users, setUsers] = useState([]);

  const toggleSelectedUsers = (user) => {
    const isChecked = selectedUsers.includes(user);
    if (!isChecked) {
      setSelectedUsers((prevUsers) => [...prevUsers, user]);
    } else {
      setSelectedUsers(selectedUsers.filter((item) => item !== user));
    }
  };

  const createGroup = async () => {
    try {
      dispatch(setGlobalIsLoading({ isLoading: true }));
      const data = { isGroupChat: true, users: selectedUsers };
      if (name.length > 0) {
        data.name = name;
      }
      const group = await ConversationService.createConversation(data);
      dispatch(addConversation({ conversation: group }));
      dispatch(setGlobalIsLoading({ isLoading: false }));
      setIsAddGroup(false);
    } catch (error) {
      dispatch(setGlobalIsLoading({ isLoading: false }));
      toast(`${error}`);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        setIsLoading(true);
        const data = await AuthService.getStaff();
        setUsers(data.filter((item) => item._id !== user._id));
        setIsLoading(false);
      } catch (error) {
        toast(`${error}`);
      }
    };
    getUsers();
  }, []);

  return (
    <Box>
      {isLoading ? (
        <CustomCircularProgress sx={{ height: "80vh" }} />
      ) : (
        <>
          <Button
            sx={{
              width: "100%",
              height: "32px",
              bgcolor: theme.palette.secondary[900],
              color: theme.palette.primary.main,
              textTransform: "none",
              fontSize: "16px",
              textAlign: "center",
              borderRadius: "0",
              ":hover": {
                bgcolor: theme.palette.secondary[900],
              },
            }}
            onClick={() => {
              toggleSelectedUsers([]);
              setIsAddGroup(false);
            }}
          >
            <CloseOutlined sx={{ mr: "5px" }} />
            sélectionner les membres du groupe
          </Button>
          <Box
            sx={{
              height: "60vh",
              overflow: "auto",
            }}
          >
            {users &&
              Array.isArray(users) &&
              users.length >= 0 &&
              users.map((user) => (
                <ListItem
                  key={user._id}
                  sx={{
                    height: "72px",
                    width: "100%",
                    p: "0",
                  }}
                >
                  <ListItemButton
                    sx={{
                      bgcolor:
                        selectedUsers.includes(user) && theme.palette.grey[400],
                      ":hover": {
                        bgcolor: selectedUsers.includes(user)
                          ? theme.palette.grey[400]
                          : theme.palette.grey.light,
                      },
                      width: "100%",
                      p: "0",
                    }}
                    onClick={() => toggleSelectedUsers(user)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "20px",
                        width: "20px",
                        m: "10px",
                        border: "none",
                      }}
                    >
                      <Checkbox
                        checked={selectedUsers.includes(user)}
                        inputProps={{
                          style: {
                            color: "green",
                          },
                        }}
                        sx={{
                          width: "50px",
                          height: "50px",
                          color: theme.palette.grey.light,
                          borderRadius: "4px",
                          border: "none",
                          ".css-havevq-MuiSvgIcon-root": {
                            width: "25px",
                            height: "25px",
                            color:
                              selectedUsers.includes(user) &&
                              theme.palette.alt[900],
                          },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemAvatar
                      sx={{
                        height: "76px",
                        width: "76px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                        backgroundImage: `url(${avatar})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "70%",
                      }}
                    />
                    <ListItemText
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: theme.palette.secondary[700],
                          }}
                        >
                          {user.firstName + " " + user.lastName}
                        </Typography>
                      </Box>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
          </Box>
          <Box
            sx={{
              height: "139px",
              width: "100%",
              bgcolor: theme.palette.grey.light,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              gap: "10px",
              p: "30px",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.grey[600],
                fontSize: "14px",
              }}
            >
              Nom du group (optionnel)
            </Typography>
            <InputBase
              fullWidth
              placeholder="Groupe"
              onChange={(event) => setName(event.target.value)}
              sx={{
                color: theme.palette.grey[400],
                bgcolor: theme.palette.primary.main,
                borderRadius: "4px",
                minHeight: "40px",
                pl: "10px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                gap: "20px",
                width: "100%",
              }}
            >
              <Button
                sx={{
                  flex: "1",
                  bgcolor: theme.palette.alt[900],
                  color: theme.palette.primary.main,
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "none",
                  ":hover": {
                    bgcolor: theme.palette.alt[900],
                  },
                }}
                onClick={createGroup}
              >
                Confirmer
              </Button>
              <Button
                sx={{
                  flex: "1",
                  bgcolor: theme.palette.alt[900],
                  color: theme.palette.primary.main,
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "none",
                  ":hover": {
                    bgcolor: theme.palette.alt[900],
                  },
                }}
                onClick={() => {
                  toggleSelectedUsers([]);
                  setIsAddGroup(false);
                }}
              >
                Annuler
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AddGroup;
