import handleError from "utils/handelError";
import axiosClient from "./client";
import {
  getAllConversationMessagesEndPoint,
  sendMessageEndPoint,
} from "./Constants";

const AuthService = {
  getAllConversationMessages: async (id) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.get(
        getAllConversationMessagesEndPoint,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            conversationId: id,
          },
        }
      );
      const messages = response.data.messages;
      return messages;
    } catch (error) {
      handleError(error);
    }
  },
  sendMessage: async (data) => {
    try {
      const token = localStorage.getItem("@userCsc");
      const response = await axiosClient.post(sendMessageEndPoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const message = response.data.message;
      return message;
    } catch (error) {
      handleError(error);
    }
  },
};

export default AuthService;
