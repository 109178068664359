import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CollegeList from "./CollegeList";
import Groups from "./Groups";
import { useTheme } from "@emotion/react";
import Conversation from "./Conversation";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedConversation,
  setSelectedConversation,
  updateConversation,
} from "state/conversation";
import MessagingService from "Api/messanging/service";
import MainLayout from "Components/Layouts/MainLayout";
import MainHeader from "Components/Headers/MainHeader";

const Messages = () => {
  const theme = useTheme();
  const [isCollege, setIsCollege] = useState(true);
  const dispatch = useDispatch();
  const selectedConversation = useSelector((state) =>
    selectSelectedConversation(state)
  );
  useEffect(() => {
    MessagingService.on("message received", (receivedMessage) => {
      dispatch(
        updateConversation({ conversation: receivedMessage.conversation })
      );
    });
  }, []);
  return (
    <>
      <MainHeader />
      <MainLayout>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          {/* LeftSide */}
          <Box
            sx={{
              width: "364px",
              height: "93%",
            }}
          >
            {/* Header */}
            <Box
              sx={{
                width: "100%",
                height: "56px",
                bgcolor: theme.palette.grey.light,
                display: "flex",
              }}
            >
              <Button
                sx={{
                  flex: "1",
                  color: isCollege
                    ? theme.palette.alt[900]
                    : theme.palette.secondary[700],
                  borderBottom: isCollege && "2px solid",
                  borderBottomColor: isCollege && theme.palette.alt[900],
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "600",
                  borderRadius: "0",
                }}
                onClick={() => {
                  dispatch(setSelectedConversation({ conversation: null }));
                  setIsCollege(true);
                }}
              >
                Collègues
              </Button>
              <Button
                sx={{
                  flex: "1",
                  color: !isCollege
                    ? theme.palette.alt[900]
                    : theme.palette.secondary[700],
                  borderBottom: !isCollege && "2px solid",
                  borderBottomColor: !isCollege && theme.palette.alt[900],
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "600",
                  borderRadius: "0",
                }}
                onClick={() => {
                  dispatch(setSelectedConversation({ conversation: null }));
                  setIsCollege(false);
                }}
              >
                Groupes
              </Button>
            </Box>
            {/* chat list */}
            <Box
              sx={{
                width: "100%",
              }}
            >
              {isCollege ? <CollegeList /> : <Groups />}
            </Box>
          </Box>
          {/* right side */}
          <Box
            sx={{
              bgcolor: theme.palette.primary.main,
              flex: "1",
            }}
          >
            {selectedConversation ? (
              <Conversation />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                >
                  Choisissez un chat pour démarrer la conversation
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </MainLayout>
    </>
  );
};

export default Messages;
