import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import ConversationService from "Api/ConversationService";
import CustomCircularProgress from "Components/CustomCircularProgress";
import React, { useEffect, useState } from "react";
import AddGroup from "./AddGroup";
import GroupList from "./GroupList";
import { useDispatch } from "react-redux";
import { setGroups } from "state/conversation";
import { toast } from "react-toastify";

const Groups = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [isAddGroup, setIsAddGroup] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getGroups = async () => {
      try {
        setIsLoading(true);
        const data = await ConversationService.getAllGroups();
        dispatch(setGroups({ groups: data }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    getGroups();
  }, []);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.grey[100],
        height: "84vh",
        width: "100%",
      }}
    >
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <>
          {isAddGroup ? (
            <AddGroup setIsAddGroup={setIsAddGroup} />
          ) : (
            <GroupList setIsAddGroup={setIsAddGroup} />
          )}
        </>
      )}
    </Box>
  );
};

export default Groups;
