export const emailIcons = {
    received: <path d="M3 0C1.34315 0 0 1.34315 0 3V11C0 12.6569 1.34315 14 3 14H11C12.6569 14 14 12.6569 14 11V3C14 1.34315 12.6569 0 11 0H3ZM13 7H9.5C9.22386 7 8.99999 7.22297 8.99999 7.49911L8.99967 7.51142C8.99925 7.52383 8.99835 7.54435 8.99639 7.5718C8.99245 7.62686 8.98435 7.7088 8.96774 7.80843C8.93414 8.01005 8.86786 8.27125 8.74029 8.52639C8.61369 8.77958 8.43166 9.01755 8.16796 9.19335C7.9074 9.36706 7.53751 9.5 7 9.5C6.46249 9.5 6.0926 9.36706 5.83204 9.19335C5.56834 9.01755 5.38631 8.77958 5.25971 8.52639C5.13214 8.27125 5.06586 8.01005 5.03226 7.80843C5.01565 7.7088 5.00755 7.62686 5.00361 7.5718C5.00165 7.54435 5.00075 7.52383 5.00033 7.51142L5 7.49938C4.99966 7.22352 4.77594 7 4.5 7H1V3C1 1.89543 1.89543 1 3 1H11C12.1046 1 13 1.89543 13 3V7ZM1 8H4.0505C4.09652 8.26433 4.18655 8.61614 4.36529 8.97361C4.55119 9.34542 4.83791 9.73245 5.27734 10.0254C5.7199 10.3204 6.28751 10.5 7 10.5C7.71249 10.5 8.2801 10.3204 8.72266 10.0254C9.16209 9.73245 9.44881 9.34542 9.63471 8.97361C9.81345 8.61614 9.90348 8.26433 9.9495 8H13V11C13 12.1046 12.1046 13 11 13H3C1.89543 13 1 12.1046 1 11V8Z" />,
    sent: <path d="M0.721126 0.0514945L16.0756 7.61746C16.3233 7.73952 16.4252 8.03927 16.3031 8.28697C16.2544 8.3858 16.1744 8.46578 16.0756 8.51448L0.721442 16.0803C0.473739 16.2023 0.173989 16.1005 0.0519328 15.8528C-0.00143915 15.7445 -0.0138112 15.6205 0.0171017 15.5038L1.9858 8.07012L0.016755 0.627888C-0.0538755 0.360932 0.105278 0.087263 0.372235 0.0166325C0.488927 -0.0142416 0.61285 -0.001859 0.721126 0.0514945ZM1.26445 1.43403L2.87357 7.51612L2.93555 7.50412L3 7.5H10C10.2761 7.5 10.5 7.72386 10.5 8C10.5 8.24546 10.3231 8.44961 10.0899 8.49194L10 8.5H3C2.9686 8.5 2.93787 8.4971 2.90807 8.49157L1.26508 14.6976L14.7234 8.06597L1.26445 1.43403Z"  />,
    draft: <path d="M11.2447 0.817058L1.6389 10.4229L1.52163 10.5508C1.33597 10.7718 1.1999 11.031 1.12358 11.3109L0.0176822 15.3658L0.00251826 15.4452C-0.0350297 15.7857 0.285034 16.0743 0.631623 15.9798L4.6866 14.8739L4.85187 14.821C5.12275 14.7212 5.36948 14.5636 5.57457 14.3586L15.1804 4.75273L15.3106 4.61346C16.2653 3.52026 16.2219 1.85858 15.1804 0.817058C14.0936 -0.269749 12.3315 -0.269749 11.2447 0.817058ZM2.346 11.13L10.3845 3.09195L12.9055 5.61295L4.86747 13.6515L4.76979 13.7372L4.66229 13.8093C4.58766 13.8526 4.50743 13.8862 4.42348 13.9091L1.21171 14.7847L2.08835 11.574L2.13062 11.4511C2.18068 11.3314 2.25371 11.2223 2.346 11.13ZM14.4733 1.52417L14.5829 1.64407C15.1674 2.34455 15.1309 3.38803 14.4733 4.04563L13.6125 4.90595L11.0915 2.38495L11.9518 1.52417L12.0717 1.41457C12.7722 0.830031 13.8157 0.866565 14.4733 1.52417ZM9.64786 0.999664H0.499756C0.223614 0.999664 -0.000244141 1.22352 -0.000244141 1.49966C-0.000244141 1.77581 0.223614 1.99966 0.499756 1.99966H8.64786L9.64786 0.999664ZM6.64786 3.99966H0.499756C0.223614 3.99966 -0.000244141 4.22352 -0.000244141 4.49966C-0.000244141 4.77581 0.223614 4.99966 0.499756 4.99966H5.64786L6.64786 3.99966ZM2.64786 7.99966L3.64786 6.99966H0.499756C0.223614 6.99966 -0.000244141 7.22352 -0.000244141 7.49966C-0.000244141 7.77581 0.223614 7.99966 0.499756 7.99966H2.64786Z"  />,
    basket: <path d="M6.5 3H9.5C9.5 2.17157 8.82843 1.5 8 1.5C7.17157 1.5 6.5 2.17157 6.5 3ZM5.5 3C5.5 1.61929 6.61929 0.5 8 0.5C9.38071 0.5 10.5 1.61929 10.5 3H15.5C15.7761 3 16 3.22386 16 3.5C16 3.77614 15.7761 4 15.5 4H14.4456L13.2521 14.3439C13.0774 15.8576 11.7957 17 10.2719 17H5.72813C4.20431 17 2.92256 15.8576 2.7479 14.3439L1.55437 4H0.5C0.223858 4 0 3.77614 0 3.5C0 3.22386 0.223858 3 0.5 3H5.5ZM3.74131 14.2292C3.85775 15.2384 4.71225 16 5.72813 16H10.2719C11.2878 16 12.1422 15.2384 12.2587 14.2292L13.439 4H2.56101L3.74131 14.2292ZM6.5 6.5C6.77614 6.5 7 6.72386 7 7V13C7 13.2761 6.77614 13.5 6.5 13.5C6.22386 13.5 6 13.2761 6 13V7C6 6.72386 6.22386 6.5 6.5 6.5ZM10 7C10 6.72386 9.77614 6.5 9.5 6.5C9.22386 6.5 9 6.72386 9 7V13C9 13.2761 9.22386 13.5 9.5 13.5C9.77614 13.5 10 13.2761 10 13V7Z" transform="translate(0,-3)" />,
    spam: <path d="M0 2.5C0 1.11929 1.11929 0 2.5 0H5.16667C5.49122 0 5.80702 0.105266 6.06667 0.3L7.66667 1.5L13.5 1.5C14.8807 1.5 16 2.61929 16 4V7.25716C15.6929 7.00353 15.3578 6.78261 15 6.59971V4C15 3.17157 14.3284 2.5 13.5 2.5L7.61907 2.5L6.15763 4.03449C5.87452 4.33176 5.48195 4.5 5.07143 4.5H1V11.5C1 12.3284 1.67157 13 2.5 13H7.20703C7.30564 13.3486 7.43777 13.6832 7.59971 14H2.5C1.11929 14 0 12.8807 0 11.5V2.5ZM2.5 1C1.67157 1 1 1.67157 1 2.5V3.5L5.07143 3.5C5.20827 3.5 5.33913 3.44392 5.4335 3.34483L6.69446 2.02084L5.46667 1.1C5.38012 1.03509 5.27485 1 5.16667 1L2.5 1ZM8 11.5C8 9.01472 10.0147 7 12.5 7C14.9853 7 17 9.01472 17 11.5C17 13.9853 14.9853 16 12.5 16C10.0147 16 8 13.9853 8 11.5ZM12.5 8C10.567 8 9 9.567 9 11.5C9 12.2862 9.2592 13.0118 9.69681 13.5961L14.5961 8.69681C14.0118 8.2592 13.2862 8 12.5 8ZM15.3032 9.40392L10.4039 14.3032C10.9882 14.7408 11.7138 15 12.5 15C14.433 15 16 13.433 16 11.5C16 10.7138 15.7408 9.98821 15.3032 9.40392Z"  />,
    archive: <path d="M6.5 7C6.22386 7 6 7.22386 6 7.5C6 7.77614 6.22386 8 6.5 8H9.5C9.77614 8 10 7.77614 10 7.5C10 7.22386 9.77614 7 9.5 7H6.5ZM0 1.75C0 0.783502 0.783501 0 1.75 0H14.25C15.2165 0 16 0.783502 16 1.75V3.25C16 3.9481 15.5912 4.55073 15 4.83159V11C15 12.6569 13.6569 14 12 14H4C2.34315 14 1 12.6569 1 11V4.83159C0.408763 4.55073 0 3.9481 0 3.25V1.75ZM1.75 1C1.33579 1 1 1.33579 1 1.75V3.25C1 3.66421 1.33579 4 1.75 4H14.25C14.6642 4 15 3.66421 15 3.25V1.75C15 1.33579 14.6642 1 14.25 1H1.75ZM2 5V11C2 12.1046 2.89543 13 4 13H12C13.1046 13 14 12.1046 14 11V5H2Z"  />,
    moveto: <path d="M2.5 0C1.11929 0 0 1.11929 0 2.5V11.5C0 12.8807 1.11929 14 2.5 14H7.59971C7.43777 13.6832 7.30564 13.3486 7.20703 13H2.5C1.67157 13 1 12.3284 1 11.5V4.5H5.07143C5.48194 4.5 5.87452 4.33176 6.15763 4.03449L7.61907 2.5H13.5C14.3284 2.5 15 3.17157 15 4V6.59971C15.3578 6.78261 15.6929 7.00353 16 7.25716V4C16 2.61929 14.8807 1.5 13.5 1.5H7.66667L6.06667 0.3C5.80702 0.105267 5.49122 0 5.16667 0H2.5ZM1 2.5C1 1.67157 1.67157 1 2.5 1H5.16667C5.27485 1 5.38012 1.03509 5.46667 1.1L6.69446 2.02084L5.4335 3.34483C5.33913 3.44392 5.20827 3.5 5.07143 3.5H1V2.5ZM12.5 7C14.9853 7 17 9.01472 17 11.5C17 13.9853 14.9853 16 12.5 16C10.0147 16 8 13.9853 8 11.5C8 9.01472 10.0147 7 12.5 7ZM14.8532 11.854L14.8557 11.8514C14.9026 11.804 14.938 11.7495 14.9621 11.6914C14.9861 11.6333 14.9996 11.5697 15 11.503L15 11.5L15 11.497C14.9996 11.4303 14.9861 11.3667 14.9621 11.3086C14.9377 11.2496 14.9015 11.1944 14.8536 11.1464L12.8536 9.14645C12.6583 8.95118 12.3417 8.95118 12.1464 9.14645C11.9512 9.34171 11.9512 9.65829 12.1464 9.85355L13.2929 11H10.5C10.2239 11 10 11.2239 10 11.5C10 11.7761 10.2239 12 10.5 12H13.2929L12.1464 13.1464C11.9512 13.3417 11.9512 13.6583 12.1464 13.8536C12.3417 14.0488 12.6583 14.0488 12.8536 13.8536L14.8532 11.854Z"  />,
    respondeall: <path d="M8.09619 0.904259C8.26976 1.07783 8.28905 1.34725 8.15405 1.54212L8.09619 1.61137L4.707 4.99981L8 5.00045C12.0609 5.00045 15.368 8.22793 15.4961 12.2577L15.5 12.5005C15.5 12.7766 15.2761 13.0005 15 13.0005C14.7239 13.0005 14.5 12.7766 14.5 12.5005C14.5 8.98864 11.715 6.1273 8.23313 6.00456L8 6.00045L4.707 5.99981L8.09619 9.38954C8.29146 9.5848 8.29146 9.90139 8.09619 10.0966C7.92263 10.2702 7.6532 10.2895 7.45833 10.1545L7.38909 10.0966L3.14645 5.85401C2.97288 5.68044 2.9536 5.41102 3.08859 5.21615L3.14645 5.1469L7.38909 0.904259C7.58435 0.708997 7.90093 0.708997 8.09619 0.904259ZM0.146447 5.1469L4.38909 0.904259C4.58435 0.708997 4.90093 0.708997 5.09619 0.904259C5.26976 1.07783 5.28905 1.34725 5.15405 1.54212L5.09619 1.61137L1.20711 5.50045L5.09619 9.38954C5.29146 9.5848 5.29146 9.90139 5.09619 10.0966C4.92263 10.2702 4.6532 10.2895 4.45834 10.1545L4.38909 10.0966L0.146447 5.85401C-0.0271197 5.68044 -0.046405 5.41102 0.0885911 5.21615L0.146447 5.1469L4.38909 0.904259L0.146447 5.1469Z"  />,
    transfer: <path d="M7.90381 0.146447C7.73024 0.320013 7.71095 0.589437 7.84595 0.784305L7.90381 0.853553L11.293 4.242L8 4.24264C3.93908 4.24264 0.631981 7.47011 0.503852 11.4999L0.499999 11.7426C0.499999 12.0188 0.723857 12.2426 0.999999 12.2426C1.27614 12.2426 1.5 12.0188 1.5 11.7426C1.5 8.23083 4.285 5.36949 7.76687 5.24674L8 5.24264L11.293 5.242L7.90381 8.63173C7.70854 8.82699 7.70854 9.14357 7.90381 9.33883C8.07737 9.5124 8.3468 9.53169 8.54167 9.39669L8.61091 9.33883L12.8536 5.09619C13.0271 4.92263 13.0464 4.6532 12.9114 4.45833L12.8536 4.38909L8.61091 0.146447C8.41565 -0.0488155 8.09907 -0.0488155 7.90381 0.146447Z"  />,
    seen: <path d="M7.26205 0.0741708C7.10135 -0.0247236 6.89865 -0.0247236 6.73795 0.0741708L0.713853 3.78131C0.270232 4.05431 0 4.5379 0 5.05879V10.5C0 11.8807 1.11929 13 2.5 13H11.5C12.8807 13 14 11.8807 14 10.5V5.0588C14 4.5379 13.7298 4.05431 13.2861 3.78131L7.26205 0.0741708ZM1.45402 4.5L7 1.08709L12.546 4.5L7 7.91291L1.45402 4.5ZM1 5.39478L6.73795 8.92583C6.89865 9.02472 7.10135 9.02472 7.26205 8.92583L13 5.39478V10.5C13 11.3284 12.3284 12 11.5 12H2.5C1.67157 12 1 11.3284 1 10.5V5.39478ZM4.50018 15C3.68227 15 2.9561 14.6072 2.5 14H12.0002C13.657 14 15.0002 12.6568 15.0002 11V5.05876C15.0002 4.89508 14.9842 4.73362 14.9532 4.57629L15.2863 4.78127C15.7299 5.05427 16.0002 5.53787 16.0002 6.05876V11C16.0002 13.2091 14.2093 15 12.0002 15H4.50018Z"  />,
    responde: <path d="M1.707 5.99981L5.09619 9.38954C5.29146 9.5848 5.29146 9.90139 5.09619 10.0966C4.92263 10.2702 4.6532 10.2895 4.45834 10.1545L4.38909 10.0966L0.146447 5.85401C-0.0271197 5.68044 -0.046405 5.41102 0.0885911 5.21615L0.146447 5.1469L4.38909 0.904259C4.58435 0.708997 4.90093 0.708997 5.09619 0.904259C5.26976 1.07783 5.28905 1.34725 5.15405 1.54212L5.09619 1.61137L1.707 4.99981L8 5.00045C12.0609 5.00045 15.368 8.22793 15.4961 12.2577L15.5 12.5005C15.5 12.7766 15.2761 13.0005 15 13.0005C14.7239 13.0005 14.5 12.7766 14.5 12.5005C14.5 8.98864 11.715 6.1273 8.23313 6.00456L8 6.00045L1.707 5.99981L5.09619 9.38954L1.707 5.99981Z"  />,
    more:<path d="M3.25 2C3.25 2.69036 2.69036 3.25 2 3.25C1.30964 3.25 0.75 2.69036 0.75 2C0.75 1.30964 1.30964 0.75 2 0.75C2.69036 0.75 3.25 1.30964 3.25 2ZM8.25 2C8.25 2.69036 7.69036 3.25 7 3.25C6.30964 3.25 5.75 2.69036 5.75 2C5.75 1.30964 6.30964 0.75 7 0.75C7.69036 0.75 8.25 1.30964 8.25 2ZM12 3.25C12.6904 3.25 13.25 2.69036 13.25 2C13.25 1.30964 12.6904 0.75 12 0.75C11.3096 0.75 10.75 1.30964 10.75 2C10.75 2.69036 11.3096 3.25 12 3.25Z"/>,
}