import { useTheme } from "@emotion/react";
import { estimateSchema } from "Pages/schemas/schemas";
import { Formik ,Form} from "formik";
import React, { useEffect, useState } from "react";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomField from "Components/CustomField";

const EstimateArray = ({ title, type, estimates, setEstimates }) => {
  const theme = useTheme();
  const [isAdd, setIsAdd] = useState(false);
  const getTotal = (items) => items.reduce((total, item) => total + item.amount, 0);

  const [resources, setResources] = useState([]);
  
  const onAdd = (values, { resetForm }) => {
    if (values.amount && values.resource) {
      const newEstimates = [...estimates];
      const estimate = {
        resource: values.resource,
        type,
        currency: "euro",
        amount: parseFloat(values.amount),
      };
      newEstimates.push(estimate);
      setEstimates(newEstimates);
      setIsAdd(false);
      resetForm();
    } else {
      console.log("Valeurs manquantes : ", values);
    }
  };

  const onRemove = (resource) => {
    setEstimates(estimates?.filter((item) => item.resource !== resource));
  };

  useEffect(() => {
    if (estimates && Array.isArray(estimates)) {
      const currentResource =
        estimates.filter((item) => item.type === type) ?? [];
      setResources(currentResource);
    }
  }, [estimates, type]);
  return (
    <Box
      sx={{
        height: "330px",
        bgcolor: theme.palette.primary.main,
        borderRadius: "6px",
      }}
    >
      <Formik
        onSubmit={onAdd}
        initialValues={{
          amount: '',
          resource: '',
        }}
        validationSchema={estimateSchema}
        validateOnMount={true}
      >
        {({ handleSubmit, values }) => (
          <Form>
          <Typography
            sx={{
              color: theme.palette.grey[600],
              fontSize: "16px",
              fontWeight: "bold",
              m: "15px 0 10px 20px",
            }}
          >
            {title}
          </Typography>
          <TableContainer
            sx={{
              bgcolor: theme.palette.primary.main,
              height: "270px",
              boxShadow: "none",
            }}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "2px solid",
                      borderBottomColor: theme.palette.grey[200],
                    }}
                  ></TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: theme.palette.grey[600],
                      fontSize: "14px",
                      borderBottom: "2px solid",
                      borderBottomColor: theme.palette.grey[200],
                    }}
                  >
                    Ressource
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: theme.palette.grey[600],
                      fontSize: "14px",
                      borderBottom: "2px solid",
                      borderBottomColor: theme.palette.grey[200],
                    }}
                  >
                    Estimation (€)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resources.map((resource, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        pt: "0",
                        pb: "0",
                        width: "50px",
                      }}
                    >
                      <IconButton
                        sx={{ p: "0" }}
                        onClick={() => onRemove(resource.resource)}
                      >
                        <RemoveCircleOutline
                          sx={{ color: theme.palette.grey[600] }}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey[200],
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        fontWeight: "500",
                        p: "5px 10px",
                      }}
                    >
                      {resource.resource}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey[200],
                        borderLeft: "1px solid",
                        borderLeftColor: theme.palette.grey[200],
                        color: theme.palette.secondary[700],
                        fontSize: "14px",
                        fontWeight: "500",
                        width: "130px",
                        p: "5px 10px",
                      }}
                    >
                      {resource.amount}
                    </TableCell>
                  </TableRow>
                ))}
                {isAdd && (
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        pt: "0",
                        pb: "0",
                        width: "50px",
                      }}
                    >
                      <IconButton
                        sx={{ p: "0" }}
                        onClick={() => setIsAdd(false)}
                      >
                        <RemoveCircleOutline
                          sx={{ color: theme.palette.grey[600] }}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey[200],
                        p: "0",
                      }}
                    >
                      <CustomField
                        name="resource"
                        type="text"
                        value={values.resource}
                        titleStyle={{ display: "none" }}
                        sx={{ height: "38px", m: "0", overflow: "clip" }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: theme.palette.grey[200],
                        borderLeft: "1px solid",
                        borderLeftColor: theme.palette.grey[200],
                        width: "130px",
                        p: "0",
                      }}
                    >
                      <CustomField
                        titleStyle={{ display: "none" }}
                        fieldStyle={{ border: "none" }}
                        value={values.amount}
                        sx={{ height: "38px", m: "0", overflow: "hidden" }}
                        name="amount"
                        type="number"
                      />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      width: "50px",
                      pt: "0",
                      pb: "0",
                    }}
                  >
                    <IconButton
                      sx={{ p: "0" }}
                      onClick={(event) => {
                        if (isAdd) {
                          handleSubmit(event);
                        } else {
                          setIsAdd(true);
                        }
                      }}
                    >
                      <AddCircleOutline
                        sx={{ color: theme.palette.grey[600] }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "none",
                      color: theme.palette.secondary[700],
                      fontSize: "14px",
                      fontWeight: "500",
                      bgcolor: theme.palette.grey[50],
                      pt: "15px",
                      pb: "15px",
                    }}
                  >
                    Estimation totale
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderLeftColor: theme.palette.grey.main,
                      color: theme.palette.secondary[700],
                      fontSize: "14px",
                      width: "130px",
                      fontWeight: "500",
                      bgcolor: theme.palette.grey[50],
                      pt: "15px",
                      pb: "15px",
                    }}
                  >
                    {resources.length > 0 ? getTotal(resources) : 0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EstimateArray;
