import { formatDate } from "./format_date";

export const groupeEmails = (emails) => {
  const groupedEmails = [];
  emails.forEach((email) => {
    const date = formatDate(email.createdDateTime);
    if (!groupedEmails.some((item) => item.date === date)) {
      groupedEmails.push({ date, emails: [] });
    }
    groupedEmails[groupedEmails.findIndex((item) => item.date === date)].emails.push(email);
  });
  return groupedEmails;
};
