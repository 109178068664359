import { useTheme } from "@emotion/react";
import { Box, IconButton, Input, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import avatar from "../../Images/avatar.png";
import {
  HighlightOffOutlined,
  Send,
  SentimentSatisfiedAltOutlined,
} from "@mui/icons-material";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Message from "./Message";
import MessageService from "Api/MessageService";
import { useDispatch, useSelector } from "react-redux";

import {
  selectSelectedConversation,
  updateConversation,
} from "state/conversation";
import { toast } from "react-toastify";
import MessagingService from "Api/messanging/service";
import CustomCircularProgress from "Components/CustomCircularProgress";

const Conversation = () => {
  const theme = useTheme();
  const [showEmoji, setShowEmoji] = useState(false);
  const [message, setMessage] = useState("");
  const user = useSelector((state) => state.authentification.user);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const selectedConversation = useSelector((state) =>
    selectSelectedConversation(state)
  );
  const selectedConversationRef = useRef(selectedConversation);

  const getConversationName = () => {
    if (selectedConversation.isGroupChat) {
      return selectedConversation.name;
    } else {
      const college =
        selectedConversation.users.filter((item) => item._id !== user._id)[0] ??
        {};
      return college.firstName + " " + college.lastName;
    }
  };

  const sendMessage = async () => {
    try {
      if (!message) return;
      const data = await MessageService.sendMessage({
        content: message,
        conversation: selectedConversation._id,
      });
      setMessages((prevMessages) => [data, ...prevMessages]);
      MessagingService.emit("send message", data);
      const updatedConversation = {
        ...selectedConversation,
        lastMessage: data,
        unseenCount: 0,
      };
      dispatch(updateConversation({ conversation: updatedConversation }));
      setMessage("");
    } catch (error) {
      toast(`${error}`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const getMessages = async () => {
      try {
        const data = await MessageService.getAllConversationMessages(
          selectedConversation._id
        );
        setMessages(data);
        const updatedConversation = { ...selectedConversation, unseenCount: 0 };
        dispatch(updateConversation({ conversation: updatedConversation }));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast(`${error}`);
      }
    };
    if (selectedConversation) {
      selectedConversationRef.current = selectedConversation;
      getMessages();
    }
  }, [selectedConversation]);

  useEffect(() => {
    MessagingService.on("message received", (receivedMessage) => {
      if (
        selectedConversationRef.current._id === receivedMessage.conversation._id
      ) {
        setMessages((prevMessages) => [receivedMessage, ...prevMessages]);
      }
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              borderBottom: "1px solid",
              borderBottomColor: theme.palette.grey.light,
            }}
          >
            <Box
              sx={{
                height: "76px",
                width: "76px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                backgroundImage: `url(${avatar})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "70%",
              }}
            />
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: theme.palette.secondary[700],
              }}
            >
              {getConversationName()}
            </Typography>
          </Box>
          {/* Body */}
          <Box
            sx={{
              flexGrow: "1",
            }}
          >
            <Stack
              direction="column-reverse"
              sx={{
                height: "calc(68vh + 4px)",
                overflowY: "scroll",
              }}
            >
              {messages &&
                Array.isArray(messages) &&
                messages.length > 0 &&
                messages.map((item) => (
                  <Message
                    key={item._id}
                    message={item}
                    isSent={item.sender._id === user._id}
                  />
                ))}
            </Stack>
          </Box>
          {/* Toolbar */}
          <Box
            sx={{
              height: "92px",
              width: "100%",
              bgcolor: theme.palette.grey.light,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "0 20px",
              position: "relative",
            }}
          >
            {showEmoji && (
              <Box
                sx={{
                  position: "absolute",
                  left: "30px",
                  bottom: "90%",
                }}
              >
                <Picker
                  data={data}
                  onEmojiSelect={(value) => setMessage(message + value.native)}
                  locale={"fr"}
                  onClickOutside={() => setShowEmoji(false)}
                />
              </Box>
            )}
            <Input
              fullWidth
              placeholder="Message"
              inputProps={{
                style: {
                  color: theme.palette.grey[10],
                },
              }}
              onChange={(event) => setMessage(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  sendMessage(event.target.value);
                }
              }}
              value={message}
              startAdornment={
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowEmoji(!showEmoji);
                  }}
                >
                  {showEmoji ? (
                    <HighlightOffOutlined
                      sx={{ color: theme.palette.grey[10] }}
                    />
                  ) : (
                    <SentimentSatisfiedAltOutlined
                      sx={{ color: theme.palette.grey[10] }}
                    />
                  )}
                </IconButton>
              }
              endAdornment={
                <IconButton onClick={sendMessage}>
                  <Send sx={{ color: theme.palette.alt[1000] }} />
                </IconButton>
              }
              sx={{
                bgcolor: theme.palette.primary.main,
                height: "56px",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Conversation;
