import { Box } from "@mui/material";
import React from "react";

const EmailAvatar = ({ name ,sx}) => {
    const str = name ?? ''
    const getColor = ()=>{
        let hash = 0;
        for (var i = 0; i < str.length; i++) {
          if(str[i]) hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        const h = hash % 360;
        const s = 75;
        const l = 50;
        return `hsl(${h}, ${s}%, ${l}%)`
    }
  return (
    <Box
      sx={{
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontSize: "10px",
        fontWeight: "600",
        bgcolor:getColor(),
        ...sx
      }}
    >
        {str.charAt(0).toUpperCase()}
    </Box>
  );
};

export default EmailAvatar;
