import React from 'react'
import "./Style.css"
import Logo from "../../../Images/cslogo.svg"
import BG from "../../../Images/bggg.png"
function BannerAPropos() {
    return (
        <div className='bannerContainer'>
            <img src={Logo} alt="" />
            <img src={BG} alt=""  className='bgForBanner'/>
        </div>
    )
}

export default BannerAPropos